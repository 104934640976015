import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputErrorConf } from './input-error.conf';

@Component({
    selector: 'app-input-error',
    template: `
        <div *ngIf="errors && enable" class="flex-row">
            <span *ngFor="let error of errorList">{{error}}</span><br/>
        </div>
    `,
    styles: []
})
export class InputErrorComponent
{
    @Input("errorMessages") errorMessages: any = InputErrorConf.defaultErrorMessages;
    @Input("errors") errors: ValidationErrors;
    @Input("enable") enable: boolean = false;
    
    constructor(private ts: TranslateService) {}

    get errorList(): Array<string>
    {
        let list: Array<string> = [];
        if (this.errorMessages && this.errors)
        {
            Object.keys(this.errors).forEach(errorKey =>
            {
                let parameters = {};
                if (typeof(this.errors[errorKey]) == 'object')
                {
                    Object.keys(this.errors[errorKey]).forEach(k =>
                    {
                        let eKey = k.replace('"', '');
                        parameters[eKey] = this.errors[errorKey][k];
                    });
                }
                else
                {
                    let eKey = errorKey + "_param";
                    parameters = { eKey: this.errors[errorKey] };
                }
                if(this.errorMessages[errorKey]){
                    let label = this.ts.instant(this.errorMessages[errorKey], parameters);
                    list.push(label)
                }else{
                    list.push("Errore non codificato al campo");
                    console.log("error errorMessages{},parameters{},errorkey{},errors",this.errorMessages,parameters,errorKey,this.errors);
                }
            });
        }
        return list;
    }
}
