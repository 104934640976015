import { ReportComponent } from './components/report/report.component';
import { ListaEntiPublicComponent } from './components/lista-enti-public/lista-enti-public.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DettaglioPraticaComponent } from './components/dettaglio-pratica/dettaglio-pratica.component';
import { ListaPratichePublicComponent } from './components/lista-pratiche-public/lista-pratiche-public.component';
import { ListaPraticheComponent } from './components/lista-pratiche/lista-pratiche.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NuovaPraticaComponent } from './components/nuova-pratica/nuova-pratica.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { AclTipo } from './model/AclTipo.enum';


const routes: Routes = [

  /************************* */
  { path: 'lista-pratiche', component: ListaPraticheComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT, AclTipo.ED] ,vistaMappa:false} },
  { path: 'nuova-pratica', component: NuovaPraticaComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT, AclTipo.ED] } },
  { path: 'mappa-pratiche', component: ListaPraticheComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT, AclTipo.ED], vistaMappa:true} },
  { path: 'modifica-pratica/:idPratica', component: DettaglioPraticaComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT, AclTipo.ED] } },
  { path: 'dettaglio-pratica/:idPratica', component: DettaglioPraticaComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT, AclTipo.ED] } },
  { path: 'genera-report', component: ReportComponent, canActivate: [AuthGuard], data: { acl: [AclTipo.DPT,AclTipo.ED] } },
  { path: 'public/lista-pratiche-public', component: ListaPratichePublicComponent},
  { path: 'public/lista-enti', component: ListaEntiPublicComponent},
  { path: 'access', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '',  redirectTo: '/access', pathMatch: 'full'},
  /**
   * Reindirizza le chiamate sbagliate al componente di accesso per controllo autenticazione
  */
  { path: '**',  redirectTo: '/access', pathMatch: 'full'}
]

/// <reference path="./../model/Model.d.ts" />

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
