/**
 * Loadable component.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 12th December 2016
 * @export 
 * @class LoadableComponent
 */
import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'data-loadable',
    templateUrl: 'loadable.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoadableComponent {

    @Input()
    public errorMessage: string = 'C\'è stato un errore durante il caricamento dei dati';

    /**
     * The loading state
     * 
     * @type {boolean}
     * @memberOf LoadableComponent
     */
    @Input()
    public isLoading: boolean;

    /**
     * The updating state
     * 
     * @type {boolean}
     * @memberOf LoadableComponent
     */
    @Input()
    public isUpdating: boolean;

    /**
     * The loading error state
     * 
     * @type {boolean}
     * @memberOf LoadableComponent
     */
    @Input()
    public isError: boolean;

    /**
     * Creates an instance of LoadableComponent.
     * 
     * 
     * @memberOf LoadableComponent
     */
    constructor() {
        // Do nothing
    }

}