import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform
{

  transform(value: string, limit: number = 50): any
  {
    let s = value;
    if(value && value.length > limit)
      s = (value.substr(0, limit).trim()) + "...";
    return s;
  }

}
