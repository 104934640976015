import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CustomValidator {
  dateLessThan(from: string, to: string, error?: ValidationErrors[]) {
    return (group: FormGroup): { [key: string]: any } => {
      let disabledFrom = group.controls[from].disabled;
      let disabledTo = group.controls[to].disabled;

      let dataInizio = group.controls[from].value;
      let dataFine = group.controls[to].value;
      if (dataFine !== '' && dataFine !== null && dataFine !== undefined) {
        let f = this.setDate(new Date(dataInizio));
        let t = this.setDate(new Date(dataFine));
        let today = this.setDate(new Date());

        if (f.getTime() > today.getTime()) {
          group.get(from).setErrors({ disableDateFuture: true });
        }
        if (t.getTime() > today.getTime()) {
          group.get(to).setErrors({ disableDateFuture: true });
        } else if (f.getTime() > t.getTime()) {
          group.get(to).setErrors(disabledTo ? null : { dateError: true });
          group.get(from).setErrors(disabledFrom ? null : { dateError: true });
        } else {
          group.get(to).setErrors(null);
          if (dataInizio !== '' && dataInizio !== null && dataInizio !== undefined) {
            group.get(from).setErrors(null);
          }
        }
      }

      return {};
    };
  }

  dateLessThanToday(to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let dataFine = group.controls[to].value;

      if (dataFine !== '' && dataFine !== null && dataFine !== undefined) {
        let t = this.setDate(new Date(dataFine));
        let today = this.setDate(new Date());

        if (t.getTime() > today.getTime()) {
          group.get(to).setErrors({ disableDateFuture: true });
        } else {
          group.get(to).setErrors(null);
        }
      }
      return {};
    };
  }

  setDate(date: Date): Date {
    let d = new Date(date);
    return (d = new Date(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      0,
      0,
      0,
      0
    ));
  }

  /**
   * @description Validatore che rende required un determinato input solamente quando un altro abstractControl
   * (passato in input al metodo) ha un determinato valore.
   * @param dependency input al quale è legato il required
   * @param expectedValue valore che rende required l'abstractControl a cui è associato questo validator
   */
  requiredDependsOn(
    dependency: AbstractControl,
    expectedValue: any
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let response: ValidationErrors | null = null;
      if (!dependency || !dependency.value || !expectedValue) return response;
      //set di valori che mi fanno scattare la validazione...
      if (expectedValue instanceof Array) {
        let mustRequired: boolean = false;
        expectedValue.forEach((valDep) => {
          if (dependency.value.includes(valDep)) {
            mustRequired = true;
          }
        });
        if (mustRequired && (!control.value || control.value === '')) {
          response = { required: true };
        } else {
          response = null; //valido
        }
      } else {
        //valore secco in expectedValue
        if (
          ((!(dependency.value instanceof Array) &&
            dependency.value === expectedValue) ||
            dependency.value.includes(expectedValue)) &&
          (!control ||
            !control.value ||
            control.value === '' ||
            control.value.length === 0)
        )
          response = { required: true };
      }
      return response;
    };
  }

  /**
   * @description Controllo per invalidare i campi che non presentano testo ma solo spazio
   * @param control
   * @returns
   */
  noWhitespaceValidator(control: AbstractControl) {
    return (c: AbstractControl): { [key: string]: any } => {
      let response: ValidationErrors | null = null;
      if ((control.value || '').trim().length === 0) {
        response = { required: true };
      } else {
        response = null;
      }

      return response;
    };
    // const isWhitespace = (control.value || '').trim().length === 0;
    // const isValid = !isWhitespace;
    // return isValid ? null : { 'whitespace': true };
  }

  dateLessThanTest(from: AbstractControl, to: AbstractControl) {
    return (c: AbstractControl): { [key: string]: any } => {
      let response: ValidationErrors | null = null;
      let disabledFrom = from.disabled;
      let disabledTo = to.disabled;

      let dataInizio = from.value;
      let dataFine = to.value;
      if (dataFine !== '' && dataFine !== null && dataFine !== undefined) {
        let f = this.setDate(new Date(dataInizio));
        let t = this.setDate(new Date(dataFine));
        let today = this.setDate(new Date());

        if (f.getTime() > today.getTime()) {
          response = { disableDateFuture: true };
          // from.setErrors({ disableDateFuture: true });
        }
        if (t.getTime() > today.getTime()) {
          response = { disableDateFuture: true };
          // to.setErrors({ disableDateFuture: true });
        } else if (f.getTime() > t.getTime()) {
          response = disabledTo ? null : { dateError: true };
          response = disabledFrom ? null : { dateError: true };
          // to.setErrors(disabledTo ? null : { dateError: true });
          // from.setErrors(disabledFrom ? null : { dateError: true });
        } else {
          response = null;
          // to.setErrors(null);
          // from.setErrors(null);
        }
      }
      return response;
    };
  }

}
