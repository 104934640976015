import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MapControl } from "regioneveneto-maps";
import {
  BaseLayer,
  Coordinate,
  DrawEvent,
  DrawStartedEvent,
  EntitiesGroup,
  EntityClickedEvent,
  GeometriesDeletedEvent,
  GeometriesEditedEvent,
  GeometryAddedEvent,
  Locale,
  MapOptions,
  MaxEntitiesReachedEvent,
  OverlayLayer,
  WindowSize,
} from "./models/model";

/**
 * Map component
 */
@Component({
  selector: "ng-regioneveneto-map",
  exportAs: "ng-regioneveneto-map",
  encapsulation: ViewEncapsulation.None,
  template: `<div #container [ngStyle]="{'width': windowSize.width, 'height': windowSize.height}"></div>`,
})
export class NgRegioneVenetoMapComponent implements AfterViewInit, OnChanges {

  @Input()
  public windowSize: WindowSize;

  @Input()
  public options: MapOptions;

  @Input()
  public baseLayers: BaseLayer[];

  @Input()
  public overlayLayers: OverlayLayer[];

  @Input("drawModeEnabled")
  public isDrawModeEnabled: boolean;

  @Input("center")
  public center: {
    type: "point" | "boundingBox";
    coordinates: Coordinate | [Coordinate, Coordinate];
  };

  @Input("entitiesGroups")
  public entitiesGroups: EntitiesGroup[];

  @Input("locales")
  public locales: { [key: string]: Locale };

  @Input("currentLanguageCode")
  public currentLanguageCode: string;

  @Output()
  public drawEvent: EventEmitter<DrawEvent> = new EventEmitter<DrawEvent>();

  @Output()
  public entityClickedEvent: EventEmitter<EntityClickedEvent> = new EventEmitter<EntityClickedEvent>();

  @Output()
  public maxEntitiesReachedEvent: EventEmitter<MaxEntitiesReachedEvent> = new EventEmitter<MaxEntitiesReachedEvent>();

  @ViewChild("container")
  public container: ElementRef;

  private mapControl: MapControl;

  /**
   *
   */
  ngAfterViewInit() {
    // Initialize the map using container and initial options
    this.mapControl = new MapControl();

    this.mapControl.createMap(this.container.nativeElement, this.options);

    // Eventually set base and overlay layers
    if (this.baseLayers) this.mapControl.setBaseLayers(this.baseLayers);

    if (this.overlayLayers)
      this.mapControl.setOverlayLayers(this.overlayLayers);

    // Set localization
    if (this.locales) {
      //this.mapControl.updateLocales(this.locales);
    }

    if (this.currentLanguageCode) {
      this.mapControl.setLocale(this.currentLanguageCode);
    }

    if (this.isDrawModeEnabled) {
      this.mapControl.enableDrawMode();
    } else {
      this.mapControl.disableDrawMode();
    }

    // Subscribe to events
    this.mapControl.onDrawStart((event) => {
      this.drawEvent.emit({
        type: "drawStarted",
        data: {
          geometryType: event.layerType,
        },
      } as DrawStartedEvent);
    });

    this.mapControl.onDrawnItem((event) => {
      this.drawEvent.emit({
        type: "geometryAdded",
        data: {
          geometryType: event.type,
          vertices: event.position || event.positions,
          radius: event.radius || undefined,
        },
      } as GeometryAddedEvent);
    });

    this.mapControl.onDrawEdited((event) => {
      this.drawEvent.emit({
        type: "geometriesEdited",
        data: {
          items: event,
        },
      } as GeometriesEditedEvent);
    });

    this.mapControl.onDrawDeleted((event) => {
      this.drawEvent.emit({
        type: "geometriesDeleted",
        data: {
          items: event,
        },
      } as GeometriesDeletedEvent);
    });

    this.mapControl.onEntityClicked((event) => {
      this.entityClickedEvent.emit({
        type: "entityClicked",
        data: {
          item: event,
        },
      });
    });

    this.mapControl.onMaxEntitiesReached((event) => {
      this.maxEntitiesReachedEvent.emit({
        type: "maxEntitiesReached",
      });
    });
  }

  /**
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.mapControl) return;

    // Update base layers
    if (changes.hasOwnProperty("baseLayers"))
      this.mapControl.setBaseLayers((changes as any).baseLayers.currentValue);

    // Update overlay layers
    if (changes.hasOwnProperty("overlayLayers"))
      this.mapControl.setOverlayLayers(
        (changes as any).overlayLayers.currentValue
      );

    // Update draw mode
    if (changes.hasOwnProperty("isDrawModeEnabled"))
      (changes as any).isDrawModeEnabled.currentValue
        ? this.mapControl.enableDrawMode()
        : this.mapControl.disableDrawMode();

    // Update grouped entities
    if (changes.hasOwnProperty("entitiesGroups")) {
      if (!(changes as any).entitiesGroups) {
        return;
      }

      this.updateEntitiesGroups(
        (changes as any).entitiesGroups.previousValue,
        (changes as any).entitiesGroups.currentValue
      );
    }

    // Update center
    if (changes.hasOwnProperty("center")) {
      if (!(changes as any).center.currentValue) {
        return;
      }

      const center = (changes as any).center.currentValue;

      center.type === "point"
        ? this.mapControl.centerMapOnPoint(center.coordinates)
        : this.mapControl.centerMapOnBoundingBox(
            center.coordinates[0],
            center.coordinates[1]
          );
    }

    // Update locals
    if (changes.hasOwnProperty("locales")) {
      const locales = (changes as any).locales.currentValue;

      this.mapControl.updateLocales(locales);
    }

    // Update current language
    if (changes.hasOwnProperty("currentLanguageCode")) {
      const currentLanguageCode = (changes as any).currentLanguageCode
        .currentValue;

      this.mapControl.setLocale(currentLanguageCode);
    }
  }

  /**
   * Calculates the polygon center
   *
   * @param {Coordinate[]} coordinates
   * @returns {Coordinate}
   * @memberof NgRegioneVenetoMap
   */
  public getPolygonCenter(coordinates: Coordinate[]): Coordinate {
    return this.mapControl.getPolygonCenter(coordinates);
  }

  /**
   * Calculates the polygon bounding box
   *
   * @param coordinates
   * @returns
   */
  public getPolygonBoundingBox(coordinates: Coordinate[]): [Coordinate, Coordinate] {
    return this.mapControl.getPolygonBoundingBox(coordinates);
  }

  /**
   * Brings the layer to the front of the layers stack
   *
   * @param {string} layerName
   * @memberof NgRegioneVenetoMap
   */
  public bringLayerToFront(layerName: string): void {
    this.mapControl.bringToFront(layerName);
  }

  /**
   * Sends the layer to the back of the layers stack
   *
   * @param {string} layerName
   * @memberof NgRegioneVenetoMap
   */
  public sendLayerToBack(layerName: string): void {
    this.mapControl.sendToBack(layerName);
  }

  private updateEntitiesGroups(
    previousGroups: EntitiesGroup[],
    currentGroups: EntitiesGroup[]
  ): void {
    const previousGroupsIdsMap: { [id: string]: EntitiesGroup } = {};
    const currentGroupsIdsMap: { [id: string]: EntitiesGroup } = {};

    // Mapping ids
    for (let group of previousGroups) {
      if (group.id) {
        previousGroupsIdsMap[group.id] = group;
      }
    }

    for (let group of currentGroups) {
      if (group.id) {
        currentGroupsIdsMap[group.id] = group;
      }
    }

    // Get deltas
    const addeddGroupsIds = this.subtractArrays(
      Object.keys(currentGroupsIdsMap),
      Object.keys(previousGroupsIdsMap)
    );

    const deletedGroupsIds = this.subtractArrays(
      Object.keys(previousGroupsIdsMap),
      Object.keys(currentGroupsIdsMap)
    );

    const updatedGroupsIds = this.intersectArrays(
      Object.keys(previousGroupsIdsMap),
      Object.keys(currentGroupsIdsMap)
    );

    const addedOrUpdatedGroupsIds = addeddGroupsIds.concat(updatedGroupsIds);

    // Update deltas on map
    for (let id of addedOrUpdatedGroupsIds) {
      this.mapControl.addOrUpdateEntitiesGroup(
        currentGroupsIdsMap[id].layerName,
        currentGroupsIdsMap[id].id,
        currentGroupsIdsMap[id].entities
      );
    }

    for (let id of deletedGroupsIds) {
      this.mapControl.removeEntitiesGroup(id);
    }
  }

  private intersectArrays(
    firstEntities: string[],
    secondEntities: string[]
  ): string[] {
    const result = [];

    for (let entity of secondEntities) {
      if (firstEntities.indexOf(entity) >= 0) {
        result.push(entity);
      }
    }

    return result;
  }

  private subtractArrays(
    firstEntities: string[],
    secondEntities: string[]
  ): string[] {
    const result = [...firstEntities];

    for (let entity of secondEntities) {
      if (firstEntities.indexOf(entity) >= 0) {
        result.splice(result.indexOf(entity), 1);
      }
    }

    return result;
  }
}
