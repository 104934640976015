import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoProceduraService {

  private BASE_URL = environment.urls.base + "/TipoProcedura";

  constructor(private http: HttpClient) { }

  /**
  * Chiamata per recuperare la lista dei Tipi Procedura
  * @param
  * @author Marta Zecchillo
  * @returns TipoProcedura[]
  */
 getTipiProcedura(){
   return this.http.get<ModelAutorizzazionePaesaggistica.TipoProcedura[]>
           (this.BASE_URL+`/select`);
 }


}
