import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';

@Component({
  selector: 'myp-alerts-center',
  templateUrl: './alerts-center.component.html',
  styleUrls: ['./alerts-center.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertsCenterComponent implements OnInit, OnDestroy {

  /**
   *
   *
   * @type {boolean}
   * @memberof AlertsCenterComponent
   */
  public isSidebarOn: boolean =  false;

  /**
   *
   *
   * @type {boolean}
   * @memberof AlertsCenterComponent
   */
  public hasTopSpace: boolean = false;

  /**
   *
   *
   * @private
   * @type {Subscription}
   * @memberof AlertsCenterComponent
   */
  private alertsSubscription: Subscription;

  /**
   *
   *
   * @type {Model.AlertType[]}
   * @memberof AlertsCenterComponent
   */
  public alerts: Model.AlertType[] = [];

  /**
   * Creates an instance of AlertsCenterComponent.
   * @param {AlertsCenterService} alertsCenterService
   * @memberof AlertsCenterComponent
   */
  constructor(
      private alertsCenterService: AlertsCenterService,
      private _snackBar: MatSnackBar
  ) {}

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  /**
   *
   *
   * @memberof AlertsCenterComponent
   */
  public ngOnInit() {
      this.alertsSubscription = this.alertsCenterService.subscribe((alert: Model.AlertType) => {
          this.hasTopSpace = true;
          this.alerts.unshift(alert);
          this._snackBar.open(alert.message,'', {
            duration: alert.closeInMillis?alert.closeInMillis : 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [alert.type]
          });
      });
  }

  /**
   *
   *
   * @memberof AlertsCenterComponent
   */
  public ngOnDestroy() {
      if (this.alertsSubscription)
          this.alertsSubscription.unsubscribe();

  }

}
