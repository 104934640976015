// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urls: {
    base: "/autpae/api",
    samlLogin: "/autpae/saml/login"
  },
  defaultMapCoordinates: {
    data: {
      x: 45.4866000000000, y: 11.99160000000000
    }
  },
  geo:{
    maxResultsConsultazioneMappa:500,
    bboxVeneto:[{lng : 13.10159959, lat : 46.68059681}, {lat : 44.79198856, lng : 10.62312538}],
    coordsPadova:{ lat: 45.4161, lng: 11.8828 },
    layerEditing:"Zona_Autor",
    idGrpEntities:"groupId1",
    drawStyle:{ color: "#da6565" },
    maxGeometriePratica:1
  },
  file:{
    maxFileSize: 3*1024*1024
  },
  layerAggiuntivi:[{
    name: "Ortofoto",
      type: "wms",
      url: "http://idt2-geoserver.regione.veneto.it/geoserver/rv/wms",
      layerId: "rv:Ortofoto2018_Veneto",
      isSelected: false,
  }]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
