<div class="breadcrumbs">
  <span class="title">{{ "dettaglio.gestioneAutpae" | translate }}
    <mat-icon style="vertical-align: middle"> keyboard_arrow_right</mat-icon>
    <span>{{ "report.title" | translate }}</span>
  </span>
</div>
<hr />
<mat-expansion-panel *ngIf="form" class='rett' hideToggle (opened)="arrowFlag = !arrowFlag"
  (closed)='arrowFlag = !arrowFlag'>
  <mat-expansion-panel-header class="colorSearch">
    <mat-panel-title class='search'>
      <mat-icon>search</mat-icon>
      <strong class="title">{{'generic.cerca'|translate|uppercase}}</strong>
      <mat-icon *ngIf="arrowFlag">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="!arrowFlag">keyboard_arrow_down</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="coloredS" [formGroup]="form">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <app-text-field type="number" formControlName="id" [min]="1" [label]="'pratica.idFiltro'|translate"></app-text-field>
      </div>
      <div class="col-md-4 col-sm-12" *ngIf="!isEd">
        <app-select-auto-field formControlName="idEnteDelegato" [label]="'pratica.ente'|translate" [options]="entiDelegati"
          [disabled]="isEd" labelField="label" valueField="value" (valueChange)="evalOnChange('E')">
        </app-select-auto-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="idTipoProcedura" [label]="'pratica.tipoProc'|translate"
          [options]="tpOptions" labelField="label" valueField="value"></app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-range-date-field [label]="'pratica.dataPres'|translate" [startDate]="form.controls.dataPresentazioneRichiestaDa"
          [endDate]="form.controls.dataPresentazioneRichiestaA"></app-range-date-field>
      </div>
      <div class="col-md-4 col-sm-12" *ngIf="!isEd">
        <app-select-auto-field formControlName="idComuneCoinvolto" [label]="'pratica.comune'|translate" [options]="cOptions"
          labelField="label" valueField="value" (valueChange)="evalOnChange('C')"></app-select-auto-field>
      </div>
      <div class="col-md-4 col-sm-12" *ngIf="!isEd">
        <app-select-field formControlName="idProvinciaCoinvolta" [label]="'pratica.provincia'|translate"
          [options]="pOptions" labelField="label" valueField="value" (valueChange)="evalOnChange('P')">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-range-date-field [label]="'pratica.dataRilDin'|translate" [startDate]="form.controls.dataRilascioDiniegoAutpaeDa"
          [endDate]="form.controls.dataRilascioDiniegoAutpaeA"></app-range-date-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="codiceVincolo" [label]="'pratica.tipoVincolo'|translate"
          [options]="tvOptions" labelField="label" valueField="value">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="parereSoprintendenza" [label]="'pratica.parereSoprintendenza'|translate"
          [options]="sinoOptions" labelField="label" valueField="value">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="parereCommissione" [label]="'pratica.parereCommissione'|translate"
          [options]="sinoOptions" labelField="label" valueField="value">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="statoPratica" [label]="'pratica.stato'|translate" [options]="statiOptions"
          labelField="label" valueField="value"></app-select-field>
      </div>
    </div>
  </div>
  <mat-action-row class='colored'>
    <button mat-stroked-button class="pers2" color="warn" (click)="reset()">{{'generic.reset'|translate}}</button>
  </mat-action-row>
</mat-expansion-panel>
<div style="margin-top: 2em; margin-bottom: 2em;">
  <app-select-field [options]="options" labelField="label" valueField="value" [label]="('report.tipoReport'|translate)"
    [formControl]="control" [enable]="submitted" [errors]="control.errors">
  </app-select-field>
</div><br/>
<span>
  <button class="btn btn-success" title="Rimuovi" type="button" (click)="downloadReport()">{{'report.download'|translate}}</button>
</span>

