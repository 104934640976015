<ng-regioneveneto-map
#ngRegioneVenetoMap
(drawEvent)="onDrawEvent($event)"
(entityClickedEvent)="onEntityClickedEvent($event)"
[windowSize]="windowSize"
[options]="options"
[baseLayers]="baseLayers"
[overlayLayers]="overlayLayers"
[drawModeEnabled]="isDrawModeEnabled"
[entitiesGroups]="entitiesGroups"
[center]="centro"
></ng-regioneveneto-map>
