import { MyErrorMatcher } from './MyErrorMatcher';
import { Component, Input, OnInit, Injector } from '@angular/core';
import { ControlValueAccessor, ValidationErrors, NgControl, FormControl } from '@angular/forms';

@Component({ template: '' })
export abstract class BaseControlValueAccessor<T> implements ControlValueAccessor, OnInit
{
    @Input("name") name = '';
    @Input("label") label = '';
    @Input("tooltip") tooltip = '';
    @Input("placeholder") placeholder = '';
    @Input("translate") translate = true;
    @Input("errors") errors_: ValidationErrors = null;
    @Input("enable") set _enable(e: boolean)
    {
        this.enable = e;
        this.myErrorMatcher.enableValidation = e;
    }

    public enable: boolean = false;

    protected ngControl: NgControl;
    public myErrorMatcher = new MyErrorMatcher();

    value: T = null;
    disabled: boolean = false;
    onChange: any = (v: T) => { };
    onTouched: any = () => { };

    constructor(protected inj: Injector) { }

    public ngOnInit(): void
    {
        this.ngControl = this.inj.get(NgControl);
    }

    get control(): FormControl { return this.ngControl.control as FormControl }
    get errors(): ValidationErrors { return this.ngControl.control.errors }

    //Override dei metodi descritti nell'interfaccia ControlValueAccessor
    public writeValue(value: T): void { this.value = value; }
    public registerOnChange(fn: any): void { this.onChange = fn; }
    public registerOnTouched(fn: any): void { this.onTouched = fn; }
    public setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

    //metodi di utility
    public changeBind(value: T): void
    {
        this.value = value;
        this._callTouchAndChange();
    }
    protected _callTouchAndChange(): void
    {
        if (this.onChange)
            this.onChange(this.value);
        if (this.onTouched)
            this.onTouched();
    }
}
