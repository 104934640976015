import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceArray'
})
export class SpaceArrayPipe implements PipeTransform
{

  transform(value: string[]): any
  {
    let s: string[] = [];

    s.push(value[0]);

    for(var i = 1; i<value.length; i++)
      s.push( ' ' + value[i])

    return s;
  }

}
