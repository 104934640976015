export interface Message {
    source: string,
    message: string,
    it: string,
    en: string
}
/**
 * generic source is the default fallback if it isn't found in the source given
 * 
 */
export const messages: Array<Message> = [{
    source: 'generic',
    message: 'draft',
    en: 'draft',
    it: 'bozza',
},
{
    source: 'generic',
    message: 'published',
    en: 'published',
    it: 'pubblicato',
},
{
    source: 'generic',
    message: 'trashed',
    en: 'trashed',
    it: 'messo nel cestino',
},
{
    source: 'generic',
    message: 'new',
    en: 'new',
    it: 'nuovo',
},
{
    source: 'generic',
    message: 'status',
    en: 'status',
    it: 'stato',
},
{
    source: 'generic',
    message: 'type',
    en: 'type',
    it: 'tipo',
},
{
    source: 'generic',
    message: 'Example Service',
    en: 'Example Service',
    it: 'Servizio di esempio',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Update Status Service',
    en: 'Update Status Service',
    it: 'Servizio di aggiornamento dello stato',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Updates the status of an instance',
    en: 'Updates the status of an instance',
    it: 'Aggiorna lo stato di una istanza',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Key of the status',
    en: 'Key of the status',
    it: 'Chiave dello stato',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Label of the status (shown to the user)',
    en: 'Label of the status (shown to the user)',
    it: 'Etichetta dello stato (mostrata all\'utente)',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'This service works as an example',
    en: 'This service works as an example',
    it: 'Questo servizio funziona da esempio',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'This service works as an example',
    en: 'This service works as an example',
    it: 'Questo servizio funziona da esempio',
},
{
    source: 'ContentBrowserComponent',
    message: 'Not found',
    en: 'Not found',
    it: 'Contenuto non trovato',
},
{
    source: 'ContentBrowserComponent',
    message: 'content of this type is not allowed to be created in this folder',
    en: 'content of this type is not allowed to be created in this folder',
    it: 'Non è possibile creare contenuti di questo tipo nella cartella selezionata',
},
{
    source: 'ContentBrowserComponent',
    message: 'delete from the source folder is not possible',
    en: 'Delete from the source folder is not possible',
    it: 'L\'utente non ha il permesso di cancellare contenuti dalla cartella di origine',
},
{
    source: 'ContentBrowserComponent',
    message: 'user is not authorized to perform this transition',
    en: 'User is not authorized to perform this transition',
    it: 'L\'utente non è autorizzato a creare contenuti in questo stato per questo tipo',
},
{
    source: 'ContentBrowserComponent',
    message: 'content in this status cannot be created in this folder',
    en: 'Content in this status cannot be created in this folder',
    it: 'Non possono essere creati contenuti in questo stato per questo tipo',
},
{
    source: 'ContentBrowserComponent',
    message: 'error in indexing',
    en: 'error in indexing',
    it: 'Errore durante l\'indicizzazione del contenuto',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Generate PDF',
    en: 'Generate PDF',
    it: 'Genera PDF'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Generate PDF file from template',
    en: 'Generate PDF file from template',
    it: 'Genera PDF file da template'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Report template to use',
    en: 'Report template to use',
    it: 'Report template da usare'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Workflow variables that will be injected in the template',
    en: 'Workflow variables that will be injected in the template',
    it: 'Variabili del workflow che verranno iniettate nel template'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Name of the output file that will be generated',
    en: 'Name of the output file that will be generated',
    it: 'Nome del file che verrà generato'
},
{
    source: 'DocumentTreesBrowserComponent',
    message: 'mydoc.default',
    en: 'Default view',
    it: 'Visualizzazione predefinita',
},
{
    source: 'DocumentTreesBrowserComponent',
    message: 'mydoc.type',
    en: 'View by instance type',
    it: 'Visualizzazione per tipo istanza',
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Custom Service',
    en: 'Custom Service',
    it: 'Servizio Personalizzato'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'This is a custom service task. Exercise caution. Variables configured here won\'t be available in the model.',
    en: 'This is a custom service task. Exercise caution. Variables configured here won\'t be available in the model.',
    it: 'Questo è un service task personalizzato. Prestare attenzione. Le variabili configurate qui non saranno disponibili nel modello'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Script to generate url',
    en: 'Script to generate url',
    it: 'Script che generetà l\'url'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Http method for the call',
    en: 'Http method for the call',
    it: 'Http method della chiamata'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Script to generate headers',
    en: 'Script to generate headers',
    it: 'Script per generare gli header'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Script to generate payload',
    en: 'Script to generate payload',
    it: 'Script per generare il payload'
},
{
    source: 'ServiceTaskDelegateModalComponent',
    message: 'Script to parse output',
    en: 'Script to parse output',
    it: 'Script per analizzare l\'output della chiamata'
}
]
