import { LocalSessionService } from './../../core/services/local-session.service';
/**
 * Side menu widget component.
 * @export
 * @class ContentBrowserComponent
 */
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { UserService } from './../../core/services/user.service';
import { GestemService } from './../../core/services/gestem.service';
import { Subject } from 'rxjs';

interface MenuItem {
  title?: string;
  label?: string;
  icon?: string;
  items?: MenuItem[];
  itemsCallback?: string;
  command?: Function;
  url?: string;
  routerLink?: string;
  context?: string;
  expanded?: boolean;
  disabled?: boolean;
  separator?: boolean;
  style?: Object;
  styleClass?: string;
  acl?: string[];
  id_html?: string;
  flagCSS_selected?: string;
}

@Component({
  selector: 'myp-side-menu-public',
  templateUrl: './side-menu-public.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./side-menu-public.component.css'],
})
export class SideMenuPublicComponent implements OnInit {
  /**
   *
   *
   * @type {MenuItem[]}
   * @memberof SideMenuComponent
   */
  public items: MenuItem[];
  public ente: string = '';
  public acl: string = '';
  public urlAttivita: string = '';
  public urlRichiesta: string = '';
  public urlPanoramica: string = '';
  public selectedItem: string = '';
  @Input('menu') openCloseMenu: Subject<boolean>;

  private getMenuItems(): MenuItem[] {
    let res: MenuItem[] = [
          {
            label: 'Lista pratiche',
            routerLink: 'public/lista-pratiche-public',
            title: 'Lista pratiche',
            id_html: 'lista-pratiche-public',
          },
          {
            label: 'Lista enti',
            routerLink: 'public/lista-enti',
            title: 'Lista enti',
            id_html: 'lista-enti',
          }
        ]


      /*{
        label: 'Gestione Emergenze',
        icon: 'account_balance',
        context: '/',
        title: 'Gestione Emergenze',
        items: [{
          label: 'Eventi e Template',
          routerLink: '/lista-eventi',
          title: 'Eventi e Template',
          acl: ['dpcpl', 'dgpe'],
          id_html: 'lista-eventi',
          flagCSS_selected: 'eventi'
        }, {
          label: 'Gestione segnalazioni',
          routerLink: '/gestione-segnalazioni',
          title: 'Gestione segnalazioni',
          acl: ['eell', 'dgpe', 'dpcpl'],
          id_html: 'lista-segnalazioni',
          flagCSS_selected: 'segnalazioni'
        }, {
          label: 'Richieste',
          routerLink: '/elenco-richieste',
          title: 'Richieste',
          acl: ['dgpe'],
          id_html: 'elenco-richieste',
          flagCSS_selected: 'richieste'
        }]
      }*/;
    return res;
  }

  /**
   * @readonly
   * @type {MenuItem}
   * @memberof SideMenuComponent
   */
  public get selectedMenu(): MenuItem {
    return this._selectedMenu;
  }

  /**
   * @private
   * @type {MenuItem}
   * @memberof SideMenuComponent
   */
  private _selectedMenu: MenuItem;

  /**
   *
   *
   * @memberof SideMenuComponent
   */
  public set selectedMenu(item: MenuItem) {
    if (item && item.routerLink) {
      this.router.navigate([`${item.routerLink}`]);
    }
    this._selectedMenu = item;
    this.navigateTo = [];
    // this.mainMenuHidden = false;
    // this.toggleMainMenu();
  }

  /**
   *
   *
   * @type {boolean}
   * @memberof SideMenuComponent
   */
  public mainMenuHidden: boolean = true;

  /**
   *
   *
   * @type {string[]}
   * @memberof SideMenuComponent
   */
  public navigateTo: string[];

  /**
   * Creates an instance of ContentBrowserComponent.
   *
   *
   * @memberOf ContentBrowserComponent
   */
  constructor(
    private lss: LocalSessionService,
    private router: Router,
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private gestemService: GestemService
  ) {
    this.matIconRegistry.addSvgIcon(
      'logo_ven',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/logo-regioneveneto.svg'
      )
    );

    this.items = this.getMenuItems();

    // let routerSubscriber = this.router.events.subscribe((event) => {
    //   if (event instanceof RoutesRecognized) {
    //     let route: RoutesRecognized = event;
    //     let url: string = route.urlAfterRedirects
    //       ? route.urlAfterRedirects
    //       : route.url;
    //     let segments = [];
    //     if (this.router.parseUrl(url).root.children['primary']) {
    //       segments =
    //         this.router.parseUrl(url).root.children['primary'].segments;
    //     }

    //     let skipContext = false;
    //     let context: string[] = [];

    //     segments.forEach((segment) => {
    //       if (skipContext) context.push(`/${segment.path}`);

    //       if (segment.path === 'admin') skipContext = true;
    //     });

    //     if (context.length > 0) {
    //       let topLevelContext = context.shift();
    //       this.navigateTo = context;
    //       this.items.forEach((item) => {
    //         if (item.context === topLevelContext) this._selectedMenu = item;
    //       });
    //     }
    //     //Controllo per voci menù quando ricarico pag
    //     this.checkIfSelected(event);
    //   }
    // });
  }

  /**
   * Initialization hook for component lifecycle
   *
   *
   * @memberOf ContentBrowserComponent
   */
  public ngOnInit(): void {
    this.checkSeletedWhenOpenMenu();
  }

  /**
   *
   *
   * @memberof SideMenuComponent
   */
  public toggleMainMenu() {
    this.mainMenuHidden = !this.mainMenuHidden;
  }

  // private checkIfSelected(event: RoutesRecognized) {
  //   /*if (event.url.includes('lista-eventi') || event.url.includes('dettaglio-evento') || event.url.includes('modifica-evento') || event.url.includes('nuovo-evento')) {
  //     this.selectedItem = 'eventi';
  //   } else if (event.url.includes('elenco-richieste') || event.url.includes('nuova-richiesta')) {
  //     this.selectedItem = 'richieste';
  //   }
  //   else this.selectedItem = 'segnalazioni';
  //   */
  //   let url = event.url;
  //   this.refreshSelectedItemMenu(url);
  // }

  private checkSeletedWhenOpenMenu() {
    /*if (this.router.url.includes('lista-eventi') ||
    this.router.url.includes('dettaglio-evento') ||
    this.router.url.includes('modifica-evento') ||
    this.router.url.includes('nuovo-evento')||
    this.router.url.includes('lista-pratiche') ) {
      this.selectedItem = 'eventi';
    } else if (this.router.url.includes('elenco-richieste') || this.router.url.includes('nuova-richiesta')) {
      this.selectedItem = 'richieste';
    }
    else this.selectedItem = 'segnalazioni';
    */
    let url = this.router.url;
    this.refreshSelectedItemMenu(url);
  }

  refreshSelectedItemMenu(url: string) {
    console.log(url)
    if (
      url.includes('lista-pratiche-public')
    ) {
      this.selectedItem = 'lista-pratiche-public';
    } else if (url.includes('lista-enti')) {
      this.selectedItem = 'lista-enti';
    }
  }

  // private iniItems() {
  //   // this.mainMenuHidden = false;
  //   this.gestemService.getConfig().subscribe((data) => {
  //     sessionStorage.setItem('UrlRichiesta', data[0]);
  //     this.urlPanoramica = data[0];
  //     const tmp = this.getMenuItems();
  //     this.items = tmp;

  //     this.selectedMenu = this.items[1];
  //   });
  // }
}
