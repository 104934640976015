/**
 * 
 * 
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 21st September 2017
 * @export
 * @class AlertsCenterService
 */
import { Observable, Subject, Subscription } from 'rxjs';

export class AlertsCenterService {

    /**
     * 
     * 
     * @private
     * @type {Subject<Model.AlertType>}
     * @memberof AlertsCenterService
     */
    private emitter: Subject<Model.AlertType> = new Subject<Model.AlertType>();

    /**
     * 
     * 
     * @private
     * @type {Observable<Model.AlertType>}
     * @memberof AlertsCenterService
     */
    private alerts: Observable<Model.AlertType> = this.emitter.asObservable();

    /**
     * 
     * 
     * @param {(message: Model.AlertType) => {}} callback 
     * @returns {Subscription} 
     * @memberof AlertsCenterService
     */
    public subscribe(callback: (message: Model.AlertType) => void): Subscription {
        return this.alerts.subscribe(callback);
    }

    /**
     * 
     * 
     * @memberof AlertsCenterService
     */
    public showAlert(alert: Model.AlertType){
        this.emitter.next(alert);
    }

}