<mat-table
  [dataSource]="data"
  matSort
  [matSortActive]="id"
  matSortDirection="asc"
  matSortDisableClear
>
  <div *ngFor="let col of header">
    <ng-container [matColumnDef]="col.fieldName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        <span [ngClass]="col.headerStyleClass">{{
          col.label | translate
        }}</span>
      </mat-header-cell>
      <mat-cell
        attr.data-label="{{ col.fieldName }}"
        attr.data-respLabel="{{ col.respLabel }}"
        *matCellDef="let element"
      >
        <ng-container *ngIf="col.type == 'list'; else evalueteDateText">
          <p [innerHtml]="toListString(element[col.fieldName])"></p>
        </ng-container>
        <ng-template #evalueteDateText>
          <ng-container *ngIf="col.type == 'date'; else text">
            <p class="respCal">
              {{
                element[col.fieldName]
                  | date: (col.format ? col.format : "dd/MM/yyyy")
              }}
            </p>
          </ng-container>
          <ng-template #text>
            <p class="respCal">{{ getValue(element, col) }}</p>
            <!--<ng-container *ngIf="col.translate">
                            {{ getValue(element,col)}}
                        </ng-container>
                        <ng-container *ngIf="!col.translate">
                            {{ col.truncate ? (element[col.fieldName]|truncate:col.truncate) : element[col.fieldName]}}
                        </ng-container>-->
          </ng-template>
        </ng-template>
      </mat-cell>
    </ng-container>
  </div>
  <ng-container
    *ngIf="enableAction && action != null && action.length > 0"
    matColumnDef="actions"
  >
    <mat-header-cell
      [ngClass]="'customWidthClass'"
      *matHeaderCellDef
    ></mat-header-cell>
    <mat-cell [ngClass]="'customWidthClass'" *matCellDef="let element">
      <span
        *ngIf="element[id] !== openToggle"
        class="material-icons tool"
        title="apri/chiudi toolbar"
        (click)="openToggle = element[id]"
        >more_horiz</span
      >

      <myp-toolbar
        *ngIf="element[id] === openToggle"
        [toolbar]="toolbar[element[id]]"
        role="toolbar"
        [data]="element"
        (onToolbarEvent)="handleToolbarEvent($event)"
      >
      </myp-toolbar>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<div *ngIf="!this.data || this.data.length == 0">
  <mat-card class="emptyTable">
    <mat-card-content><h6>Non ci sono risultati</h6></mat-card-content>
  </mat-card>
</div>
<mat-paginator
  style="flex-wrap: wrap"
  [length]="totalRecords"
  [pageSize]="defaultPageSize"
  [pageIndex]="pageNumberInit-1"
  [pageSizeOptions]="pageSizeOptions"
  (page)="notifySortPagingChange()"
></mat-paginator>
