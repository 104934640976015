function getRegexFromExpression(expression: string): string[] {
    let expressions = expression.split(',');
    expressions = expressions.map(exp => {
        exp = exp.replace(/\s/g, '');
        if (exp[0] === '-') {
            exp = exp.substr(1);
            exp = `^((?!^${exp}).)*$`;
        }
        else {
            exp = `^${exp}`
        }

        return exp;
    });

    return expressions;
}

/**
 * 
 * 
 * @author Giulio Montagner
 * @param {string} r 
 * @param {string} rKey 
 * @returns 
 */
function verifyResourceWithRegex(r: string, rKey: string) {
    const regexs = getRegexFromExpression(rKey);
    let verified: boolean = true;
    regexs.forEach(regex => {
        const matches = r.match(new RegExp(regex));
        if (!matches || matches.length < 1) {
            verified = false;
        }
    });

    return verified;
};

/**
 * 
 * 
 * @author Giulio Montagner
 * @param {string} r 
 * @param {string} rKey 
 * @returns 
 */
function verifyResourceEquals(r: string, rKey: string) {
    return r === rKey;
}

/**
 * 
 * 
 * @author Giulio Montagner
 * @export
 * @param {{ [key: string]: IUserPermission[] }} permissions 
 * @param {string} resource 
 * @param {boolean} [resourceRegexMatching=false] 
 * @returns {Set<string>} 
 */
export function getAvailablePermissionSet(permissions: { [key: string]: { permission: string }[] }, resource: string, resourceRegexMatching: boolean = false): PolySet<string> {
    const availablePermissions = new PolySet<string>();

    Object.keys(permissions).forEach(resourceKey => {
        let verifyResource: (r: string, rKey: string) => boolean;

        if (resourceRegexMatching) {
            verifyResource = verifyResourceWithRegex;
        }
        else {
            verifyResource = verifyResourceEquals;
        }

        if (verifyResource(resource, resourceKey)) {
            permissions[resourceKey].forEach(p => {
                availablePermissions.add(p.permission)
            })
        }
    });
    return availablePermissions;
}

/**
 * 
 * 
 * @author Giulio Montagner
 * @export
 * @class PolySet
 */
export class PolySet<T> {

    private values: T[] = [];

    public add(value: T) {
        if(!this.has(value))
            this.values.push(value);
    }

    public has(value: T) {
        return this.values.indexOf(value) !== -1;
    }

    public get size() {
        return this.values.length;
    }
}