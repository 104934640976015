import { Component, EventEmitter, forwardRef, Injector, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-text-field',
    template: `
    <mat-form-field appearance="fill" color='accent' class="mat-input-wrapper">
        <mat-label class="big">{{label}}</mat-label>
        <input matInput [errorStateMatcher]="myErrorMatcher" [formControl]="control" [type]="type" 
            [maxLength]="maxlength && maxlength > 0 ? maxlength : 5000" [min]="min" [disabled]="disabled"
            [ngClass]="{'uppercase': uppercase}"  [readonly]="disabled" (input)="changeBind($event.target.value)"/>
        <mat-error *ngIf="errors && enable">
            <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
        </mat-error>
    </mat-form-field>
  `,
    styles: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextFieldComponent),
            multi: true
        }
    ]
})
export class TextFieldComponent extends BaseControlValueAccessor<string>
{
    @Input() uppercase: boolean = false;
    @Input() type = 'text';

    @Input() maxlength: number = null;
    @Input("min") min: number = null;

    @Output("change") onChangeEvt: EventEmitter<string> = new EventEmitter<string>();

    constructor(protected inj: Injector)
    {
        super(inj);
        //this.ngControl.valueAccessor = this;
    }

    public writeValue(value: string): void { this.value = value ? value : ""; }

    public changeBind(value: string): void
    {
        if (this.uppercase)
            value = value.toUpperCase();
        super.changeBind(value);
        this.onChangeEvt.emit(value);
    }

}
