import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { RuoliEntiService } from "./ruoli-enti.service";

@Injectable({
  providedIn: 'root'
})
export class RuoliEntiDataSource implements DataSource<ModelEventiCalamitosi.RuoliEntiDto> {

  private ruoliEntiSubject = new BehaviorSubject<ModelEventiCalamitosi.RuoliEntiDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private errorSubject = new BehaviorSubject<boolean>(false);
  public errors$ = this.errorSubject.asObservable();
  isLoading: boolean = false;
  errorMessage: string = null;

  private countSubject = new BehaviorSubject<number>(null);
  public counter$ = this.countSubject.asObservable();

  constructor(private ruoliEntiService: RuoliEntiService) {
  }

  loadRuoliEnti(ipa: string,
    filter: ModelEventiCalamitosi.RuoliEntiSearchableDto,
    sortDirection: string,
    pageIndex: number,
    pageSize: number) {

    this.loadingSubject.next(true);

    this.ruoliEntiService.getList(pageIndex, pageSize, sortDirection, {
    }).pipe(
      catchError(err => {
        this.errorSubject.next(true);
        return throwError(err);
      }),
      catchError(err => {
        return of([]);
      }),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(result => {
        this.ruoliEntiSubject.next(result['content']);
        this.countSubject.next(result['totalElements']);
      }
      );
  }

  connect(collectionViewer: CollectionViewer): Observable<ModelEventiCalamitosi.RuoliEntiDto[]> {
    return this.ruoliEntiSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ruoliEntiSubject.complete();
    this.loadingSubject.complete();
  }
}