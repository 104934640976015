<div class="al-sidebar-public">
  <ul class="al-sidebar-list my-4" style="padding-top: 20px;" id="al-sidebar-list">
    <li class="public-sidebar-item">
      MENU
      <ul class="al-sidebar-list" id="al-sidebar-list">
        <li>
          <a class="header-button" mat-button routerLink="public/lista-pratiche-public">{{'dashboard.listaPratiche'|translate}}</a>
        </li>
        <li>
          <a class="header-button" mat-button routerLink="public/lista-enti">{{'dashboard.listaEnti'|translate}}</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
