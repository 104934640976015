import { Toolbar, ToolbarEvent } from './toolbar';
import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'myp-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent {


  /**
   * Variabile per assegnare una classe personalizzata al div che racchiude la toolbar (la classe deve essere aggiunta nello toolbar.component.css)
   *
   * @private
   * @type {string}
   * @memberOf PageToolbarComponent
   */
   @Input()
   public persCSSClassDiv: any = '';

   /**
   * Variabile per assegnare una classe personalizzata ai bottoni della toolbar (la classe deve essere aggiunta nello toolbar.component.css)
   *
   * @private
   * @type {string}
   * @memberOf PageToolbarComponent
   */
    @Input()
    public persCSSClassBtn: any = '';

    /**
   * Variabile per assegnare una classe personalizzata alle icone all'interno dei bottoni della toolbar (la classe deve essere aggiunta nello toolbar.component.css)
   *
   * @private
   * @type {string}
   * @memberOf PageToolbarComponent
   */
     @Input()
     public persCSSClassIcon: any = '';


  /**
   * The toolbar description object
   *
   * @private
   * @type {Toolbar}
   * @memberOf PageToolbarComponent
   */
  @Input()
  public toolbar: Toolbar;

  /**
   *
   *
   * @private
   * @type {*}
   * @memberOf PageToolbarComponent
   */
  @Input()
  public data: any;

  /**
   *
   *
   * @private
   * @type {EventEmitter<ToolbarEvent>}
   * @memberOf PageToolbarComponent
   */
  @Output()
  public onToolbarEvent: EventEmitter<ToolbarEvent> = new EventEmitter();

  /**
   *
   *
   * @param {Event} $event
   * @param {string} eventName
   * @param {*} data
   *
   * @memberOf PageToolbarComponent
   */
  public onEvent($event: Event, eventName: string): void {
    let eventToolbar: ToolbarEvent = {
      originalEvent: $event,
      data: this.data,
      event: eventName
    }
    this.onToolbarEvent.emit(eventToolbar);
  }
}
