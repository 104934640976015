/**
 * Search service.
 *
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 21st March 2017
 * @export
 * @class SearchService
 */
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from './user.service';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class SearchService {

    /**
     * Creates an instance of SearchService.
     *
     * @param {HttpClient} http
     *
     * @memberOf SearchService
     */
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService
    ) {
        // Do nothing
    }

    /**
     * Get a content by value and page
     *
     * @param {string} value
     * @param {number} page
     * @param {Array} filter
     * @returns {Promise<Array<Model.SearchResult>>}
     *
     * @memberOf SearchService
     */
    public search(value: string, filter: Array<string>, page: number,
                  pageSize?: number, parent?: string):
                  Promise<Model.PaginatedEntities<Model.InstanceFormSearchResult|Model.ContentSearchResult|Model.NoticeboardSearchResult>> {
        const parameters: HttpParams = new HttpParams();

        parameters.append('query', value);
        parameters.append('page', page.toString());

        if (filter && filter.length > 0) {
            parameters.append('filter', filter.toString());
        }

        if (pageSize) {
            parameters.append('pageSize', pageSize.toString());
        }

        if (parent) {
            parameters.append('parent', parent);
        }

        return this.http
            .get(`/myintranet/${this.userService.ipa}/search-private`, {
                params: parameters
            })
            .toPromise()
            .then(response => (response as Model.PaginatedServiceResponse<Model.InstanceFormSearchResult|Model.ContentSearchResult|Model.NoticeboardSearchResult>).page);
    }

    /**
     * Suggest a list of strings
     *
     * @param {string} value
     * @returns {Promise<Array<Model.SuggestResult>>}
     *
     * @memberOf SearchService
     */
    public suggest(value: string, indexType: 'content' | 'instanceForm' | 'both' = 'both'): Promise<Array<string>> {
        const parameters: HttpParams = new HttpParams();

        parameters.append('query', value);
        parameters.append('indexType', indexType);

        return this.http
            .get(`/myintranet/${this.userService.ipa}/suggest`, {
                params: parameters
            })
            .toPromise()
            .then(response => {
                const items: Array<Model.SuggestResult> = (response  as Model.ServiceResponse<any>).entities;
                const suggestions: Array<string> = new Array<string>();

                items.forEach(item => {
                    suggestions.push(item.suggestedContent);
                });

                const collapsedSuggestions = this.collapseSuggestions(suggestions);
                return collapsedSuggestions;
            });
    }

    /**
     *
     *
     * @private
     * @param {Array<string>} suggestions
     * @returns {Array<string>}
     * @memberof SearchService
     */
    private collapseSuggestions(suggestions: Array<string>): Array<string> {
        const purified = suggestions.map((element) => {
            return element.replace(/[.,\/#!$%\^&\*;:{}=\-_`~() ]/g, '');
        });
        const results: any[] = [];
        purified.forEach((item, index, inArr) => {
            if (inArr.indexOf(item) === index) {

                results.push(suggestions[index]);
            }
        });
        return results;
    }


    /**
     *
     *
     * @param {string} parent
     * @param {string} [name]
     * @param {string} [status]
     * @param {string} [type]
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {string} [orderBy]
     * @returns {Promise<Model.PaginatedEntities<Model.Content>>}
     * @memberof SearchService
     */
    public filterFolder(parent: string, name?: string, status?: string, type?: string,
                        page?: number, pageSize?: number, orderBy?: string, desc?: boolean): Promise<Model.PaginatedEntities<Model.Content>> {
        let parameters  = new HttpParams();
        parameters.append('parent', parent);

        if (name) {
            parameters.append('name', name);
        }

        if (status) {
            parameters.append('status', status);
        }

        if (type) {
            parameters.append('type', type);
        }

        if (page) {
            parameters.append('page', page.toString());
        }

        if (pageSize) {
            parameters.append('pageSize', pageSize.toString());
        }

        if (orderBy) {
            parameters.append('orderBy', orderBy);
        }

        if (desc) {
            parameters.append('desc', desc.toString());
        }

        return this.http
            .get(`/myintranet/${this.userService.ipa}/search/filter-folder`, {
                params: parameters
            })
            .toPromise()
            .then(response => (response as Model.PaginatedServiceResponse<any>).page);
    }

    /**
     *
     *
     * @param {*} preconditions
     * @param {Object} attributes
     * @param {number} page
     * @param {number} [pageSize]
     * @param {string} [sortBy]
     * @param {boolean} [desc]
     * @returns {Promise<Model.PaginatedEntities<Model.ContentSearchResult>>}
     * @memberof SearchService
     */
    public advancedSearch(preconditions: any, attributes: Object, page: number,
                          pageSize?: number, sortBy?: string, desc?: boolean):
                          Promise<Model.PaginatedEntities<Model.ContentSearchResult>> {
        const parameters: HttpParams = new HttpParams();

        parameters.append('page', page.toString());
        if (pageSize) {
            parameters.append('pageSize', pageSize.toString());
        }
        if (sortBy) {
            parameters.append('sortBy', sortBy);
        }
        if (desc) {
            parameters.append('desc', desc.toString());
        }

        return this.http
            .post(`/myintranet/${this.userService.ipa}/search-advanced`, {
                attributes,
                preconditions
            }, {
                params: parameters
            })
            .toPromise()
            .then(response => ( response as Model.PaginatedServiceResponse<any>).page);
    }


}
