<div class="input-group">
  <input *ngIf="!file && !disabled" [value]="null" type="file" class="form-control" [accept]="accept" (change)="selectFile($event)"/>
  <input *ngIf="file || disabled" type="text" class="form-control" [value]="file?.fileName" [disabled]="true" [readonly]="true" />
  <!-- Buttons -->
  <span *ngIf="file && file.id" class="input-group-btn">
    <button class="btn btn-success" title="{{'fileuploader.download'|translate}}" type="button" (click)="downloadFile()">
      {{'fileuploader.download'|translate}}
    </button>
  </span>
  <span class="input-group-btn" *ngIf="!disabled && file">
    <button class="btn btn-danger" title="{{'fileuploader.rimuovi'|translate}}" type="button" (click)="deleteFile()">
      {{'fileuploader.rimuovi'|translate}}
    </button>
  </span>
</div>
