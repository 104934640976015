<div>
  <ng-regioneveneto-map
      #ngRegioneVenetoMap
      (entityClickedEvent)="onEntityClickedEvent($event)"
      [windowSize]="windowSize"
      [options]="options"
      [baseLayers]="baseLayers"
      [overlayLayers]="overlayLayers"
      [drawModeEnabled]="isDrawModeEnabled"
      [entitiesGroups]="entitiesGroups"
      [center]="centro"
    ></ng-regioneveneto-map>
</div>
<!-- <div align="center">
  <button  mat-raised-button color='accent' class='persBtn' (click)="downloadShapeFile()">
    <p class='cont'>Download Shape file</p>
  </button>
</div> -->
