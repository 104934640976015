import { toHttpParams } from 'src/app/utilities/functions';
import { environment } from './../../../environments/environment';
import { PaginatedList } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PublicService
{
    private BASE_URL = environment.urls.base + "/public";
    private URL_SEARCH_PRATICA = this.BASE_URL + "/search";
    private URL_SEARCH_ENTI    = this.BASE_URL + "/searchEnti";

    constructor(private http: HttpClient)
    {

    }

    /**
     * @param search 
     * @returns 
     */
    public listPratichePubblicate(search: ModelAutorizzazionePaesaggistica.PraticaSearch): Observable<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>
    {
        let params: HttpParams = toHttpParams(search);
        return this.http.get<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>(this.URL_SEARCH_PRATICA, {params});
    }

    public listEntiDelegati(search: ModelAutorizzazionePaesaggistica.EntiDelegatiSearch): Observable<PaginatedList<ModelAutorizzazionePaesaggistica.EnteDelegatoBean>>
    {
        let params: HttpParams = toHttpParams(search);
        return this.http.get<PaginatedList<ModelAutorizzazionePaesaggistica.EnteDelegatoBean>>(this.URL_SEARCH_ENTI, { params });
    }

}