
import { Input, Component, ViewEncapsulation } from '@angular/core';
// import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Message box styles
 *
 * @export
 * @enum {number}
 */
export enum MessageBoxStyle {

    // Just
    Information,

    // Yes or no template
    Confirm

}

/**
 * Message box answers
 * @export
 * @enum {number}
 */
export enum MessageBoxAnswer {

    Canceled,

    Yes,

    No,

    Ok

}

@Component({
    selector: 'myp-message-box',
    templateUrl: './message-box.component.html',
    encapsulation: ViewEncapsulation.None

})
export class MessageBoxComponent {

    /**
     * The title for the modal
     * 
     * @type {string}
     * @memberof MessageBoxComponent
     */
    @Input()
    public title: string = 'Messaggio';
    
    /**
     * The message to display
     * 
     * @type {string}
     * @memberOf MessageBoxComponent
     */
    @Input()
    public message: string;
    
    /**
     * The message box style
     * 
     * @type {MessageBoxStyle}
     * @memberOf MessageBoxComponent
     */
    @Input()
    public type: MessageBoxStyle;

    /**
     * Creates an instance of MessageBoxComponent.
     * 
     * @param {NgbActiveModal} activeModal
     * 
     * @memberOf MessageBoxComponent
     */
    /*constructor(public activeModal: NgbActiveModal) {
        // Do nothing
    } */

    /**
     * Raised when a user clicks on any answer, emits event with selected answer
     * 
     * @param {MessageBoxAnswer} event
     * 
     * @memberOf MessageBoxComponent
     */
    public onAnswerClicked(event: MessageBoxAnswer) {
        // this.activeModal.close(event);
    }

}