/**
 * Listview widget component.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 3rd January 2017
 * @export 
 * @class ListViewComponent
 */
import { Component, Input, Output, OnChanges, SimpleChange, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Toolbar, ToolbarEvent } from '../toolbar/toolbar';
import { ItemsPreviewProvider } from './items-preview.provider';

/**
 * A listview item
 * 
 * @export
 * @interface ListViewItem
 * @template T
 */
export interface ListViewItem<T> {

    /**
     * Unique item id
     * 
     * @type {string}
     * @memberOf ListViewItem
     */
    id: string;

    /**
     * The item label
     * 
     * @type {string}
     * @memberOf ListViewItem
     */
    label: string;

    /**
     * The item status (published | draft)
     * 
     * @type {string}
     * @memberOf ListViewItem
     */
    status: string;

    /**
     * The item thumbnail URL
     * 
     * @type {string}
     * @memberOf ListViewItem
     */
    thumbnailUrl: string;

    /**
     * The item entity
     * 
     * @type {T}
     * @memberOf ListViewItem
     */
    entity: T;

}

@Component({
    selector: 'myp-listview',
    templateUrl: 'listview.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ListViewComponent implements OnChanges {

    /**
     * The nodes to display
     * 
     * 
     * @memberOf TreeBrowserComponent
     */
    @Input()
    public items: Array<ListViewItem<any>>;

    /**
     * An optional items preview provider implementor
     * 
     * @type {ItemsPreviewProvider<ListViewItem<any>>}
     * @memberOf ListViewComponent
     */
    @Input()
    public itemsPreviewProvider: ItemsPreviewProvider<ListViewItem<any>>;

    /**
     * 
     * 
     * @type {Toolbar}
     * @memberOf ListViewComponent
     */
    @Input()
    public toolbar: Toolbar;

    /**
     * 
     * 
     * @type {string}
     * @memberOf ListViewComponent
     */
    @Input()
    public messageBox: string;

    /**
     * 
     * 
     * @type {EventEmitter<ToolbarEvent>}
     * @memberOf ListViewComponent
     */
    @Output()
    public onToolbarEvent: EventEmitter<ToolbarEvent> = new EventEmitter();

    /**
     * 
     * 
     * @type {EventEmitter<ListViewItem<any>>}
     * @memberOf ListViewComponent
     */
    @Output()
    public onItemClickedEvent: EventEmitter<ListViewItem<any>> = new EventEmitter<ListViewItem<any>>();

    /**
     * Creates an instance of ListViewComponent.
     * 
     * 
     * @memberOf ListViewComponent
     */
    constructor() {
        // Do nothing
    }

    /**
     * 
     * 
     * @param {ToolbarEvent} event
     * 
     * @memberOf ListViewComponent
     */
    public handleToolbarEvent(event: ToolbarEvent): void {
        this.onToolbarEvent.emit(event);
    }

    /**
     * 
     * 
     * @param {ListViewItem<any>} event
     * 
     * @memberOf ListViewComponent
     */
    public selectItem(item: ListViewItem<any>): void {
        this.onItemClickedEvent.emit(item)
    }

    private getThumbnail(item:ListViewItem<any>):string{
        if(item.entity && !(item.entity['type']==="cm_folder"))
        return 'item-thumbnail'
        else
        return 'icon-folder'
    }

    /**
     * 
     * 
     * @param {{ [propName: string]: SimpleChange }} changes
     * 
     * @memberOf ListViewComponent
     */
    public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes['items'] && this.itemsPreviewProvider)
            this.itemsPreviewProvider.provideItemsPreviews(changes['items'].currentValue);
    }

}