<div class="flex-column">
  <div class="flex-column retro">

    <div class="flex-display">
      <div class="flex-column">
        <div class="login-point" [ngClass]="{'wait-point-bounce':!logout}"></div>
        <div class="wait-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="70px" viewBox="0 0 33.78 20.08">
            <path
              d="M24.08,32.62,22.36,21.71h-.68L19.53,32.34h-3.9L13.48,21.71H12.8L11.08,32.62H7.35L10,18.49H16l1.61,8.65,1.61-8.65h5.94l2.69,14.13Z"
              transform="translate(-7.35 -18.49)" />
            <path d="M31.6,18.5l2.57,10.91h.65L37.4,18.5h3.73l-5,20.07h-3.7L34,32.63H31.35L27.87,18.5Z"
              transform="translate(-7.35 -18.49)" />
          </svg>
        </div>
      </div>
    </div>

    <p *ngIf="logoutForzato">L'utente non ha nessun diritto di utilizzo per l'applicazione Autorizzazioni paesaggistiche</p>

    <p *ngIf="!logout">Logout in corso...</p>
    <p *ngIf="logout">Logout eseguito da Autorizzazioni paesaggistiche.&nbsp;&nbsp;&nbsp;&nbsp;</p>
  </div>
</div>
