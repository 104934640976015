
import { Input, Component, Optional, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'myp-singleselect',
    templateUrl: 'singleselect.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SingleselectComponent implements ControlValueAccessor {


    /**
     * 
     * 
     * @type {Array<string>}
     * @memberof SingleselectComponent
     */
    @Input()
    public values: Array<string>;

    /**
     * 
     * 
     * @public
     * @type {string}
     * @memberof SingleselectComponent
     */
    public selectedOption: string;

    
    /**
     * 
     * 
     * @private
     * @memberof SingleselectComponent
     */
    private propagateChange = (_: any) => { };

    
    /**
     * Creates an instance of SingleselectComponent.
     * @param {NgControl} control 
     * @memberof SingleselectComponent
     */
    constructor(
        @Optional() control: NgControl
    ) {
        if (control)
            control.valueAccessor = this;
    }
    
    /**
     * 
     * 
     * @param {*} value 
     * @memberof SingleselectComponent
     */
    public writeValue(value: any) {
        this.selectedOption = value;
    }

    
    /**
     * 
     * 
     * @param {*} fn 
     * @memberof SingleselectComponent
     */
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    
    /**
     * 
     * 
     * @memberof SingleselectComponent
     */
    public registerOnTouched() {
        // Do nothing
    }
    
    
    /**
     * 
     * 
     * @private
     * @param {Event} event 
     * @param {string} value 
     * @memberof SingleselectComponent
     */
    public valueToggled(event: Event) {
        if (!event)
            this.selectedOption = '';
        
        this.selectedOption = event.currentTarget['value'];
    
        this.propagateChange(this.selectedOption);
    }

}