<div class="main" *ngIf="config">
    <div class="row">
        <div class="col">
            <div class="titles">
                <div class="titles-left">
                    <div class="breadcrumbs">
                        <span *ngIf="config && config.enableAddFavourite" class="favorite-style">
                            <span class="fav-folder icon icon-favourite-o"></span>
                        </span>
                        <span *ngFor="let i = index; let e of config.entries">
                            <span [ngClass]="iconStyle(e, i)"></span>
                            <span [ngClass]="entryStyle(e, i)" (click)="navigate(e)">{{e.label}}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>