import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { getAvailablePermissionSet } from './../utils/AclPermissionsUtils';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class UserService {

    /**
     * 
     * 
     * @private
     * @type {Model.UserProfile}
     * @memberof UserService
     */
    private userInfo: Model.UserProfile = null;

    /**
     * 
     * 
     * @readonly
     * @type {string}
     * @memberof UserService
     */
    public get ipa(): string {
        if (!this.userInfo)
            return null
        return this.userInfo.ipa;
    }

    /**
     * 
     * 
     * @readonly
     * @type {string}
     * @memberof UserService
     */
    public get email(): string {
        if (!this.userInfo)
            return null
        return this.userInfo.email;
    }

    /**
     * 
     * 
     * @readonly
     * @type {Array<string>}
     * @memberof UserService
     */
    public get authorizedIpas(): Array<string> {
        if (!this.userInfo)
            return null
        return this.userInfo.authorizedIpas;
    }

    /**
     * 
     * 
     * @readonly
     * @type {string}
     * @memberof UserService
     */
    public get fullname(): string {
        if (this.userInfo === undefined || this.userInfo === null || !this.userInfo.firstName || !this.userInfo.lastName) {
            return null;
        }
        // return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
        return `"Pippo"`;
    }

    /**
     * 
     * 
     * @readonly
     * @type {Model.UserAcl}
     * @memberof UserService
     */
    public get acl(): Model.UserAcl {
        if (!this.userInfo || !this.userInfo.acl)
            return {};

        return this.userInfo.acl;
    }

    /**
     * 
     * 
     * @author Giulio Montagner
     * @param {string} acl 
     * @param {string} permission 
     * @memberof UserService
     */
    public isAuthorizedFor(acl: string, permissions?: string[], resource?: string, isRegex: boolean = false): boolean {
        const resources = this.acl[acl];

        if (!resources || Object.keys(resources).length === 0) {
            return false;
        }

        if (!permissions) {
            return true;
        }

        if (!resource) {
            const userPermissions = resources['*'];

            let authorized: boolean = false;
            for (let i = 0; !authorized && userPermissions.length > i; i++) {
                if (permissions.indexOf(userPermissions[i].permission) !== -1) {
                    authorized = true;
                }
            }

            return authorized;
        }

        const availablePermissions = getAvailablePermissionSet(resources, resource, isRegex);

        let authorized = false;
        // if a specific set of permissions is not required (rp.permissions === null)
        // but we have at least a permission that matches the resource than go ahead.
        if (!permissions && availablePermissions.size > 0) {
            authorized = true;
        }

        for (let i = 0; !authorized && permissions && permissions.length > i; i++) {
            if (availablePermissions.has(permissions[i])) {
                authorized = true;
            }
        }

        return authorized;
    }

    /**
     * Creates an instance of UserService.
     * 
     * @param {HttpClient} http
     * 
     * @memberOf UserService
     */
    constructor( @Inject(HttpClient) private http: HttpClient, private cookieService: CookieService) {
    }

    /**
     * 
     * 
     * @author Giulio Montagner
     * @returns {Promise<void>} 
     * @memberof UserService
     */
    public loadProfile(): Promise<void> {
        return this.http
            .get('/myintranet/user/profile')
            .toPromise()
            .then(response => {
                this.userInfo = (<Model.ServiceResponse<Model.UserProfile>> response).entity;
            });
    }



    /**
     * Publish portal 
     * 
     * @param {string} path
     * @returns {Promise<string>}
     * 
     * @memberOf UserService
     */
    public setCurrentIpa(ipa: string): Promise<Model.SwitchIpa> {
        const parameters: HttpParams = new HttpParams();
        parameters.append('newIpa', ipa);

        return this.http
            .post(`/myintranet/${this.ipa}/user/ipa`, null, {
                params: parameters
            })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.SwitchIpa>> response).entity);
    }

    /**
     * 
     * 
     * @memberof UserService
     */
    public logoutUser(): void {
        this.cookieService.removeAll();
        this.userInfo = null;
    }
}
