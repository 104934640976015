import { Directive, ElementRef, Input, OnInit, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from './../../core/services/user.service';

export interface ACLDirectiveConfiguration {
    resource?: string;
    permissions: Array<string>;
}

@Directive({ selector: '[mypAcl]' })
export class ACLDirective implements OnInit, OnDestroy {

    /**
     * 
     * 
     * @private
     * @type {Array<string>}
     * @memberof ACLDirective
     */
    private permissions: Array<string> = [];

    /**
     * 
     * 
     * @private
     * @type {string}
     * @memberof ACLDirective
     */
    private resource: string;

    /**
     * 
     * 
     * @private
     * @type {Subscription}
     * @memberof ACLDirective
     */
    private subscription: Subscription;

    /**
     * 
     * 
     * @private
     * @type {Boolean}
     * @memberof ACLDirective
     */
    private isPartial: boolean;

    /**
     * 
     * 
     * @private
     * @type {string}
     * @memberof ACLDirective
     */
    private aclName: string;


    @Input()
    public set mypAcl(obj: ACLDirectiveConfiguration) {
        this.resource = obj.resource;
        this.permissions = obj.permissions;
        this.verifyAcl();
    }


    private verifyAcl() {
        if ((this.aclName && this.permissions) 
           && this.userService.isAuthorizedFor(this.aclName, this.permissions, this.resource, this.isPartial)) {
            this.viewContainer.clear()
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        else {
            this.viewContainer.clear()
        }
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService
    ) {
        // Do nothing
    }


    public ngOnInit() {
        this.subscription = this.activatedRoute.data.subscribe(data => {
            this.aclName = data['acl'].name;
            this.isPartial = data['acl'].isPartial;
            this.verifyAcl();
        })
    }


    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}