import { AuthenticationService } from './../../core/services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { PaginatedList } from 'src/app/model/base-model';
import { PublicService } from './../../services/autorizzazionePaesaggistica/public.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SimpleTableHeader } from 'src/app/shared/components/simple-table/SimpleTableConfig';

@Component({
  selector: 'app-lista-enti-public',
  templateUrl: './lista-enti-public.component.html',
  styleUrls: ['./lista-enti-public.component.scss']
})
export class ListaEntiPublicComponent implements OnInit, OnDestroy
{
  public list: PaginatedList<ModelAutorizzazionePaesaggistica.EnteDelegatoBean>;
  public header: Array<SimpleTableHeader> = this.buildHeader();
  public form: FormGroup

  private enteSearch: ModelAutorizzazionePaesaggistica.EntiDelegatiSearch = this.initSearch();

  constructor(private service: PublicService, 
              private alertsCenterService: AlertsCenterService,
              private authService: AuthenticationService,
              private fb: FormBuilder) { }

  ngOnInit() 
  {
    this.authService.isPublic.next(true);
    this.form = this.fb.group({descrizione: [null]});
    this.search();
  }

  ngOnDestroy(): void
  {
    this.authService.isPublic.next(false);
  }

  public sortAndPaging(event: any): void
  {
    this.enteSearch = { ...this.enteSearch, ...event };
    this.search();
  }

  public onSearch(): void
  {
    this.enteSearch = {
      ...this.form.getRawValue(),
      limit: this.enteSearch.limit,
      page: this.enteSearch.page,
      sortBy: this.enteSearch.sortBy,
      sortType: this.enteSearch.sortType
    };
    this.search();
  }

  private search(): void
  {
    this.service.listEntiDelegati(this.enteSearch).subscribe(
      response => this.list = response,
      errors =>
      { 
        this.alertsCenterService.showAlert({
          message: `Errore lettura dati`,
          type: 'danger',
          autoClosable: false
        });
      });
  }

  private initSearch(): ModelAutorizzazionePaesaggistica.EntiDelegatiSearch
  {
    return {
      descrizione: null,
      page: 1,
      limit: 10,
      sortBy: 'descrizione',
      sortType: 'ASC' 
    }
  }

  private buildHeader(): Array<SimpleTableHeader>
  {
    return [
      {
        fieldName: 'descrizione',
        label: 'enti.descrizione',
        type: 'text',
      },
      {
        fieldName: 'tipoEnte',
        label: 'enti.tipoEnte',
        type: 'text',
      },
      {
        fieldName: 'id',
        label: 'enti.ente',
        type: 'text',
      },
    ]
  }

}
