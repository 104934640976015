import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RuoliEntiService {
  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  public getList(
    page: number,
    size: number,
    sort: string,
    filters?: ModelEventiCalamitosi.RuoliEntiSearchableDto
  ) {

    let searchParams = new HttpParams();
    searchParams = searchParams.append('page', `${page}`);
    searchParams = searchParams.append('size', `${size}`);
    searchParams = searchParams.append('sort', `${sort}`);
    for (let key in filters) {
      if (filters[key] !== null && filters[key].toString().trim() !== '') {
        searchParams = searchParams.append(key, filters[key]);
      }
    }

    return this.http.get<ModelEventiCalamitosi.RuoliEntiDto[]>(
      `${environment.urls.base}/utente/ruoli-enti-list`,
      {
        params: searchParams,
      }
    );
  }

  public aggiornaRuoliService(aclDto: ModelEventiCalamitosi.AclDto) {
    return this.http.post<ModelEventiCalamitosi.AclDto>(
      `${environment.urls.base}/utente/cambia-ruolo-ente`,
      aclDto,
      {
        observe: 'response',
      }
    );
  }

  /**
   * @description Recupero la lista dei ruoli Enti
   * @author Valentina Barba
   * @date 02/12/2021
   * @returns
   */
  public getListRuoliEnte() {
    return this.http.get<ModelEventiCalamitosi.RuoliEntiDto[]>(
      `${environment.urls.base}/utente/list-ruoli-enti`
    );
  }
}
