import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  erroreLogin: boolean;
  caricamento: boolean;
  logoutForzato : boolean = false;
  returnURL: string;
  logout: boolean = false;

  constructor(private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router) {

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser !== null) {
      this.logout = false;
    } else {
      this.logout = true;
    }
  }

  //quando logout viene chiamato ci si considera loggati, in realtà il vero controllo dipende da un httpOnly cookie contenente il JWT
  ngOnInit(): void {
    setTimeout(() => {
      this.route.queryParams.subscribe(params => {
        this.returnURL = params.returnUrl;
        if (sessionStorage.getItem("acl") === "") {
          this.logoutForzato = true;
        };
        this.authenticationService.logout().subscribe (ret => {
            this.logout = true;
        });
      });
      this.erroreLogin = false;
      this.caricamento = true;
    });
  }

}
