import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-generic-dialog-content',
  templateUrl: './generic-dialog-content.component.html',
  styleUrls: ['./generic-dialog-content.component.css']
})
export class GenericDialogContentComponent implements OnInit {
  public form: FormGroup
  public isDpgr: boolean = false;
  public stringDpgr: string;

  constructor(public dialogRef: MatDialogRef<GenericDialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModelEventiCalamitosi.DialogData) {
     }

  ngOnInit(): void {
    this.form = new FormGroup({
      dpgr: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      etichetta:new FormControl('',[Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")]),
      motivoRifiutoSegnalazione:new FormControl('',[Validators.required])
    });
  }
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  controlDpgr() {
    if(this.form.controls.dpgr.valid) {
      this.dialogRef.close(this.form.controls.dpgr.value);
    } else this.form.controls.dpgr.markAsTouched();
  }

  controlEtichetta() {
    if(this.form.controls.etichetta.valid) {
      this.dialogRef.close(this.form.controls.etichetta.value);
    } else this.form.controls.etichetta.markAsTouched();
  }

  controlMotivoRifiuto() {
    if(this.form.controls.motivoRifiutoSegnalazione.valid) {
      this.dialogRef.close(this.form.controls.motivoRifiutoSegnalazione.value);
    } else this.form.controls.motivoRifiutoSegnalazione.markAsTouched();
  }

  getCssClass(){
    if(this.data?.altreOpzioni?.cssClass){
      return [this.data?.altreOpzioni?.cssClass];
    }else{
      return ['confirm'];
    }
    
  }
}
