import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-select-field',
    template: `
        <mat-form-field class="mat-input-wrapper" color="accent" appearance="fill">
            <mat-label class="big">{{label}}</mat-label>
            <mat-select [errorStateMatcher]="myErrorMatcher" [formControl]="control" [disabled]="_disabled" (selectionChange)="changeBind($event.value)">
                <mat-option [value]="null" matTooltip="Nessun valore" matTooltipPosition="below">-- Nessun valore selezionato--</mat-option>
                <mat-option *ngFor="let o of options" [matTooltip]="o[labelField]" matTooltipPosition="below"
                    [value]="valueField ? o[valueField] : o">{{labelField ? o[labelField] : o}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="errors && enable">
                <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
            </mat-error>
        </mat-form-field>
    `,
    styles: [`
          ::ng-deep .mat-select-disabled .mat-select-value {
            color: rgba(0,0,0,.87);
          }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectField),
            multi: true
        }
    ]
})
export class SelectField extends BaseControlValueAccessor<any> implements OnInit
{
    @Input("options") set _options(options: Array<any>)
    {
        this.options = options;
    }

    @Input("labelField") labelField: string = "label";
    @Input("valueField") valueField: string = null;
    @Input("disabled") _disabled: boolean; //Da eliminare

    @Output("valueChange") valueChange: EventEmitter<any>;

    public options: Array<any> = [];

    constructor(protected inj: Injector)
    {
        super(inj);
        this.valueChange = new EventEmitter();
    }

    public changeBind(value: any): void
    {
        let change = !isEqual(this.value, value);
        this.value = value;
        super._callTouchAndChange();
        if (change) this.valueChange.emit({ value: value });
    }
}
