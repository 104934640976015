/**
 * Filter items in order to exclude non renderable ones.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 5th January 2017
 * @export 
 * @class RenderableItemsFilterPipe
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterRenderableItems' })
export class RenderableItemsFilterPipe implements PipeTransform {

    /**
     * Pipe transform
     * 
     * @param {*} value
     * @param {any[]} [args]
     * @returns {any[]}
     * 
     * @memberOf RenderableItemsFilterPipe
     */
    public transform(value: Model.ContentSchemaItem[], args?: any): any[] {
        return value.filter(v => {
            return v.htmlRender !== 'NONE';
        });
    }

}