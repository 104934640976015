/**
 * 
 * 
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 6th September 2017
 * @export
 * @class SafeHtmlPipe
 * @implements {PipeTransform}
 */
import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}