import { PaginatedList } from './../../model/base-model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';
import { toHttpParams } from 'src/app/utilities/functions';
import { List } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PraticaService {
  constructor(private http: HttpClient) { }

  private BASE_URL = environment.urls.base + "/Pratica";
  private URL_PUBLIC_SEARCH        = environment.urls.base + "/public/simpleSearch";
  private URL_SIMPLE_SEARCH        = this.BASE_URL + "/simpleSearch";
  private URL_SIMPLE_SEARCH_GEO    = this.BASE_URL + "/simpleSearchGeo";
  private URL_RIC_CANC             = this.BASE_URL + "/richiestaCancellazione";
  private URL_CANCELLA             = this.BASE_URL + "/cancellaPratica";
  private URL_ELIMINA              = this.BASE_URL + "/eliminaPratica";
  private URL_RIPRISTINA           = this.BASE_URL + "/ripristinaPratica";
  private URL_REPORT               = this.BASE_URL + "/downloadReport";
  private URL_DOWNLOAD_SHAPE_BY_ID = this.BASE_URL + "/downloadShapeById";
  private URL_DOWNLOAD_SHAPE       = this.BASE_URL + "/downloadShape";
  private URL_DOWNLOAD_GEOJSON_BY_ID = this.BASE_URL + "/downloadGeoJsonById";
  private URL_DOWNLOAD_GEOJSON       = this.BASE_URL + "/downloadGeoJson";
  private URL_SIMPLE_SEARCH_RICHIESTA_ANNULLAMENTO  = this.BASE_URL + "/simpleSearchRichiestaAnnullamento";


  /**
  *
  * @param search pratica in stato annullamento
  * @returns
  */
   public simpleSearchPraticheRichiestaAnnullamento(search: ModelAutorizzazionePaesaggistica.PraticaSearch): Observable<List<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>
   {
     let params: HttpParams = toHttpParams(search);
     return this.http.get<List<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>(this.URL_SIMPLE_SEARCH_RICHIESTA_ANNULLAMENTO, {params});
   }


   
   /**
   * Chiamata per generare l'id della nuova pratica
   * @param pratica valorizzato solo con idEnteDelegato e oggetto
   * @author Marta Zecchillo
   * @returns ****
   */
  createPratica(pratica: ModelAutorizzazionePaesaggistica.NewPraticaDto){
    return this.http.post<number>(this.BASE_URL+`/insert`, pratica);
  }

  /**
   * Chiamata per generare l'id della nuova pratica
   * @param pratica valorizzato solo con idEnteDelegato e oggetto
   * @author Marta Zecchillo
   * @returns ****
   */
  savePratica(pratica: ModelAutorizzazionePaesaggistica.PraticaBean,
                autorizzazione?: File, parereSop?: File, parereCommissione?: File){

    let formData = new FormData();

    formData.append("praticaBean", new Blob([JSON.stringify(pratica)], {type: 'application/json'}));

    if(autorizzazione!=undefined&&autorizzazione!=null)
     formData.append("autorizzazione", autorizzazione);
     if(parereSop!=undefined&&parereSop!=null)
      formData.append("parereSop", parereSop);
      if(parereCommissione!=undefined&&parereCommissione!=null)
        formData.append("parereCommissione", parereCommissione);

    return this.http.post<number>(this.BASE_URL+`/update`, formData);
  }


  /**
   * Chiamata per validare la pratica e in caso di pratica valida viene trasmessa
   * @param idPratica
   * @author Marta Zecchillo
   * @returns ****
   */
  validatePratica(idPratica: string){
    return this.http.post<any>(this.BASE_URL+`/valida/`+ idPratica, null);
  }

  /**
   *
   * @param search
   * @author Michele Santoro
   * @returns
   */
  public simpleSearchPratiche(search: ModelAutorizzazionePaesaggistica.PraticaSearch): Observable<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>
  {
    let params: HttpParams = toHttpParams(search);
    return this.http.get<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>(this.URL_SIMPLE_SEARCH, {params});
  }

/**
   *
   * @param search con estrazione anche delle geometrie
   * @author acolaianni
   * @returns
   */
 public simpleSearchPraticheGeo(search: ModelAutorizzazionePaesaggistica.PraticaSearch): Observable<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>
 {
   let params: HttpParams = toHttpParams(search);
   return this.http.get<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>(this.URL_SIMPLE_SEARCH_GEO, {params});
 }


  /**
   * Chiamata per recuperare una pratica in base all'id
   * @param id idPratica
   * @author Marta Zecchillo
   * @returns PraticaBean
   */
  getPratica(idPratica: string){
    //let params = new HttpParams();
    //params = params.append("idPratica", idPratica)
    return this.http.get<ModelAutorizzazionePaesaggistica.PraticaBean>
              (this.BASE_URL+`/dettaglio/`+idPratica);
  }

/**
   *
   * @param search
   * @author Michele Santoro
   * @returns
   */
 public simpleSearchPratichePubblicate(search: ModelAutorizzazionePaesaggistica.PraticaSearch): Observable<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>
 {
   let params: HttpParams = toHttpParams(search);
   return this.http.get<PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>>(this.URL_PUBLIC_SEARCH, {params});
 }
  /**
   * download allegato pratica
   * @author acolaianni
   * @param idPratica
   * @param idAllegato
   * @returns
   */
  downloadAllegatoPratica(idPratica: string,idAllegato:string){
    return this.http.get(
      this.BASE_URL+`/dettaglio/`+idPratica+'/allegato/'+idAllegato,
              { observe: 'response', responseType: 'blob' });
  }

  public richiestaCancellazione(idPratica: number): Observable<boolean>
  {
    let params = new HttpParams().set('idPratica', idPratica.toString());
    return this.http.post<boolean>(this.URL_RIC_CANC, null, {params});
  }

  public cancellaPratica(idPratica: number): Observable<boolean>
  {
    let params = new HttpParams().set('idPratica', idPratica.toString());
    return this.http.post<boolean>(this.URL_CANCELLA, null, {params});
  }

  public eliminaBozzaPratica(idPratica: number): Observable<boolean>
  {
    let params = new HttpParams().set('idPratica', idPratica.toString());
    return this.http.delete<boolean>(this.URL_ELIMINA, {params});
  }

  public ripristinaPratica(idPratica: number): Observable<boolean>
  {
    let params = new HttpParams().set('idPratica', idPratica.toString());
    return this.http.post<boolean>(this.URL_RIPRISTINA, null, { params });
  }

  public downloadReport(search: any, formato: 'pdf'|'xls'|'csv')
  {
    let params: HttpParams = toHttpParams(search);
    params = params.append('formato', formato);
    return this.http.get(this.URL_REPORT, { params, observe: 'response', responseType: 'blob' });
  }

  public downloadShapeById(idPratica: string)
  {
    let params: HttpParams = new HttpParams().set('idPratica', ""+idPratica);
    return this.http.get(this.URL_DOWNLOAD_SHAPE_BY_ID, { params, observe: 'response', responseType: 'blob' });
  }

  public downloadShape(search: any)
  {
    let params: HttpParams = toHttpParams(search);
    return this.http.get(this.URL_DOWNLOAD_SHAPE, { params, observe: 'response', responseType: 'blob' });
  }

  public downloadGeoJsonById(idPratica: string)
  {
    let params: HttpParams = new HttpParams().set('idPratica', ""+idPratica);
    return this.http.get(this.URL_DOWNLOAD_GEOJSON_BY_ID, { params, observe: 'response', responseType: 'blob' });
  }

  public downloadGeoJson(search: any)
  {
    let params: HttpParams = toHttpParams(search);
    return this.http.get(this.URL_DOWNLOAD_GEOJSON, { params, observe: 'response', responseType: 'blob' });
  }

  
}
