<div class="flex-display al-sidebar">
    <ul class="al-sidebar-list" id="al-sidebar-list">
        <li class="unstyled">
            <div class="line-separator"></div>
        </li>

        <li class="al-sidebar-list-item with-sub-menu main-menu"
            [ngClass]="{'ba-sidebar-item-expanded': !mainMenuHidden}">
            <a href (click)="toggleMainMenu(); false" class="al-sidebar-list-link" title="Menu principale">
                <mat-icon class='men-icon'>menu</mat-icon>
                <span class="mymenu">Menu principale</span>
            </a>
            <ul class="al-sidebar-sublist">
                <li *ngFor="let item of items" class="al-sidebar-list-item" [ngClass]="{'no-icon': !item.icon}"
                    [title]="item.title?item.title:item.label">
                    <a href (click)="selectedMenu = item; false" class="al-sidebar-list-link upp-submen-container">
                        <mat-icon class='men-icon'>{{item.icon}}</mat-icon>
                        <span class="mymenu">{{item.label}}</span>
                    </a>
                </li>
            </ul>
        </li>
        <li class="unstyled">
            <div class="line-separator"></div>
        </li>
        <ng-container *ngIf="selectedMenu">
            <ul class="sub-sub-list">
                <li class="al-sidebar-list-item title-item">
                    <span>{{selectedMenu.title}}</span>
                </li>
                <ng-container *ngFor="let item of selectedMenu.items">
                    <li class="al-sidebar-list-item" [ngClass]="{'no-icon': !item.icon, 'selected':selectedItem == item.flagCSS_selected}" id="{{item.id_html}}"
                        [title]="item.title?item.title:item.label">
                        <a [routerLink]="[item.routerLink]" class="al-sidebar-list-link" *ngIf="!item.url">
                            <span class="fa icon men-icon {{item.icon ? item.icon : ''}}"></span>
                            <span>{{item.label}}</span>
                        </a>
                        <a [href]="item.url" class="al-sidebar-list-link" *ngIf="item.url" 
                            [attr.target]="item.targetAttributeValue"
                        >
                            <span class="fa icon men-icon {{item.icon ? item.icon : ''}}"></span>
                            <span>{{item.label}}</span>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </ul>
</div>
