import { Injectable, Inject } from '@angular/core';
import { HttpClient,  HttpParams } from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/toPromise';
import { Subscription } from 'rxjs';

@Injectable()
export class ClientService {

    /**
     * Creates an instance of ClientService.
     * 
     * @param {HttpClient} http
     * 
     * @memberOf ClientService
     */
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService
    ) {
        // Do nothing
    }

    /**
     * Get the content for a template
     * 
     * @param {string} path The page path
     * @returns {Promise<string>}
     * 
     * @memberOf ClientService
     */
    /*public getTemplate(path: string): Promise<string> {
        return this.http
            .get(`/myintranet/${this.userService.ipa}/client/template${path}`)
            .toPromise()
            .then(response => response);
    }*/

    /**
     * Publish portal 
     * 
     * @param {string} path
     * @returns {Promise<string>}
     * 
     * @memberOf ClientService
     */
    /*public publishPortal(): Promise<string> {
        return this.http
            .post(`/myintranet/${this.userService.ipa}/portal/publish`, null)
            .toPromise()
            .then(response => response.text());
    }*/

    /**
     * 
     * 
     * @returns {Promise<Array<Model.Log>>}
     * 
     * @memberOf ClientService
     */
    public getPortalLogs(pageIndex: number = 1, pageSize: number = 20): Promise<Model.PaginatedEntities<Model.PortalStatus>> {
        let parameters = new HttpParams();

        parameters.append('pageSize', pageSize.toString());
        parameters.append('pageIndex', pageIndex.toString());

        return this.http
            .get(`/myintranet/${this.userService.ipa}/portal/logs`, {
                params: parameters
            })
            .toPromise()
            .then(response => (<Model.PaginatedServiceResponse<Model.PortalStatus>> response).page);
    }

    /**
     * 
     * 
     * @returns {Promise<Model.Portal>}
     * 
     * @memberOf ClientService
     */
    public getPortalInfo(): Promise<Model.Portal> {
        return this.http
            .get(`/myintranet/${this.userService.ipa}/portal/info`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Portal>> response).entity);
    }

}