import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnagraficaEnteService {
  private BASE_URL = environment.urls.base + '/AnagraficaEnte';

  constructor(private http: HttpClient) {}

  /**
   * Chiamata per recuperare la lista degli enti delegati di tipo Provincia
   * @param enteDelegato --> ??
   * @author Marta Zecchillo
   * @returns AutoCompleteDto[]
   */
  getAnagraficaEntiProvince() {
    return this.http.get<AutoCompleteDto[]>(
      this.BASE_URL + `/selectProvinciaCoinvolta`
    );
  }

  /**
   * Chiamata per recuperare la lista dei comuni coinvolti in base all'ente delegato
   * @param idEnteDelegato
   * @param idPratica
   * @author Marta Zecchillo
   * @returns DatiComuneDto[]
   */
  getAnagraficaEntiComuni(idEnteDelegato: string, idProvincia: string, idPratica: string) {
    let params = new HttpParams();

    params = params.append('idEnteDelegato', idEnteDelegato);
    params = params.append('idProvincia', idProvincia);
    params = params.append('idPratica', idPratica);

    return this.http.get<ModelAutorizzazionePaesaggistica.DatiComuneDto[]>(
      this.BASE_URL + `/selectComuneCoinvolto`,
      { params: params }
    );
  }
}
