import { MatTooltipModule } from '@angular/material/tooltip';
import { TextAreaFieldComponent } from './../components/textarea-field/textarea-field';
import { SelectAutocompleteField } from './components/select-auto-field/select-autocomplete-field';
import { DateRangePickerField } from './components/range-date-picker/range-date-picker';
import { TruncatePipe } from './pipes/truncate-pipe/truncate.pipe';
import { ChipListMultiselectField } from './components/chip-list-multiselect-field/chip-list-multiselect-field';
import { DatePickerField } from './components/date-picker/date-picker';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SimpleTableComponent } from './components/simple-table/simple-table.component';
import { CommonModule } from '@angular/common';
/**
 * Shared module.
 *
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 13th August 2017
 * @export
 * @class SharedModule
 */
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgArrayPipesModule, NgMathPipesModule } from 'angular-pipes';
import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { InputErrorComponent } from './components/input-error/input-error';
import { ListViewComponent } from './components/listview/listview.component';
import { LoadableComponent } from './components/loadable-component/loadable.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { MyLoadableComponent } from './components/my-loadable-component/my-loadable.component';
import { SelectField } from './components/select-field/select-field';
import { SingleselectComponent } from './components/singleselect/singleselect.component';
import { TextFieldComponent } from './components/text-field/text-field';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { ACLDirective } from './directives/acl.directive';
import { RightSidebarDirective } from './directives/right-sidebar.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { GroupArrayPipe } from './pipes/group-array.pipe';
import { IterableObjectPipe } from './pipes/iterable-object.pipe';
import { RenderableItemsFilterPipe } from './pipes/renderable-items-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MultiselectListComponent } from './components/multiselect-list/multiselect-list.component';
import { NgRegioneVenetoMapComponent } from './components/ng-regioneveneto-map/ng-regioneveneto-map.component';
import { MatChipsModule } from '@angular/material/chips';
import { SpaceArrayPipe } from './pipes/spaceArray.pipe';

/* import { TranslatePipe } from './pipes/translate.pipe'; */

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatNativeDateModule,
  MatButtonModule,
  MatIconModule,
  MatTableModule,
  MatExpansionModule,
  MatGridListModule,
  MatSelectModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatCardModule,
  MatTabsModule,
  MatCheckboxModule,
  NgMathPipesModule,
  NgArrayPipesModule,
  MatChipsModule,
  MatTooltipModule
];

const components = [
  LoadableComponent,
  BreadcrumbsComponent,
  MyLoadableComponent,
  MessageBoxComponent,
  ToolbarComponent,
  MultiselectComponent,
  SingleselectComponent,
  ListViewComponent,
  InputErrorComponent,
  TextFieldComponent,
  TextAreaFieldComponent,
  SelectField,
  SelectAutocompleteField,
  MultiselectListComponent,
  SimpleTableComponent,
  FileUploadComponent,
  DatePickerField,
  ChipListMultiselectField,
  DateRangePickerField,
  NgRegioneVenetoMapComponent
];

const pipes = [
  IterableObjectPipe,
  GroupArrayPipe,
  RenderableItemsFilterPipe,
  CapitalizePipe,
  SafeHtmlPipe,
  TruncatePipe,
  SpaceArrayPipe
  /* TranslatePipe, */
];

const directives = [
  RightSidebarDirective,
  ACLDirective,
]

@NgModule({
    imports: [
        ...modules
    ],
    declarations: [
        ...components,
        ...pipes,
        ...directives
    ],
    exports: [
        // Imports
        ...modules,
        // Declarations
        ...components,
        ...pipes,
        ...directives
    ]
})
export class SharedModule { }
