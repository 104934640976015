import { EnteDelegatoSelectBean, AutocompleteComuniBean, EnteAssociazioneBean } from './../../model/base-model';
import { Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, of } from 'rxjs';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LoaderService } from 'src/app/core/spinner/loader.service';
import { AutoCompleteDto, PaginatedList } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { DialogService } from 'src/app/services/dialog.service';
import { ActionEventResult, SimpleActionLink, SimpleTableHeader } from 'src/app/shared/components/simple-table/SimpleTableConfig';
import { PublicService } from './../../services/autorizzazionePaesaggistica/public.service';
import { TipologicheService } from './../../services/autorizzazionePaesaggistica/tipologiche.service';
import { statiOptions } from 'src/app/utilities/functions';
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-lista-pratiche-public',
  templateUrl: './lista-pratiche-public.component.html',
  styleUrls: ['./lista-pratiche-public.component.scss']
})
export class ListaPratichePublicComponent implements OnInit,OnDestroy {
  
  arrowFlag: boolean = false;
  dettaglio: boolean = false;
  eventDetail: any;

  public header: Array<SimpleTableHeader> = this.buildHeader();
  public actions: Array<SimpleActionLink> = this.buildActions();
  public list: PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>;
  public praticaSearch: ModelAutorizzazionePaesaggistica.PraticaSearch = this.initFilter();
  //Variabili per modal
  title: string;
  content: string;
  confirmed: string;
  inputData: string;

  public entiDelegati: Array<AutoCompleteDto> = [];
  public tpOptions: Array<AutoCompleteDto> = [];
  public rpOptions: Array<AutoCompleteDto> = [];
  public statiOptions: Array<AutoCompleteDto> = [];
  public tiOptions: Array<AutoCompleteDto> = [];
  public pOptions: Array<AutoCompleteDto> = [];
  public cOptions: Array<AutocompleteComuniBean> = [];

  public form: FormGroup;

  public isLoading: boolean = false;

  private allTipiIntervento: Array<ModelAutorizzazionePaesaggistica.TipoInterventoDto> = [];
  private defaultRp: Array<AutoCompleteDto> = [];
  private defaultProvince: Array<AutoCompleteDto> = [];
  private defaultComuni: Array<AutocompleteComuniBean> = [];
  private defaultEnti: Array<EnteDelegatoSelectBean> = [];
  private associazioni: Array<EnteAssociazioneBean> = [];

  constructor(
    private fb: FormBuilder,
    private service: PublicService,
    private tService: TipologicheService,
    private router: Router,
    private dialogService: DialogService,
    private alertsCenterService: AlertsCenterService,
    private authenticationService: AuthenticationService,
    private loader: LoaderService)
  {
  }

  ngOnDestroy(): void
  {
    this.authenticationService.isPublic.next(false);
  }

  ngOnInit()
  {
    this.buildForm();
    this.authenticationService.isPublic.next(true);
    //aggiunto controllo url
    if(this.router.url.includes('?idEnteDelegato=')){
      this.praticaSearch.idEnteDelegato=this.router.url.split('=')[1];
      this.form.controls['idEnteDelegato'].setValue(this.router.url.split('=')[1]);
    }
    this.initPage();
  }

  private initPage(): void
  {
    let toRetrive: ModelAutorizzazionePaesaggistica.DataListSearch = {
      enteDelegato: false,
      provincia: true,
      comuni: true,
      tipoIntervento: true,
      tipoProcedura: true,
      tipoVincolo: false,
      rilasciatoProvincia: true
    }
    combineLatest([this.tService.getDatiSelect(toRetrive), this.tService.getDataForEnteSelect(),
                   this.service.listPratichePubblicate(this.praticaSearch)]).subscribe(([rcn, red, res]) =>
    {
      let rilasciatoProvinciaRemapped=rcn.rilasciatoProvincia.map(el=>{
        //non vogliono vedere -Non applicabile- lato pubblico
        if(el.value=='VUOTO'){
          let elRemapped=el;
          elRemapped.label='-'
          return elRemapped;
        }else {
          return el;
        }
        
      });
      
      this.allTipiIntervento = rcn.tipiIntervento;
      this.defaultRp = rilasciatoProvinciaRemapped;
      this.defaultProvince = rcn.province;
      this.defaultComuni = rcn.comuni;
      this.defaultEnti = red.enti;
      this.associazioni = red.associazioni;

      this.list = res;
      this.tpOptions = rcn.tipiProcedura;
      this.pOptions = rcn.province;
      this.rpOptions = rilasciatoProvinciaRemapped;
      this.cOptions = rcn.comuni;
      this.entiDelegati = this.defaultEnti.map(m => { return { label: m.descrizione, value: m.id } });
      this.tiOptions = rcn.tipiIntervento.map(m => { return { label: m.descrizione, value: m.id } });

      this.statiOptions = statiOptions();

      if(this.form.controls.idEnteDelegato.value != null && this.form.controls.idEnteDelegato.value != undefined
        && this.form.controls.idEnteDelegato.value != ''){
        this.evalOnChange('E');
      }
      /*[
        { label: 'Bozza', value: 'BOZZA' },
        { label: 'Inoltrata', value: 'INOLTRATA' },
        { label: 'Inviata al protocollo', value: 'INVIATA_AL_PROTOCOLLO' },
        { label: 'Errato protocollo', value: 'ERRATO_PROTOCOLLO' },
        { label: 'Protocollata', value: 'PROTOCOLLATA' },
        { label: 'Richiesta di annullamento', value: 'RICHIESTA_DI_CANCELLAZIONE' },
        { label: 'Annullata', value: 'CANCELLATA' },
      ];*/
    },
    () => 
    {
      this.alertsCenterService.showAlert({
        message: `Errore lettura dati`,
        type: 'danger',
        autoClosable: false
      });
    });
  }

  private buildForm(): void
  {
    this.form = this.fb.group({
      idEnteDelegato: [null],
      idTipoProcedura: [null],
      dataPresentazioneRichiesta: [null],
      rilasciatoProvincia: [null],
      dataRilascioDiniegoAutpae: [null],
      numeroAutpae: [null],
      richiedente: [null],
      oggetto: [null],
      idTipoIntervento: [null],
      presenzaParereSop: [null],
      presenzaParereCommLoc: [null],
      idProvinciaCoinvolta: [null],
      idComuneCoinvolto: [null],
      dataTrasmissione: [null],
      dataRichiestaCancellazione: [null],
    });
    /* this.form.controls.idComuneCoinvolto.disable();
    this.form.controls.idTipoIntervento.disable(); */
  }

  private search(): void
  {
    this.service.listPratichePubblicate(this.praticaSearch).subscribe(
    response => {this.list = response, console.log(this.list)},
    error =>
    {
      this.alertsCenterService.showAlert({
        message: `Errore lettura dati`,
        type: 'danger',
        autoClosable: false
      });
    });
  }

  public evalOnChange(_case: 'E'|'C'|'P'|'RP'): void
  {
    switch (_case)
    {
      case 'E':
        this.filterRilasciatoProvincia();
        this.filterProvince();
        this.filterComuni();
        break;
      case 'RP':
        this.filterEnti();
        this.filterProvince();
        this.filterComuni();
        break;
      case 'P':
        this.filterEnti();
        this.filterComuni();
        break;
      case 'C':
        this.filterEnti();
        this.filterProvince();
        break;
    }
  }

  private filterEnti(): void
  {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let enti = this.defaultEnti.slice();

    if (rp != null && rp != 'VUOTO')
      enti = enti.filter(f => f.tipoEnte == 'D');
    if (p != null)
      enti = enti.filter(f => this.associazioni.some(s => "" + s.idProvincia == p && s.idEnteDelegato == f.id));
    if (com != null)
      enti = enti.filter(f => this.associazioni.some(s => "" + s.idComune == com && s.idEnteDelegato == f.id))
    this.entiDelegati = enti.map(m => { return { label: m.descrizione, value: m.id } });
    this.valutaResetIfNotPresent(this.entiDelegati, 'idEnteDelegato');
  }

  private filterProvince(): void
  {
    let ed = this.form.controls.idEnteDelegato.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let province = this.defaultProvince.slice();

    if (ed != null)
    {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(e => e.id == ed);
      if (ente.tipoEnte == 'D')
        province = [province.find(f => f.value == "" + ente.idAe)];
      else
        province = province.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idProvincia == f.value));
    }

    if (com != null)
    {
      let comune = this.cOptions.find(f => f.value == com);
      province = province.filter(f => comune.idProvincia == f.value);
    }
    this.pOptions = province;
    this.valutaResetIfNotPresent(this.pOptions, 'idProvinciaCoinvolta');
  }

  private filterComuni(): void
  {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let ed = this.form.controls.idEnteDelegato.value;
    let comuni = this.defaultComuni.slice();

    if (ed)
    {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(e => e.id == ed);
      switch (ente.tipoEnte)
      {
        case 'A1':
          //Caso ente comune
          comuni = this.defaultComuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          break;
        case 'D':
          //Caso ente provincia
          if (rp == 'ART45A')
            comuni = this.defaultComuni.filter(f => f.idProvincia == "" + ente.idAe);
          else
            comuni = comuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          break;
        default:
          comuni = comuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          if (p) comuni = comuni.filter(f => f.idProvincia == p);
      }
    }
    else if (p)
    {
      comuni = this.defaultComuni.filter(f => f.idProvincia == p);
    }
    this.cOptions = comuni;
    this.valutaResetIfNotPresent(this.cOptions, "idComuneCoinvolto");
  }

  private filterRilasciatoProvincia(): void
  {
    let ed = this.form.controls.idEnteDelegato.value;
    let ril = this.defaultRp.slice();

    if (ed)
    {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(e => e.id == ed);
      if (ente.tipoEnte != 'D')
        ril = [this.defaultRp.find(f => f.value == 'VUOTO')];
    }
    this.rpOptions = ril;
    this.valutaResetIfNotPresent(this.rpOptions, "rilasciatoProvincia");
  }

  public searchOnChange(): void
  {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let ed = rp != 'ART45A' ? this.form.controls.idEnteDelegato.value : null;
    let p = this.form.controls.idProvinciaCoinvolta.value;

    let pObs$ = ed ? this.tService.getProv(ed) : of(this.defaultProvince);
    let cObs$ = p || ed ? this.tService.getCom(ed, p) : of(this.defaultComuni);

    combineLatest([pObs$, cObs$]).subscribe(([prov, com]) =>
    {
      this.pOptions = prov;
      this.cOptions = com;
      this.valutaResetIfNotPresent(prov, "idProvinciaCoinvolta");
      this.valutaResetIfNotPresent(com , "idComuneCoinvolto");
    },
    ([e1, e2]) =>
    {
      this.alertsCenterService.showAlert({
        message: `Errore lettura dati comuni e province`,
        type: 'danger',
        autoClosable: false
      });
    });
  }

  private valutaResetIfNotPresent(v: Array<AutoCompleteDto>, formControlName: string): void
  {
    let actualValue = this.form.controls[formControlName].value;
    if (!actualValue || !v.some(m => m.value == actualValue))
      this.form.controls[formControlName].setValue(null);
  }

  public searchTI(): void
  {
    let tipoProcedura = this.form.controls.idTipoProcedura.value;
    this.form.controls.idTipoIntervento.setValue(null);
    this.tiOptions = this.allTipiIntervento
      .filter(f => !tipoProcedura || f.idTipoProcedura == this.form.controls.idTipoProcedura.value)
      .map(m => { return { label: m.descrizione, value: m.id } });
  }

  public reset(): void
  {
    this.form.reset();
    this.pOptions = this.defaultProvince;
    this.cOptions = this.defaultComuni;
    this.rpOptions = this.defaultRp;
    this.praticaSearch.page=1;
    this.entiDelegati = this.defaultEnti.map(m => { return { label: m.descrizione, value: m.id } });
    this.ricercaListaEventi();
  }

  public ricercaListaEventi(): void
  {

    this.praticaSearch.page=1;
      this.praticaSearch = {
      ...this.form.getRawValue(),
      limit: this.praticaSearch.limit,
      page: this.praticaSearch.page,
      sortBy: this.praticaSearch.sortBy,
      sortType: this.praticaSearch.sortType
    };
    this.search();
  }

  public handleEvent(event: ActionEventResult): void
  {
    switch(event.type)
    {
      case 'details':
        this.router.navigate(['dettaglio-pratica', event.value.id]);
      default:
        console.log("Evento non supportato.");
    }
  }

  public handleSortPagingChange(event: any): void
  {
    this.praticaSearch = {...this.praticaSearch, ...event};
    this.search();
  }

  public initFilter(): ModelAutorizzazionePaesaggistica.PraticaSearch
  {
    return {
      id: null,
      statoPratica: null,
      idEnteDelegato: null,
      idTipoProcedura: null,
      dataPresentazioneRichiesta: null,
      rilasciatoProvincia: null,
      dataRilascioDiniegoAutpae: null,
      numeroAutpae: null,
      richiedente: null,
      oggetto: null,
      idTipoIntervento: null,
      presenzaParereSop: null,
      presenzaParereCommLoc: null,
      idProvinciaCoinvolta: null,
      dataTrasmissione: null,
      dataRichiestaCancellazione: null,
      page: 1,
      limit: 10,
      sortBy: 'id',
      sortType: 'ASC'
    }
  }

  private buildHeader(): Array<SimpleTableHeader>
  {
    return [
      {
        label: 'pratica.enteComp',
        fieldName: 'nomeEnteDelegatoCompetenza',
        type: 'text',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.tipoProc',
        fieldName: 'nomeTipoProcedura',
        headerStyleClass: 'textLeft',
        type: 'text'
      },
      {
        label: 'pratica.dataPres2',
        fieldName: 'dataPresentazioneRichiesta',
        headerStyleClass: 'textLeft',
        type: 'date'
      },
      {
        label: 'pratica.rilasciatoProv2',
        fieldName: 'rilasciatoProvincia',
        headerStyleClass: 'textLeft',
        type: 'text',
        translate:this.traduciRp.bind(this)

      },
      {
        label: 'pratica.provincia',
        fieldName: 'provinciaCoinvolta',
        headerStyleClass: 'textLeft',
        type: 'text'
      },
      {
        label: 'pratica.comuni',
        fieldName: 'comuniCoinvolti',
        headerStyleClass: 'textLeft',
        type: 'list'
      },
      {
        label: 'pratica.dataRilDin2',
        fieldName: 'dataRilascioDiniegoAutpae',
        headerStyleClass: 'textLeft',
        type: 'date'
      },
      {
        label: 'pratica.numero2',
        fieldName: 'numeroAutpae',
        headerStyleClass: 'textLeft',
        type: 'text'
      },
      /*{
        label: 'pratica.richiedente',
        fieldName: 'richiedente',
        headerStyleClass: 'textLeft',
        type: 'text'
      },*/
      {
        label: 'pratica.oggetto',
        fieldName: 'oggetto',
        headerStyleClass: 'textLeft',
        type: 'text'
      },
      {
        label: 'pratica.tipoIntervento',
        fieldName: 'minidescTipoIntervento', //descTipoIntervento
        headerStyleClass: 'textLeft',
        type: 'text'
      },
    ];
  }

  private buildActions(): Array<SimpleActionLink>
  {
    return [
      /*{
        title: 'Dettaglio',
        icon: 'article',
        code: 'details'
      }*/
    ];
  }

  private traduciRp(cellValue:any){
    let ret=cellValue;
    if(cellValue=="VUOTO")
      return "-";
    if(this.rpOptions && this.rpOptions.length>0){
      let val=this.rpOptions.find(el=>el.value==cellValue);
      if(val){
        ret=val.label;
      }
    }
    return ret;
  }

}
