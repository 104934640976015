<div [ngClass]="getCssClass()">
  <h2 class="colorSearch" mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="mat-typography">

    <p class="texted" [innerHTML]="data.message"></p>


    <!-------------------- Dati che servono solo al rifiuto di una segnalazione --------------------------------------------------->
    <mat-form-field class ='w110' [formGroup]="form" *ngIf="data.rifiutaSegnalazione" appearance="fill" color='accent'>
      <mat-label class ='big' >{{data.inputTitle}}</mat-label>
      <textarea matInput [formControlName]="'motivoRifiutoSegnalazione'"></textarea>
      <mat-error *ngIf="this.form.controls.motivoRifiutoSegnalazione.errors">
        <mat-error *ngIf="this.form.controls.motivoRifiutoSegnalazione.errors.required">Campo obbligatorio
        </mat-error>
      </mat-error>
    </mat-form-field>
  <!------------------------------------------------------------------------------------------------------------------------->

    <!-------------------- Dati che servono solo alla duplicazione dpgr --------------------------------------------------->
    <mat-form-field class ='w110' [formGroup]="form" *ngIf="data.input" appearance="fill" color='accent'>
      <mat-label class ='big' >{{data.inputTitle}}</mat-label>
      <input matInput [formControlName]="'dpgr'">
      <mat-error *ngIf="this.form.controls.dpgr.errors">
        <mat-error *ngIf="this.form.controls.dpgr.errors.required">Campo obbligatorio
        </mat-error>
        <mat-error *ngIf="this.form.controls.dpgr.errors.maxlength">Il numero massimo di
          caratteri consentiti è 10</mat-error>
      </mat-error>
    </mat-form-field>
  <!------------------------------------------------------------------------------------------------------------------------->

  <!-------------------- Dati che servono solo alla duplicazione campo --------------------------------------------------->
      <mat-form-field class ='w110' [formGroup]="form" *ngIf="data.duplCamp" appearance="fill" color='accent'>
        <mat-label class ='big' >{{data.inputTitle}}</mat-label>
        <input matInput [formControlName]="'etichetta'">
        <mat-error *ngIf="this.form.controls.etichetta.errors">
          <mat-error *ngIf="this.form.controls.etichetta.errors.required">Campo obbligatorio
          </mat-error>
          <mat-error *ngIf="this.form.controls.etichetta.errors.pattern">Etichetta non può contenere spazi o caratteri speciali</mat-error>
        </mat-error>
      </mat-form-field>
    <!------------------------------------------------------------------------------------------------------------------------->

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color='accent' class='persBtn' mat-dialog-close *ngIf="!data.disableAnnulla">
      <p class='cont'>Annulla</p>
    </button>
    <button *ngIf="!data.input && !data.duplCamp && !data.rifiutaSegnalazione && data.title !== 'Importa template' && data.title !== 'Cambio tipologia' "  mat-raised-button color='accent' class='persBtn' [mat-dialog-close]="true"
      cdkFocusInitial>
      <p class='cont'>{{data?.altreOpzioni?.labelConferma?data?.altreOpzioni?.labelConferma:'Conferma'}}</p>
    </button>
<!-----------------------------BUTTON per duplicazione evento e scelta DPGR -------------------------------------------------->
    <button *ngIf="data.input" mat-raised-button color='accent' class='persBtn'
       (click)="controlDpgr()" cdkFocusInitial>
      <p class='cont'>Salva</p>
    </button>
  <!-------------------------------------------------------------------------------------------------------------------------->

  <!-----------------------------BUTTON per duplicazione campo e scelta etichetta -------------------------------------------------->
  <button *ngIf="data.duplCamp" mat-raised-button color='accent' class='persBtn'
  (click)="controlEtichetta()" cdkFocusInitial>
 <p class='cont'>Conferma</p>
</button>
<!-------------------------------------------------------------------------------------------------------------------------->

  <!-----------------------------BUTTON per aggiungere motivazione rifiuto segnalazione -------------------------------------------------->
  <button *ngIf="data.rifiutaSegnalazione" mat-raised-button color='accent' class='persBtn'
  (click)="controlMotivoRifiuto()" cdkFocusInitial>
 <p class='cont'>Conferma</p>
</button>
<!-------------------------------------------------------------------------------------------------------------------------->

 <!-----------------------------BUTTON per import template -------------------------------------------------->
 <button *ngIf="data.title === 'Importa template'" mat-raised-button color='accent' class='persBtn' [mat-dialog-close]="true" cdkFocusInitial>
<p class='cont'>Importa Template</p>
</button>
<!-------------------------------------------------------------------------------------------------------------------------->

  </mat-dialog-actions>
</div>

