<div class="breadcrumbs">
  <span class="title" *ngIf="!isModifica"
    >{{ "dettaglio.gestioneAutpae" | translate }}
    <mat-icon style="vertical-align: middle"> keyboard_arrow_right</mat-icon>
    {{ "dettaglio.visualizza" | translate }}
  </span>
  <span class="title" *ngIf="isModifica">
    {{ "dettaglio.gestioneAutpae" | translate }}
    <mat-icon style="vertical-align: middle"> keyboard_arrow_right</mat-icon>
    {{ "dettaglio.modifica" | translate }}
  </span>
</div>
<hr />

<form class="flex-form" [formGroup]="form" #formDettaglio>
  <!-- BOTTONI IN ALTO ALLA FORM -->
  <div class="titolo flex-display">
    <div class="flex-display">
      <button
        mat-stroked-button
        type="button"
        class="pers"
        (click)="askConfermaIndietro()"
      >
        <mat-icon style="vertical-align: middle">keyboard_arrow_left</mat-icon>
      </button>

      <span class="h1 upp">{{ "dettaglio.title" | translate }}</span>
    </div>

    <div class="flex-row">
      <button
        mat-stroked-button
        type="button"
        color="basic"
        *ngIf="isModifica"
        class="pers2 mleft"
        (click)="savePratica('trasmetti')"
      >
        {{ "dettaglio.invia" | translate | uppercase }}
      </button>
      <button
        mat-flat-button
        type="button"
        color="accent"
        class="pers3 mleft"
        *ngIf="isModifica"
        (click)="savePratica(null)"
      >
        {{ "dettaglio.salva" | translate | uppercase }}
      </button>
    </div>
  </div>

  <!-- TITOLO FORM  -->
  <div class="pers titles row">
    <div class="title-pratica col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "dettaglio.dataInserimento" | translate }}:
      <span class="spanLeft">
        {{ praticaDto?.dateCreated | date: "dd/MM/yyyy" }}
      </span>
    </div>

    <div class="title-pratica col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "dettaglio.dataConferma" | translate }}:
      <span class="spanLeft">
        {{
          praticaDto?.dataConferma
            ? (praticaDto?.dataConferma | date: "dd/MM/yyyy")
            : "-"
        }}
      </span>
    </div>

    <div class="title-pratica col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "dettaglio.idPratica" | translate }}:
      <span class="spanLeft">
        {{ praticaDto?.id }}
      </span>
    </div>

    <div *ngIf="praticaDto?.statoPratica == 'CANCELLATA'" class="title-pratica col-sm-12 col-md-3">
      {{ "dettaglio.dataRichiestaCancellazione" | translate }}:
      <span class="spanLeft">
        {{ praticaDto?.dataRichiestaCancellazione | date: "dd/MM/yyyy"}}
      </span>
    </div>

    <div class="title-pratica marginTop col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "dettaglio.dataProtocollo" | translate }}:
      <span class="spanLeft">
        {{ dataProtocollo ? (dataProtocollo | date: "dd/MM/yyyy") : "-" }}
      </span>
    </div>
    <div class="title-pratica marginTop col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "dettaglio.numeroProtocollo" | translate }}:
      <span class="spanLeft">
        {{ numeroProtocollo ? numeroProtocollo : "-" }}
      </span>
    </div>

    <div class="title-pratica marginTop col-sm-12" [ngClass]="praticaDto?.statoPratica == 'CANCELLATA'? 'col-md-3' : 'col-md-4'">
      {{ "pratica.stato" | translate }}:
      <span class="spanLeft">
        {{ labelStatoPratica }}
      </span>
    </div>

    <div *ngIf="praticaDto?.statoPratica == 'CANCELLATA'" class="title-pratica col-sm-12 col-md-3">
      {{ "dettaglio.dataCancellazione" | translate }}:
      <span class="spanLeft">
        {{ praticaDto?.dataCancellazione | date: "dd/MM/yyyy"}}
      </span>
    </div>
  </div>

  <!-- CORPO DELLA FORM  -->
  <mat-tab-group animationDuration="0ms"
  (selectedTabChange)="tabChanged($event)">
    <mat-tab
      [label]="'dettaglio.tabs.info' | translate | uppercase"
      [aria-label]="tabInfo ? 'error' : ' success'"
    >
    <div class="container-fluid">
      <!-- ---------- INFORMAZIONI GENERALI ---------- -->
      <!-- READ ONLY -->
      <div class="row">
      <div class="col-md-12 mt-2">
        <app-select-auto-field
          formControlName="idEnteDelegato"
          [label]="('pratica.ente' | translate) + '*'"
          labelField="label"
          valueField="value"
          [options]="entiDelegati"
          [enable]="enable"
        >
        </app-select-auto-field>
      </div>
    </div>
      <!-- /. -->
      <div class="row">
      <div class="col-md-12">
        <app-textarea-field
          formControlName="oggetto"
          [label]="('pratica.oggetto' | translate) + '*'"
          [maxlength]="200"
          [enable]="enable"
        >
        </app-textarea-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-select-field
          formControlName="idTipoProcedura"
          [label]="('pratica.tipoProc' | translate) + '*'"
          labelField="descrizione"
          valueField="id"
          [options]="tipoProcedura"
          (valueChange)="chooseIntervento($event)"
          [errors]="form.get('idTipoProcedura').errors"
          [enable]="enable"
        >
        </app-select-field>
      </div>
    </div>
      <div class="row">
        <div class="col-md-6">
          <app-text-field
            formControlName="richiedente"
            [label]="('pratica.richiedente' | translate) + '*'"
            [maxlength]="200"
            [enable]="enable"
          >
          </app-text-field>
        </div>

        <div class="col-md-6">
          <app-date-field
            formControlName="dataPresentazioneRichiesta"
            [label]="'pratica.dataPres' | translate"
            [matDatepickerFilter]="dateFilter"
            [enable]="enable"
          >
          </app-date-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-text-field
            formControlName="numeroAutpae"
            [label]="('pratica.numero' | translate) + '*'"
            [maxlength]="40"
            [enable]="enable"
            [errors]="form.get('numeroAutpae').errors"
          ></app-text-field>
        </div>

        <div class="col-md-6">
          <app-date-field
            formControlName="dataRilascioDiniegoAutpae"
            [label]="('pratica.dataRilDin' | translate) + '*'"
            [matDatepickerFilter]="dateFilter"
            [enable]="true"
          >
          </app-date-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-text-field
            formControlName="numeroProtocolloEnte"
            [label]="('pratica.numProtocolloEnte' | translate) + '*'"
            [maxlength]="40"
            [enable]="enable"
          ></app-text-field>
        </div>

        <div class="col-md-6">
          <app-date-field
            formControlName="dataProtocolloEnte"
            [label]="('pratica.dataProtocolloEnte' | translate) + '*'"
            [matDatepickerFilter]="dateFilter"
            [enable]="true"
          >
          </app-date-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-select-field
            formControlName="rilasciatoProvincia"
            [label]="('pratica.rilasciatoProv' | translate) + '*'"
            labelField="descrizione"
            valueField="id"
            [options]="tipoRilasciatoProv"
            (valueChange)="
              onValueChange(setComuniCoinvolti, [
                { field: 'comuniCoinvolti', array: true }
              ])
            "
            [errors]="form.get('rilasciatoProvincia').errors"
            [enable]="enable"
          >
          </app-select-field>
        </div>

        <div class="col-md-6">
          <app-select-field
            formControlName="idProvinciaCoinvolta"
            [label]="('pratica.provincia' | translate) + '*'"
            labelField="label"
            valueField="value"
            [options]="provinceCoinvolte"
            (valueChange)="
              onValueChange(setComuniCoinvolti, [
                { field: 'comuniCoinvolti', array: true }
              ])
            "
            [errors]="form.get('idProvinciaCoinvolta').errors"
            [enable]="enable"
          >
          </app-select-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">

          <app-chip-list-multiselect-field
            formControlName="comuniCoinvolti"
            [label]="('pratica.comune' | translate) + '*'"
            labelField="descrizione"
            valueField="id"
            [options]="comuniCoinvolti"
            [duplicates]="false"
            [maxlength]="maxlength"
            [enable]="enable"
            [errors]="form.get('comuniCoinvolti').errors"
          ></app-chip-list-multiselect-field>
        </div>
      </div>
    </div>
    
    </mat-tab>

    <mat-tab
      label="{{ 'dettaglio.tabs.intervento' | translate | uppercase }}"
      [aria-label]="tabIntervento ? 'error' : ' success'"
    >
      <!-- INTERVENTO  -->
      <div class="container-fluid">
      <div class="detail">
        <app-multiselect-list
          formControlName="idTipoIntervento"
          [label]="'pratica.tipoIntervento' | translate"
          labelField="minidescrizione"
          valueField="id"
          [options]="tipoIntervento"
          (change)="chooseDescTipoIntervento()"
          [enable]="enable"
          [errors]="form.get('idTipoIntervento').errors"
        >
        </app-multiselect-list>
      </div>
      
    <!--div  *ngFor="let desc of descrizione_list; let i = index">
      <span>{{desc}}</span>
    </div-->

      <div class="detail" >
        <app-textarea-field
          formControlName="descrizioneTipoIntervento"
          [label]="('pratica.descTipoIntervento' | translate)"
          [enable]="enable"
          #textArea
        >
        </app-textarea-field>
      </div>

    </div>
    </mat-tab>

    <mat-tab
      label="{{ 'dettaglio.tabs.vincoli' | translate | uppercase }}"
      [aria-label]="tabVincoli ? 'error' : ' success'"
    >

      <!-- VINCOLI -->
      <div class="container-fluid">
      <div class="detail">
        <div>  
          <app-multiselect-list
            formControlName="idTipoVincolo"
            [label]="('pratica.tipoVincolo' | translate)"
            labelField="descrizione"
            valueField="codice"
            [options]="tipoVincolo"
            (change)="
              onValueChange(chooseTipoVincolo, [
                { field: 'idCodiceDescrizioneVincolo', array: false },
                { field: 'vincoli', array: true }
              ],null)
            "
            [errors]="form.get('idTipoVincolo').errors"
            [enable]="enable"
          >
          </app-multiselect-list>
        </div>

        <div *ngIf="idTipoVincolo_map.size >0">
          <div *ngFor="let i = index; let vincolo of idTipoVincolo_map | keyvalue;" style="margin-top: 1rem;">
          

        <div  *ngIf="vincolo.key=='ART136_42_2004'" style="border:0.5px solid #878787">
          <div>
            <app-multiselect-list
              formControlName="idCodiceDescrizioneVincolo1"
              [label]="('pratica.descTipoVincolo' | translate) +' '+vincolo.key "
              labelField="minidescrizione"
              valueField="codiceDescrizioneVincolo"
              [options]="vincolo.value.vincolo_descrizione"
              (change)="
                onValueChange(      
                  chooseDescTipoVincolo,
                  [{ field: 'vincoli', array: true }],
                  $event,vincolo.key
                )
              "
              [errors]="form.get('idCodiceDescrizioneVincolo1').errors"
              [enable]="enable"
            >
            </app-multiselect-list>
          </div>

          <div>
            <app-textarea-field
              formControlName="descrizioneEstTipoVincolo1"
              [label]="('pratica.descExTipoVincolo' | translate) +' '+vincolo.key "
              [enable]="enable"
            >
            </app-textarea-field>
          </div>
          </div>


          <div *ngIf="vincolo.key=='ART142_42_2004'" style="border:0.5px solid #878787">
            <div>
            <app-multiselect-list
              formControlName="idCodiceDescrizioneVincolo2"
              [label]="('pratica.descTipoVincolo' | translate) +' '+vincolo.key "
              labelField="minidescrizione"
              valueField="codiceDescrizioneVincolo"
              [options]="vincolo.value.vincolo_descrizione"
              (change)="
                onValueChange(      
                  chooseDescTipoVincolo,
                  [{ field: 'vincoli', array: true }],
                  $event,vincolo.key
                )
              "
              [errors]="form.get('idCodiceDescrizioneVincolo2').errors"
              [enable]="enable"
            >
            </app-multiselect-list>
          </div>

          <div > 
            <app-textarea-field style="padding-bottom:0.5rem"
              formControlName="descrizioneEstTipoVincolo2"
              [label]="('pratica.descExTipoVincolo' | translate) +' '+vincolo.key "
              [enable]="enable"
            >
            </app-textarea-field>
          </div>
          </div>


          </div>
      </div>


      <!--div style="border: groove;margin-top:2rem;">
        <div>
          <app-multiselect-list
            formControlName="idCodiceDescrizioneVincolo"
            [label]="('pratica.descTipoVincolo' | translate)"
            labelField="minidescrizione"
            valueField="codiceDescrizioneVincolo"
            [options]="descTipoVincolo"
            (change)="
              onValueChange(
                chooseDescTipoVincolo,
                [{ field: 'vincoli', array: true }],
                $event
              )
            "
            [errors]="form.get('idCodiceDescrizioneVincolo').errors"
            [enable]="enable"
          >
          </app-multiselect-list>
        </div>

        <div>
          <app-textarea-field
            formControlName="descrizioneEstTipoVincolo"
            [label]="('pratica.descExTipoVincolo' | translate)"
            [enable]="enable"
          >
          </app-textarea-field>
        </div>
      </div-->

        <app-chip-list-multiselect-field
          *ngIf="false"
          formControlName="vincoli"
          [label]="('pratica.idVincolo' | translate) + '*'"
          labelField="label"
          valueField="value"
          [options]="vincolo"
          [duplicates]="false"
          [enable]="enable"
          [errors]="form.get('vincoli').errors"
        >
        </app-chip-list-multiselect-field>
      </div>
    </div>
    </mat-tab>
    <mat-tab
      label="{{ 'dettaglio.tabs.mappa' | translate | uppercase }}"
    >
    </mat-tab>
    <mat-tab
      label="{{ 'dettaglio.tabs.allegati' | translate | uppercase }}"
      [aria-label]="tabAllegati ? 'error' : ' success'"
    >

      <!-- ALLEGATI -->
      <div class="allegati">
        <span class="text-allegati">{{ getDescrizioneAllegatoAutorizzazione() }}*</span>
        <hr />
        <app-file-upload
          [accept]="mimeAccepted"
          [disabled]="isDettaglio"
          [file]="praticaBean?.autorizzazione"
          [maxSize]="maxSizeFile"
          (delete)="deleteFile($event, tipoDocumentoEnum.AUTORIZZAZIONE)"
          (download)="downloadFile($event)"
          (select)="selectFile($event, tipoDocumentoEnum.AUTORIZZAZIONE)"
        ></app-file-upload>
        <app-input-error
          class="validationFile"
          [errors]="validationFileAutorizzazione"
          [enable]="enable"
        ></app-input-error>
      </div>

      <div class="allegati">
        <span class="text-allegati">{{
          "dettaglio.allegati.soprintendenza" | translate
        }}</span>
        <hr />
        <app-select-field
          formControlName="presenzaParereSop"
          [label]="
            ('dettaglio.allegati.soprintendenzaPresente' | translate) + '*'
          "
          labelField="label"
          valueField="value"
          [options]="parereSop"
          [errors]="form.get('presenzaParereSop').errors"
          [enable]="enable"
          (valueChange)="
            disableAllegati($event.value, tipoDocumentoEnum.PARERE_SOP, true)
          "
        >
        </app-select-field>

        <app-file-upload
          [accept]="mimeAccepted"
          [disabled]="disabledFileParereSop || isDettaglio"
          [file]="praticaBean?.parereSop"
          [maxSize]="maxSizeFile"
          (delete)="deleteFile($event, tipoDocumentoEnum.PARERE_SOP)"
          (download)="downloadFile($event)"
          (select)="selectFile($event, tipoDocumentoEnum.PARERE_SOP)"
        ></app-file-upload>
        <app-input-error
          class="validationFile"
          [errors]="validationFilePareSop"
          [enable]="enable"
        ></app-input-error>
      </div>

      <div class="allegati">
        <span class="text-allegati">{{
          "dettaglio.allegati.commissione" | translate
        }}</span>
        <hr />
        <app-select-field
          formControlName="presenzaParereCommLoc"
          [label]="('dettaglio.allegati.commissionePresente' | translate) + '*'"
          labelField="label"
          valueField="value"
          [options]="parereComLoc"
          [errors]="form.get('presenzaParereCommLoc').errors"
          [enable]="enable"
          (valueChange)="
            disableAllegati(
              $event.value,
              tipoDocumentoEnum.PARERE_COMMLOC,
              true
            )
          "
        >
        </app-select-field>

        <app-file-upload
          [accept]="mimeAccepted"
          [file]="praticaBean?.parereCommLoc"
          [disabled]="disabledFileParereCommLoc || isDettaglio"
          [maxSize]="maxSizeFile"
          (delete)="deleteFile($event, tipoDocumentoEnum.PARERE_COMMLOC)"
          (download)="downloadFile($event)"
          (select)="selectFile($event, tipoDocumentoEnum.PARERE_COMMLOC)"
        ></app-file-upload>
        <app-input-error
          class="validationFile"
          [errors]="validationFilePareCommLoc"
          [enable]="enable"
        ></app-input-error>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
<!-- messo fuori dai tab perchè altrimenti mappa va in effetto tassellatura e non renderizza correttamente -->
<div *ngIf="tabMappa">
  <app-tab-mappa [entitiesInput]="entitiesInput" [isDrawModeEnabled]="isModifica" (modifica)="modificaMappa($event)"></app-tab-mappa>
  <div class="row my-4">
    <button
    *ngIf="geoJson && geoJson?.features && geoJson?.features.length > 0"
    mat-stroked-button
    color="basic"
    type="button"
    class="pers2 mleft"
    (click)="downloadShape()"
    > {{'dettaglio.downloadShape'|translate|uppercase}}<mat-icon>download</mat-icon>
    </button>
    <button
    *ngIf="geoJson && geoJson?.features && geoJson?.features.length > 0"
    mat-stroked-button
    color="basic"
    type="button"
    class="pers2 mleft"
    (click)="downloadGeoJson()"
    > {{'dettaglio.downloadGeoJson'|translate|uppercase}}<mat-icon>download</mat-icon>
    </button>

  </div>
</div>