/**
 *
 * 
 * @author Matteo Trevisan
 * @version 1.0.0
 * @since 25th August 2017
 * @export 
 * @class MyLoadableComponent
 */
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'myp-loadable',
    templateUrl: 'my-loadable.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MyLoadableComponent implements OnInit {

    @Input()
    public isLoading: boolean;

    @Input()
    public isUpdating: boolean;

    @Input()
    public isError: boolean;

    public ngOnInit(): void {
    }

}
