/**
 *
 * @export
 * @class ErrorLoggerInterceptor
 */
import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable()
export class ErrorLoggerInterceptor implements HttpInterceptor {


    constructor() {
        // Do nothing
    }

    /*
     * 
     */
    /*public interceptAfter(r: InterceptedResponse): InterceptedResponse {
        if (r.response.status !== 200 && r.response.headers.get('x-myplace-requestcontext')) {
            console.error(`xMyPlaceRequestContext: ${r.response.headers.get('x-myplace-requestcontext')}`);
            console.error(r.response.text());
        }

        return r;
    }*/

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
            return next.handle(req).pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    if (error.status !== 200) {
                        console.log("errore:{}",error)
                    }
                    return throwError(error);
                }
            )
        );
    }
}
