import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestemService {
  
  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  public getConfig(){
    return this.http.get<string>(`${environment.urls.base}/utente/getconfig`);
  }
}
