import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

/*
<mat-select-trigger *ngFor="let o of options">
      <span> {{valueField ? o[valueField]: ''}}</span>
  </mat-select-trigger>
</mat-select>
*/

@Component({
  selector: 'app-multiselect-list',
  template: `
      <mat-form-field class="mat-input-wrapper" color="accent" appearance="fill">
          <mat-label class="big">{{label}}</mat-label>
          <mat-select [disabled]="disabled || readonly" [ngModel]="value" 
          (selectionChange)="changeBind($event.value)" multiple>
            <mat-option *ngFor="let o of options" [value]="valueField ? o[valueField] : o">
                {{labelField ? o[labelField] : o}}
            </mat-option>
            </mat-select>
      </mat-form-field>
      <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
  `,

  styles: [],
  providers: [
      {
          provide: NG_VALUE_ACCESSOR,
          useExisting: forwardRef(() => MultiselectListComponent),
          multi: true
      }
  ]
})
export class MultiselectListComponent extends BaseControlValueAccessor<any> 
{
    @Input("options") set _options(options: Array<any>)
    {
        this.options = options;
        this.filteredOptions = this.options;
        this.buildInitText();
    }

    @Input("filter") filter: boolean = false;
    @Input("labelField") labelField: string = "label";
    @Input("valueField") valueField: string = null;

    @Input("disabled") readonly: boolean = false;

    @Output("change") change: EventEmitter<any>;

    public options: Array<any> = [];
    public filteredOptions: Array<any> = [];

    public initTextLabel: string = "";

    public fn = this.displayFn.bind(this);

    constructor(protected inj: Injector)
    {
        super(inj);
        this.change = new EventEmitter<any>();
        this.filteredOptions = this.options;
    }

    public writeValue(value: any)
    {
        super.writeValue(value);
        this.buildInitText();
    }

    public changeBind(value: any): void
    {
        super.changeBind(value);
        this.change.emit({ value: value });
    }

    private displayFn(obj: any): string
    {
        let v = ''
        if (obj)
        {
            let p = this.options.find(f => this.valueField ? f[this.valueField] == obj : f == obj);
            v = this.labelField ? p[this.labelField] : p;
        }
        return v;
    }

    public filterOptions(value: string): void
    {
        const filterValue = value.toLowerCase();
        this.filteredOptions = this.options.filter(f => this.labelField ? f[this.labelField].toLowerCase().includes(filterValue) : f.toLowerCase().includes(filterValue));
    }

    private buildInitText(): any
    {
        if (this.options && this.filter)
        {
            let o = null;
            if (this.valueField && this.valueField != "")
                o = this.options.find(p => p[this.valueField] == this.value);
            else
                o = this.options.find(p => isEqual(p, this.value));
            this.initTextLabel = o ? this.labelField ? o[this.labelField] : o : '';
        }
    }

    get optionSelected(): any
    {
        let o: any = null;
        if (this.options)
        {
            if (this.valueField && this.valueField != "")
                o = this.options.find(p => isEqual(p[this.valueField], this.value));
            else
                o = this.options.find(p => isEqual(p, this.value));
        }
        return o && this.valueField ? o[this.value] : o;
    }
}
