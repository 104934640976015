/**
 * Header widget component.
 * @export 
 * @class HeaderComponent
 */
import { Component, Input, ViewEncapsulation, HostListener} from '@angular/core';
import { UserService } from './../../core/services/user.service';
import { ClientService } from './../../core/services/client.service';
import { GlobalState } from './../../core/services/global-state.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageBoxComponent, MessageBoxAnswer } from './../../shared/components/message-box/message-box.component';

@Component({
    selector: 'myp-header',
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

    /**
     * 
     * 
     * @type {boolean}
     * @memberof HeaderComponent
     */
    public isScrolled: boolean = false;

    /**
     * 
     * 
     * @type {boolean}
     * @memberof HeaderComponent
     */
    public isMenuCollapsed: boolean = false;

    /**
     * 
     * 
     * @type {boolean}
     * @memberof HeaderComponent
     */
    public userDropdown: boolean = false;

    /**
     * User's current IPA
     * 
     * @type {string}
     * @memberOf HeaderComponent
     */
    @Input()
    public selectedIpa: string;

    /**
     * List of all the IPAs the user has roles into
     * 
     * @type {Array<string>}
     * @memberOf HeaderComponent
     */
    public authorizedIpas: Object[];

    /**
     * 
     * 
     * @type {string}
     * @memberof HeaderComponent
     */
    public searchInputModel: string;

    /**
     * 
     * 
     * @type {string}
     * @memberof HeaderComponent
     */
    public redirectLogout: string

    /**
     * Creates an instance of HeaderComponent.
     * 
     * 
     * @memberOf HeaderComponent
     */
    constructor(private userService: UserService,
                private clientService: ClientService,
                private state: GlobalState, private router: Router) {
        /*this.authorizedIpas = this.userService.authorizedIpas.map(ipa => {
            return {
                label: ipa,
                value: ipa
            };

        }); */

        this.selectedIpa = this.userService.ipa;

        this.state.subscribe('menu.isCollapsed', (isCollapsed: boolean) => {
            this.isMenuCollapsed = isCollapsed;
        }, 'header.component');

        this.redirectLogout = `/myintranet/${this.selectedIpa}/logout`;
    }

    @HostListener('document:click', ['$event'])
    handleClick(event: any) {
        if (this.userDropdown && !event.target.classList.contains('user-profile-dropdown')) {
            this.userDropdown = false;
        }
    }

    /**
     * Called when the user chages IPA 
     * 
     * 
     * @memberOf HeaderComponent
     */
    public onIpaChange(value: string): void {
        if (this.selectedIpa !== value) {
            this.userService.setCurrentIpa(value)
                .then(result => {
                    location.assign(result.redirectUrl);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    public get userFullname(): string {
        return this.userService.fullname || this.userService.email;
    }

    /**
     * 
     * 
     * @returns 
     * @memberof HeaderComponent
     */
    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this.state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    /**
     * 
     * 
     * @param {any} isScrolled 
     * @memberof HeaderComponent
     */
    public scrolledChanged(isScrolled: boolean) {
        this.isScrolled = isScrolled;
    }

    /**
     * 
     * 
     * @memberof HeaderComponent
     */
    public searchContent() {
        if(this.searchInputModel && this.searchInputModel!=="")
        this.router.navigate(['/myintranet/' + this.selectedIpa + '/admin/my-cms/search'], { queryParams: { pattern: this.searchInputModel, IPA : this.selectedIpa } })
    }

    /**
     * 
     * 
     * @memberof HeaderComponent
     */
    public logout(form : any): void {
        /*if (this.userFullname){
            let modalReference = this.modalService.open(MessageBoxComponent, { backdrop: 'static', keyboard: false });
            
            modalReference.componentInstance.title = 'Attenzione';
            modalReference.componentInstance.message = 'Sei sicuro di voler uscire?';
            modalReference.componentInstance.type = 1;
            
            modalReference.result.then((res:  MessageBoxAnswer) => {
                if (res ===  MessageBoxAnswer.No)
                    return;

                form.submit();
            })
        }*/
    }

}