/**
 * Dom service.
 * 
 * @author Nicola Prenna
 * @version 1.0.0
 * @since 6th June 2017
 * @export 
 * @class DomService 
 */

import { Injectable, Inject } from '@angular/core';

@Injectable()
export class DomService {
    
    /**
     * Generates Dynamic HTML for Template Dom
     * 
     * @param {string} fatherId
     * @param {number} rowHeight
     * @param {Array<any>} elements
     * @param {('preview' | 'content')} field
     * @returns {HTMLDivElement}
     * 
     * @memberOf DomService
     */
    public generateElements(fatherId: string, rowHeight: number, elements: Array<any>, field: 'preview' | 'content'): HTMLDivElement {
        let externalElement = document.createElement("div");
        externalElement.id = `ext_${fatherId}`;
        externalElement.setAttribute('name',fatherId);
        externalElement.setAttribute('style',`margin-bottom: 20px`);
        externalElement.setAttribute('class', 'row');
        let wrapElement = document.createElement("div");
        wrapElement.setAttribute('class', 'col-md-11');
        let fatherElement = document.createElement("div");
        elements.forEach(element => {
            if (field === 'preview') {
                fatherElement.setAttribute('style', `height: ${rowHeight}px`);
                fatherElement.id = fatherId;
                fatherElement.setAttribute('class', 'row inert');
                let divElement = document.createElement(element['preview'].fatherTag);
                divElement.setAttribute('class', element['preview'].fatherClass);
                divElement.setAttribute('style', element['preview'].fatherHeight);
                let widgetPlaceholderElement = document.createElement(element['preview'].childTag);
                widgetPlaceholderElement.setAttribute('id', element['preview'].childId);
                widgetPlaceholderElement.setAttribute('class', element['preview'].childClass);
                widgetPlaceholderElement.setAttribute('style', element['preview'].fatherHeight);
                divElement.appendChild(widgetPlaceholderElement);
                fatherElement.appendChild(divElement);
                wrapElement.appendChild(fatherElement)
            }
            else {
                fatherElement.id = fatherId;
                fatherElement.setAttribute('class', 'row');
                let divElement = document.createElement(element['content'].fatherTag);
                divElement.setAttribute('class', element['content'].fatherClass);
                let widgetPlaceholderElement = document.createElement(element['content'].childTag);
                widgetPlaceholderElement.setAttribute('id', element['content'].childId);
                divElement.appendChild(widgetPlaceholderElement);
                fatherElement.appendChild(divElement);
            }
        }
        );
        if (field === 'preview') {
            let buttonElement = document.createElement('button');
            buttonElement.setAttribute('type', 'button');
            buttonElement.setAttribute('class', 'btn btn-danger col-md-1');
            buttonElement.setAttribute('float', 'right');
            buttonElement.appendChild(document.createTextNode("Elimina"));

            externalElement.appendChild(wrapElement);
            externalElement.appendChild(buttonElement);
            return externalElement;
        }
        else
            return fatherElement;
    }
}