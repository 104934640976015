import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import 'rxjs/add/operator/toPromise';
import { UserService } from './user.service';

/**
 * The document tree  service
 *
 * @version 1.0.0
 * @export
 * @class DocumentTreeService
 */
@Injectable()
export class DocumentTreeService {

    /**
     * Creates an instance of DocumentTreeService.
     * 
     * @param {HttpClient} http
     * @param {UserService} userService
     * 
     * @memberOf DocumentTreeService
     */
    constructor(private http: HttpClient, private userService: UserService) {
        //Do nothing
    }

    /**
     * 
     * 
     * @returns {Promise<Array<string>>}
     * 
     * @memberOf DocumentTreeService
     */
   /* public getViews(): Promise<Array<string>> {
        return this.http
            .get(`/myintranet/${this.userService.ipa}/documenttree/views`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<string>> response).entities);
    }*/

    /**
     * Get tree nodes by level
     * 
     * @memberOf DocumentTreeService
     */
    /*public getNodes(view: string, level: number = 1): Promise<Array<Model.TreeNode>> {
        let parameters: HttpParams = new HttpParams();

        parameters.set('tree', view);
        parameters.set('level', level ? level.toString() : '1');

        return this.http
            .get(`/myintranet/${this.userService.ipa}/documenttree/nodes`, {
                params: parameters
            })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.TreeNode>>).entities);
    }*/

    /**
     * Search tree nodes by partial path
     * 
     * @memberOf DocumentTreeService
     */
    /*public searchNodes(view: string, path: string): Promise<Array<Model.TreeNode>> {
        let parameters: HttpParams = new HttpParams();

        parameters.set('tree', view);
        parameters.set('path', path);

        return this.http
            .get(`/myintranet/${this.userService.ipa}/documenttree/nodes/search`, {
                params: parameters
            })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.TreeNode>> response).entities);
    }*/

    /**
     * Get tree node children by id
     * 
     * @memberOf DocumentTreeService
     */
    /*public getChildren(view: string, id: string): Promise<Array<Model.TreeNode>> {
        let parameters: HttpParams = new HttpParams();

        parameters.set('tree', view);
        parameters.set('id', id);

        return this.http
            .get(`/myintranet/${this.userService.ipa}/documenttree/children`, {
                params: parameters
            })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.TreeNode>> response).entities);
    }*/

    /**
     * Return the content download url
     * 
     * @memberOf DocumentTreeService
     */
    public getDocumentDownloadUrl(view: string, path: string, contentId: string): string {
        return `/myintranet/${this.userService.ipa}/documenttree/download?tree=${view}&path=${path}&contentId=${contentId}`
    }

}