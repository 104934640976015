import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import { LocalSessionService } from './local-session.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private currentAclSubject: Subject<ModelEventiCalamitosi.AclDto>;
  public currentAclObservable: Observable<ModelEventiCalamitosi.AclDto>;
  public  isPublic:BehaviorSubject<any>;
  
  private currentMyIntranetUrl: Subject<string>;
  public currentMyIntranetUrlAsObservable: Observable<string>;

  private currentUserAcl: BehaviorSubject<any>;
  public currentAcl: Observable<any>;

  public acl: string = '';

  constructor(private http: HttpClient) {
    this.isPublic = new BehaviorSubject<any>(false);
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserAcl = new BehaviorSubject<any>(null);
    this.currentAcl = this.currentUserAcl.asObservable();

    this.currentAclSubject = new Subject<ModelEventiCalamitosi.AclDto>();
    this.currentAclObservable = this.currentAclSubject.asObservable();

    this.currentMyIntranetUrl = new Subject<string>();
    this.currentMyIntranetUrlAsObservable = this.currentMyIntranetUrl.asObservable();
  }


  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(acl : ModelEventiCalamitosi.AclDto) {
    this.currentAclSubject.next(acl);
    this.currentUserAcl.next(acl.acl);
    this.currentUserSubject.next(acl.ente);
    sessionStorage.setItem("acl", acl.acl);
    sessionStorage.setItem(LocalSessionService.USER_KEY, JSON.stringify(acl));
  }

  public getAcl() {
    return this.http
      .get<ModelEventiCalamitosi.AclDto>(`${environment.urls.base}/utente/acl-utente`)
      .pipe(tap(acl => {
        this.currentAclSubject.next(acl);
      }));
  }

  public logout() {
    return this.http
      .get<any>(`${environment.urls.base}/utente/logout`)
      .pipe(map(ret => {
        this.currentUserSubject.next(null);
        this.currentUserAcl.next(null);
        this.currentAclSubject.next(null);
        sessionStorage.setItem("acl", "");
        sessionStorage.setItem(LocalSessionService.USER_KEY, "");
        return true;
      }));
  }

  public haveAcl(acl: string) {
    let arrayAcl = sessionStorage.getItem('acl').split(",");
    if (arrayAcl.findIndex(el => el == acl) >= 0) {
      return true
    } else {
      return false;
    }
  }

  public emitMyIntranetUrl(value:string){
    this.currentMyIntranetUrl.next(value);
  }
}

