import { FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class MyErrorMatcher implements ErrorStateMatcher
{
    public enableValidation: boolean = true;

    constructor(enableValidation?: boolean)
    {
        this.enableValidation = enableValidation ? enableValidation : true;
    }

    isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean
    {
        return this.enableValidation && control && control.invalid;
    }
}