import { DialogService } from 'src/app/services/dialog.service';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { EnteDelgatoService } from 'src/app/services/autorizzazionePaesaggistica/enteDelegato.service';
import { PraticaService } from 'src/app/services/autorizzazionePaesaggistica/pratica.service';
import { GenericDialogContentComponent } from '../generic-dialog-content/generic-dialog-content.component';
import { LocalSessionService } from '../../core/services/local-session.service';
import { CustomValidator } from 'src/app/shared/validators/custom-validator.service';

@Component({
  selector: 'app-nuova-pratica',
  templateUrl: './nuova-pratica.component.html',
  styleUrls: ['./nuova-pratica.component.css'],
})
export class NuovaPraticaComponent implements OnInit {
  /*Form: Inserisci Nuova Pratica */
  public form: FormGroup;
  disabledEnte: boolean = true;
  entiDelegati: AutoCompleteDto[] = [];
  enteSelected: string;
  pratica: ModelAutorizzazionePaesaggistica.NewPraticaDto;
  acl: string;
  errors: ValidationErrors = { required: true };

  enable: boolean = false;
  submitted: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private enteDelegatoSvc: EnteDelgatoService,
    private praticaSvc: PraticaService,
    private aclSvc: AuthenticationService,
    private alertsCenterService: AlertsCenterService,
    private dialogSvc: DialogService,
    private customValidator: CustomValidator
  ) {}
  ngOnInit(): void {
    this.acl = sessionStorage.getItem(LocalSessionService.ACL_KEY);
    this.initForm();

    this.enteDelegatoSvc.getEntiDelegati().subscribe((response) => {
      this.entiDelegati = response;

      if (this.acl == 'ed') {
        this.enteSelected = response[0].value;
        this.form.patchValue({
          enteDelegato: this.enteSelected,
        });
      }
    });
  }

  initForm() {
    this.disabledEnte = this.acl == 'ed';
    this.form = new FormGroup({
      enteDelegato: new FormControl(
        { value: null, disabled: this.disabledEnte },
        [Validators.required]
      ),
      oggetto: new FormControl(null, [Validators.required]),
    });


    this.form
      .get('oggetto')
        .setValidators([
          this.customValidator.noWhitespaceValidator(this.form.get('oggetto')),
        ]);
    // this.form.get('oggetto').updateValueAndValidity();
  }

  confirm() {
    this.submitted = true;
    if (this.form.valid) {
      this.dialog
        .open(GenericDialogContentComponent, {
          data: {
            title: 'Conferma Operazione',
            message: 'Desidera creare una nuova autorizzazione paesaggistica?',
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            //L'utente ha cliccato su CONFERMA: creo quindi una nuova pratica
            this.creaNuovaPratica();
            //this.onNavigate('dettaglio-pratica');
          } else {
            //L'utente ha cliccato su ANNULLA: annullo l'operazione di creazione
          }
        });
    } else {
      this.alertsCenterService.showAlert({
        message: 'CAMPI NON CORRETTI',
        type: 'danger',
        autoClosable: false,
      });
      //   this.dialog
      //   .open(GenericDialogContentComponent, {
      //     data: {
      //       title: "Errore",
      //       message: "Campi non compilati correttamente"
      //     },
      //   })
      //   .afterClosed()
      //   .subscribe();
    }
  }

  creaNuovaPratica() {
    let value = this.form.value;

    //Prendo il valore di Default
    let valEnteDelegato = this.form.controls.enteDelegato.value;
    // let valEnteDelegato = this.enteSelected

    //Controllo se l'utente ha selezionato un ente delegato differente
    if (value.enteDelegato != undefined || value.enteDelegato != null)
      valEnteDelegato = value.enteDelegato;

    //Chiamata al BE: --> Generazione id Pratica
    this.pratica = {
      idEnteDelegato: valEnteDelegato,
      oggetto: value.oggetto,
    };
    console.log(this.pratica);

    //this.onNavigate('modifica-pratica',null,1);
    this.praticaSvc.createPratica(this.pratica).subscribe(
      (idPratica) => {
        console.log(idPratica);
        this.onNavigate('modifica-pratica', null, idPratica);
      },

      (error) => {
        let messaggio=error.error;
          if(!messaggio || (typeof messaggio)!="string"){
            messaggio="Errore nella creazione della pratica!";
          }
        this.dialogSvc
          // Dialog che si aprirà solo nel caso in cui si sfori la lunghezza massima di un campo
          .openDialog(
            'NUOVA AUTORIZZAZIONE NON ESEGUITA:',
            messaggio,
            '800px',
            null,
            true,
            {
              labelConferma: 'Chiudi',
              cssClass: 'confirmNoMaxWidth',
            }
          )
          .subscribe();
      }
    );
  }

  onNavigate(path: string, denominazione?: string, idPratica?: number) {
    switch (path) {
      case 'template-list': {
        this.router.navigate([this.router.url + '/' + path], {
          state: { denominazione: denominazione },
        });
        break;
      }
      case 'modifica-pratica': {
        this.router.navigate([path + '/' + idPratica]);
        break;
      }
      case 'dettaglio-pratica': {
        this.router.navigate([path + '/' + idPratica]);
        break;
      }
      default: {
        this.router.navigate([path]);
        break;
      }
    }
  }

  public askConfermaIndietro(){
    if(this.form.touched){
      this.dialogSvc
      .openDialog('ATTENZIONE', "Le modifiche non risultano salvate, abbandonare?")
      .subscribe((result) => {
        if (result) {
          this.onNavigate('lista-pratiche');
        }
      });
    }else{
      this.onNavigate('lista-pratiche');
    }
  }
}
