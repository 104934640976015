<ng-container *ngIf="isPublic; else standard">
  <div>
    <header #header id="public-header" class="0.0.1">
      <div class="row">
        <div class="col-md-12 ttw public-header-top">
          <div class="mx-3 my-2">
            <a style="color: white;" href="https://www.regione.veneto.it/">Regione del Veneto</a>
          </div>
        </div>
        <div class="col-md-12">
          <div class="public-header-menu header-left flex-display">
            <a class="header-left-margin" aria-label="apri/chiudi menu" href="javascript:void(0)" title="apri/chiudi menu"
              (click)="toggleMenu()">
              <mat-icon id="menu">menu</mat-icon>
            </a>
            <div class="header-left-margin">
              <h6 class="ttw">Autorizzazioni paesaggistiche</h6>
            </div>
          </div>
        </div>
        <div class="col-md-12 mx-4">
          <a class="header-button" mat-button [ngClass]="router.url == '/public/lista-pratiche-public'? 'active-button' : ''" routerLink="public/lista-pratiche-public">Lista Pratiche</a>
          <a class="header-button" mat-button [ngClass]="router.url == '/public/lista-enti'? 'active-button' : ''" routerLink="public/lista-enti">Lista Enti</a>
        </div>
      </div>
    </header>
    <div class="flex-display-public-content">
      <aside id="sideM">
        <myp-side-menu-public *ngIf="menu" [menu]="openCloseMenu.asObservable()"></myp-side-menu-public>
      </aside>
      <div id="public-content">
        <div *ngIf="loadService.isLoading | async" class="spinner-style">
          <div class="spinner"></div>
        </div>
        <myp-alerts-center></myp-alerts-center>
        <router-outlet (activate)="onRouteActivated()"></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="scrollButton" class="scrollTop">
    <button mat-icon-button (click)="scrollToTop()">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  </div>
  <footer>
    <myp-footer [footerLogo]="menu"></myp-footer>
  </footer>
</ng-container>
<ng-template #standard>
  <header id="header" class="0.0.1">
    <div class="header-left flex-display">
      <a class="header-left-margin" title="Naviga verso il portale di Regione Veneto"
        href="https://www.regione.veneto.it/">
        <img class="myIntrlogo" style="width: 80px; padding-top: 0.2em" src="assets/images/dir_pian_ter.png" /></a>
      <a class="header-left-margin" aria-label="apri/chiudi menu" href="javascript:void(0)" title="apri/chiudi menu"
        (click)="toggleMenu()">
        <mat-icon id="menu">menu</mat-icon>
      </a>
    </div>

    <div class="header-right flex-display">
      <div class="flex-display mR">
        <h6 class="ttw">{{ nomeEnte ? nomeEnte : "Regione del Veneto" }}</h6>
        <mat-form-field appearance="fill" color="accent" class="ente">
          <mat-label>
            <mat-icon id="person">person_outline</mat-icon>
          </mat-label>
          <mat-select (valueChange)="setLabelOnChangeValue($event)" [formControl]="selected" style="width: 400px">
            <mat-option value="1">
              <mat-icon style="font-size: large">account_circle</mat-icon>
              {{ utenteLoggato }}
            </mat-option>
            <mat-option value="3" *ngIf="isUtenteLoggato && mostraCambioRuolo">
              <mat-icon style="font-size: large">swap_horiz</mat-icon> Cambia
              ruolo/ente
            </mat-option>
            <mat-option value="2" *ngIf="isUtenteLoggato">
              <b style="color: #ff4081; font-size: small">
                <mat-icon style="font-size: large">power_settings_new</mat-icon>
                LOGOUT
              </b>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </header>

  <div id="container">
    <aside id="sideM">
      <myp-side-menu *ngIf="menu" [menu]="openCloseMenu.asObservable()"></myp-side-menu>
    </aside>
    <main id="content" [ngClass]="{ adapter: menu }">
      <div *ngIf="loadService.isLoading | async" class="spinner-style">
        <div class="spinner"></div>
      </div>
      <myp-alerts-center></myp-alerts-center>
      <router-outlet (activate)="onRouteActivated()"></router-outlet>
    </main>
  </div>

  <footer>
    <myp-footer [footerLogo]="menu"></myp-footer>
  </footer>
</ng-template>
