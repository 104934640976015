/**
 * Core module.
 * @export
 * @class CoreModule
 */
import { NgModule, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpModule, CookieXSRFStrategy, HttpClient, XHRBackend, RequestOptions } from '@angular/common/http';
// import { InterceptorService } from 'ng2-interceptors';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie';

// Services
import { DragAreaStatusService } from './services/drag-area-status.service';
// import { ContentService } from './services/content.service';
import { UserService } from './services/user.service';
import { DashboardService } from './services/dashboard.service';
import { PageService } from './services/page.service';
import { TranslationService } from './services/translation.service';
import { ClientService } from './services/client.service';
// import { ProcedureService } from './services/procedure.service';
// import { InstanceFormService } from './services/instanceform.service';
// import { ProcedureCatalogService } from './services/procedurecatalog.service';
import { TemplateService } from './services/template.service';
import { SearchService } from './services/search.service';
import { DocumentTreeService } from './services/document-tree.service';
import { ConfigurationService } from './services/configuration.service';
import { GlobalState } from './services/global-state.service';
import { DomService } from './services/dom.service';
import { ErrorLoggerInterceptor } from './interceptors/error-logger.interceptor';
import { AuthenticationErrorInterceptor } from './interceptors/authentication-error.interceptor';
import { AlertsCenterService } from './services/alerts-center.service';
// import { ReportTemplateService } from './services/report-template.service';
// import { MyProfileService } from './services/my-profile.service';
// import { MyChannelService } from './services/my-channel.service';

// Authguards
import { AuthGuard } from './services/auth-guard.service';

@NgModule({
  imports: [
    CommonModule
    // HttpModule
  ],
  providers: [
    DragAreaStatusService,
    // ContentService,
    DashboardService,
    PageService,
    TranslationService,
    ClientService,
    // ProcedureService,
    // InstanceFormService,
    // ProcedureCatalogService,
    TemplateService,
    // MyChannelService,
    SearchService,
    DocumentTreeService,
    ConfigurationService,
    // MyProfileService,
    GlobalState,
    DomService,
    ErrorLoggerInterceptor,
    /*AuthenticationErrorInterceptor,
    {
      provide: HttpClient,
      useFactory: interceptorFactory,
      deps: [XHRBackend, RequestOptions, ErrorLoggerInterceptor, AuthenticationErrorInterceptor]
    },*/
    UserService,
    DatePipe,
    AlertsCenterService,
    /*ReportTemplateService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInizializerFactory,
      deps: [UserService],
      multi: true
    },*/
    AuthGuard
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}


/*
export function cookieStrategy() {
  return new CookieXSRFStrategy('csrf-token', 'x-csrf-token');
} */


/*export function interceptorFactory(xhrBackend: XHRBackend, equestOptions: RequestOptions,
  errorLoggerInterceptor: ErrorLoggerInterceptor, authenticationErrorInterceptor: AuthenticationErrorInterceptor) {
  let service = new InterceptorService(xhrBackend, requestOptions);
  service.addInterceptor(errorLoggerInterceptor);
  service.addInterceptor(authenticationErrorInterceptor);
  return service;
} */


export function appInizializerFactory(userService: UserService) {
  return () =>  userService.loadProfile();
}
