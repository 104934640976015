/**
 * Drag Area Status Service
 * 
 * @author Nicola Prenna
 * @version 1.0.0
 * @since 12th September 2017
 * @export 
 * @class DragAreaStatusService
 */
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
 
@Injectable()
export class DragAreaStatusService {

    /**
     * 
     * 
     * @private
     * @type {EventEmitter<string>}
     * @memberof DragAreaStatusService
     */
    private onContentCreatedEmitter: EventEmitter<string> = new EventEmitter<string>();
    
    /**
     * 
     * 
     * @private
     * @type {string}
     * @memberof DragAreaStatusService
     */
    private currentPath: string;

    /**
     * 
     * 
     * @private
     * @type {boolean}
     * @memberof DragAreaStatusService
     */
    private isDragAndDropEnabled: boolean;
    
    /**
     * 
     * 
     * @memberof DragAreaStatusService
     */
    public notifyContentCreatedEvent(): void {
        this.onContentCreatedEmitter.emit(this.currentPath);
    }

    /**
     * 
     * 
     * @readonly
     * @type {EventEmitter<string>}
     * @memberof DragAreaStatusService
     */
    public get onContentCreated(): EventEmitter<string> {
        return this.onContentCreatedEmitter;
    }

    /**
     * 
     * 
     * @memberof DragAreaStatusService
     */
    public set path(value: string) {
        this.currentPath = value;
    }

    /**
     * 
     * 
     * @readonly
     * @type {string}
     * @memberof DragAreaStatusService
     */
    public get path(): string {
        return this.currentPath;
    }

    /**
     * 
     * 
     * @memberof DragAreaStatusService
     */
    public set isEnabled(value: boolean)  {
        this.isDragAndDropEnabled = value;
    }

    /**
     * 
     * 
     * @readonly
     * @type {boolean}
     * @memberof DragAreaStatusService
     */
    public get isEnabled(): boolean {
        return this.isDragAndDropEnabled;
    }

}