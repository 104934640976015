import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoInterventoService {

  private BASE_URL = environment.urls.base + "/TipoIntervento";

  constructor(private http: HttpClient) { }

  /**
  * Chiamata per recuperare la lista dei Tipi Interventi in base al Tipo Procedura
  * @param tipoProcedura
  * @author Marta Zecchillo
  * @returns TipoInterventoDto[]
  */
   getTipiIntervento(tipoProcedura: string){

  let params = new HttpParams;
  params = params.append("tipoProcedura", tipoProcedura);

   return this.http.get<ModelAutorizzazionePaesaggistica.TipoInterventoDto[]>
           (this.BASE_URL+`/selectTipoIntervento`, { params: params });
 }

 /**
  * 
  * @returns 
  */
 getAllTipiIntervento(){
   return this.http.get<ModelAutorizzazionePaesaggistica.TipoInterventoDto[]>
           (this.BASE_URL+`/selectTipoIntervento`);
 }

}
