/**
 * Footer widget component.
 * @class FooterComponent
 */
import { Component, Input, ViewEncapsulation, HostListener, OnInit, AfterViewInit, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { LocalSessionService } from 'src/app/core/services/local-session.service';

@Component({
    selector: 'myp-footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, AfterViewInit{
  @Input('footerLogo') footerLogo:boolean;
  public:boolean;
  public urlMyintranetOrigin:string="";

  constructor(
    private authenticationService:AuthenticationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdRef:ChangeDetectorRef
   ){
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/twitter.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/images/youtube.svg'
      )
    );
   }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.authenticationService.isPublic.subscribe(
        (boolEmitted) => (this.public = boolEmitted)
      );
      this.cdRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.authenticationService.currentMyIntranetUrlAsObservable.subscribe(value=>{
      let urlMyintranet=new URL(value);
      this.urlMyintranetOrigin=urlMyintranet.origin;
      console.log("settato {}",this.urlMyintranetOrigin);
    });
  }

 }
