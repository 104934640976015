import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { Observable, of, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private alertsCenterService: AlertsCenterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /*if (sessionStorage.getItem('jwtToken')) {
      request = request.clone({
        setHeaders: {
          'x-auth-token': sessionStorage.getItem('jwtToken')
        }
      });
    }*/

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          console.log('Redirecting to saml login...');
          window.location.replace(`${environment.urls.samlLogin}`);
          return of(null);
        } else if (error.status === 0 || error.status >= 500) {
          this.alertsCenterService.showAlert({
            message: `Errore Generico di Comunicazione`,
            type: 'danger',
            autoClosable: false,
          });
          console.log('Errore di comunicazione {}', error);
          return throwError(error);
        }

        return throwError(error);
      })
    );
  }
}
