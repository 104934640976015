export class InputErrorConf
{
    private static errorMessages: any =
    {
        required: "errors.required",
        email: "errors.email",
        minlength: "errors.minlength",
        maxlength: "errors.maxlength",
        textRequired: "errors.required",
        valueRequired: "errors.required",
        particellaRequired: "errors.particellaRequired",
        pattern: "errors.patternErrato",
        stradeRequired: "errors.stradeRequired",
        indirizzoRequired: "errors.indirizzoRequired",
        numCivicoRequired: "errors.numCivicoRequired",
        requiredAllOrNone: "errors.requiredAllOrNone",
        dateError: "errors.dateError",
        disableDateFuture: "errors.disableDateFuture",
        notRequired: "errors.notRequired"
    };

    public static get defaultErrorMessages(): any { return this.errorMessages; }
}
