import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { FileUploadModel } from './file-upload-model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent
{
  @Input("file") set _file(f: FileUploadModel)
  {
    this.file = f;
  }
  @Input("accept") accept: string = null;
  @Input("disabled") disabled: boolean = false;

  @Output("select")   selectEvt$: EventEmitter<File> = new EventEmitter();
  @Output("download") downloadEvt$: EventEmitter<FileUploadModel> = new EventEmitter();
  @Output("delete") deleteEvt$: EventEmitter<FileUploadModel> = new EventEmitter();
  @Input("maxSize") maxSize: number = null; // in byte
  
  public file: FileUploadModel = null;

  constructor(private dialogSvc: DialogService) { }

  public selectFile(event: any): void
  {
    const file: File = event.target.files[0];

    if(this.maxSize!=null && file && file.size && file.size>this.maxSize){
      event.target.value=""; //pulisco il file
      this.dialogSvc
      // Dialog che si aprirà nel caso in cui il file supera le dimensioni consentite
      .openDialog(
        'FILE NON VALIDO:',
        'La dimensione massima ammessa per i file è ' + (this.maxSize/(1024*1024)) + 'MB' + ' il file selezionato è di ' + (file.size/(1024*1024)).toFixed(2)+ 'MB' ,
        '800px',
        null,
        true,
        {
          labelConferma: 'Chiudi',
          cssClass: 'confirmNoMaxWidth',
        }
      )
      .subscribe();
    }
    else{
      this.file = {fileName: file.name};
      this.selectEvt$.emit(file)
    }
  }

  public downloadFile(): void
  {
    this.downloadEvt$.emit(this.file);
  }

  public deleteFile(): void
  {
    this.dialogSvc.openDialog(
      "CONFERMA OPERAZIONE",
      "Confermi la rimozione dell'allegato? "
    )
      .subscribe(
        result => {
          if (result) {
            let f = { ...this.file };
            this.file = null;
            this.deleteEvt$.emit(f);
          }
        });
  }

}
