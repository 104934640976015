/**
 * Allow array grouping in ngFor directives.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 3rd January 2017
 * @export 
 * @class GroupArrayPipe
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'grouped' })
export class GroupArrayPipe implements PipeTransform {

    /**
     * Pipe transform
     * 
     * @param {*} value
     * @param {any[]} [args]
     * @returns {any[]}
     * 
     * @memberOf GroupArrayPipe
     */
    public transform(value: any, args?: any): any[] {
        let groups: Array<Array<any>> = new Array<Array<any>>();
        let groupsSize: number = <number>args;
        let groupIndex: number = 0;

        if (value)
            (<Array<any>>value).forEach(item => {
                if ((groups.length - 1) < groupIndex)
                    groups.push(new Array<any>());

                groups[groupIndex].push(item);

                if (groups[groupIndex].length >= groupsSize)
                    groupIndex++;
            });

        return groups;
    }

}