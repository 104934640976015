export interface Error {
    code : string,
    message: string,
    it: string,
    en: string
}

export const errors:Array<Error> = [{
        "code": "0001",
        "message": "Page not found",
        "it": "Pagina non trovata", 
        "en": "Page not found"
    },{
        "code": "0002",
        "message": "Parent not found",
        "it": "Genitore non trovato",
        "en": "Parent not found"
    },{
        "code": "0003",
        "message": "Content type 'cm_folder' cannot have an associated binary",
        "it": "Contenuto di tipo 'cm_folder' non può avere un binario associato",
        "en": "Content type 'cm_folder' cannot have an associated binary"
    },{
        "code": "0004",
        "message": "Content not found",
        "it": "Contenuto non trovato",
        "en": "Content not found"
    },{
        "code": "0005",
        "message": "Folder is not empty and cannot be deleted",
        "it": "Cartella non è vuota e non può essere cancellata",
        "en": "Folder is not empty and cannot be deleted"
    },{
        "code": "0006",
        "message": "Content has no attached file",
        "it": "Il contenuto non ha alcun file allegato",
        "en": "Content has no attached file" 
    },{
        "code": "0007",
        "message": "No content to export",
        "it": "Nessun contenuto da esportare",
        "en": "No content to export"
    },{
        "code": "0008",
        "message": "Content thumbnail not found",
        "it": "Miniatura del contenuto non trovata",
        "en": "Content thumbnail not found"
    },{
        "code": "0009",
        "message": "Template not found",
        "it": "Template non trovato",
        "en": "Template not found" 
    },{
        "code": "0010",
        "message": "Path is already taken",
        "it": "Percorso già presente", 
        "en": "Path is already taken"
    },{
        "code": "0011",
        "message": "Alias must be different from path",
        "it": "Alias deve essere differente dal percorso",
        "en": "Alias must be different from path" 
    },{
        "code": "0012",
        "message": "Alias is already taken",
        "it": "Alias già presente",
        "en": "Alias already taken"  
    },{
        "code": "0013",
        "message": "Another page is already set as homepage",
        "it": "Un'altra pagina è già impostata come homepage",
        "en": "Another page is already set as homepage" 
    },{
        "code": "0014",
        "message": "Another page is already set as landing page",
        "it": "Un'altra pagina è già impostata come pagina di destinazione",
        "en": "Another page is already set as landing page"  
    },{
        "code": "0015",
        "message": "Name is already taken",
        "it": "Nome già presente",
        "en": "Name is already taken"
    },{
        "code": "0016",
        "message": "Template is used by some pages",
        "it": "Template usato da alcune pagine",
        "en": "Template is used by some pages" 
    },{
        "code": "0017",
        "message": "Error during compile stylesheet",
        "it": "Errore durante la compilazione del foglio di stile",
        "en": "Error during compile stylesheet"
    },{
        "code": "0018",
        "message": "Layout information not exists",
        "it": "Informazioni del layout non esiste",
        "en": "Layout information not exists"
    },{
        "code": "0019",
        "message": "InstanceForm not found",
        "it": "Form di istanza non trovata",
        "en": "InstanceForm not found"
    },{
        "code": "0020",
        "message": "Workflow not found",
        "it": "Workflow non trovato",
        "en": "Workflow not found"
    },{
        "code": "0021",
        "message": "Procedure not found",
        "it": "Procedura non trovata",
        "en": "Procedure not found"
    },{
        "code": "0022",
        "message": "Procedure already published. You must unpublished before update",
        "it": "Procedura già pubblicata. È necessario non pubblicare prima dell'aggiornamento",
        "en": "Procedure already published. You must unpublished before update"
    },{
        "code": "0023",
        "message": "Exists a procedure with same type already published.",
        "it": "Esiste una procedura con lo stesso tipo già pubblicato",
        "en": "Exists a procedure with same type already published."
    },{
        "code": "0024",
        "message": "Worflow not deployed",
        "it": "Worflow non 'deploiato'",
        "en": "Worflow not deployed"
    },{
        "code": "0025",
        "message": "ProcedureType not found",
        "it": "Tipo procedura non trovato",
        "en": "ProcedureType not found"
    },{
        "code": "0026",
        "message": "Service not found",
        "it": "Servizio non trovato",
        "en": "Service not found"
    },{
        "code": "0026",
        "message": "Payload content length greater than maximum allowed: 100000000",
        "it": "Dimensione del contenuto del payload maggiore del massimo consentito: 100000000",
        "en": "Payload content length greater than maximum allowed: 100000000" 
    },{
        "code": "0027",
        "message": "Attachment size excedes the maximum allowed dimension: 100Mb",
        "it": "Dimensione dell'allegato eccede la massima dimensione consentita: 100Mb",
        "en": "Attachment size excedes the maximum allowed dimension: 100Mb"
    },{
        "code": "0028",
        "message": "An error occurred while executing the request",
        "it": "Si è verificato un errore durante l'esecuzione della richiesta",
        "en": "An error occurred while executing the request"
    },{
        "code": "0029",
        "message": "Type not available for this path",
        "it": "Il tipo di contenuto non è disponibile per questa cartella",
        "en": "Type not available for this path"
    }, {
        "code": "0030",
        "message": "InstanceForm not found.",
        "it": "Scheda instanza non trovata o mancante.",
        "en": "Instance form not found."
    },{
        "code": "0031",
        "message": "Not authorized for this operation",
        "it": "Utente non autorizzato a eseguire questa operazione.",
        "en": "Not authorized for this operation"
    },{
        "code": "0032",
        "message": "Act with given act number already exists",
        "it": "Un atto con il medesimo Numero atto esiste già.",
        "en": "Act with given act number already exists"
    },{
        "code": "0033",
        "message": "Procedure already published. You must unpublished before update.",
        "it": "Procedura già pubblicata, è necessario rimuoverla dalla pubblicazione prima di aggiornarla",
        "en": "Procedure already published. You must unpublished before update."
    },{
        "code": "0034",
        "message": "Name already in use",
        "it": "Nome già in uso",
        "en": "Name already in use"
    }, {
        "code": "0035",
        "message": "You don't have permissions to perform this action or access this resource",
        "it": "Non sei autorizzato ad eseguire questa azione o ad accedere a questa risorsa",
        "en": "You don't have permissions to perform this action or access this resource"
    }, {
        "code": "0036",
        "message": "Another document with the same name exists in this path",
        "it": "Un altro documento con lo stesso nome esiste in questo percorso",
        "en": "Another document with the same name exists in this path"
    }, {
        "code": "0037",
        "message": "One of the attachments is empty or invalid",
        "it": "Uno degli allegati è vuoto o non valido",
        "en": "One of the attachments is empty or invalid"
    }, {
        "code": "0038",
        "message": "A content with this id could\'t be retrived",
        "it": "Non è stato possibile trovare un contenuto con questo id",
        "en": "A content with this id could\'t be retrived"
    }, {
        "code": "0039",
        "message": "Content associated {name} has been moved to the trash.",
        "it": "Contenuto associato {name} è stato spostato nel cestino",
        "en": "Content associated {name} has been moved to the trash."
    }, {
        "code": "0040",
        "message": "Content associated {name} is not published.",
        "it": "Contenuto associato {name} non è pubblicato",
        "en": "Content associated {name} is not published."
    }, {
        "code": "0041",
        "message": "Another document with the same slug exists",
        "it": "Esiste già un altro documento con lo stesso slug",
        "en": "Another document with the same slug exists"
    }, {
        "code": "0042",
        "message": "Another instance-form with the same slug exists",
        "it": "Esiste già un'altra Scheda Istanza con lo stesso slug",
        "en": "Another instance-form with the same slug exists"
    }, {
        "code": "0043",
        "message": "Something went wrong",
        "it": "Qualcosa è andato storto",
        "en": "Something went wrong"
    }
]