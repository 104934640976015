import { Moment } from 'moment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';

export function toHttpParams(object: any): HttpParams
{
    let params: HttpParams = new HttpParams();
    Object.keys(object).forEach(key =>
    {
        if (typeof (object[key]) == 'boolean')
            params = params.append(key, object[key] ? 'true' : 'false');
        else if (object[key])
        {
            if (object[key] instanceof Array)
            {
                object[key].forEach((o: any) =>
                {
                    params = params.append(key, o.toString());
                });
            }
            else if (object[key] instanceof Date)
                params = params.append(key, object[key].toDateString());
            else if (object[key] instanceof moment)
            {
                let d = new Date((object[key] as Moment).toDate())
                params = params.append(key, d.toDateString());

            }
            else
                params = params.append(key, object[key].toString());
        }

    });
    return params;
}


export function downloadFile(blob: Blob, fileName: string,headers?:HttpHeaders): void
{
    //retrieval del nome dall'header...
    let filename = fileName;
    if (headers && headers.get('Content-Disposition')) {
        let contentDisposition = headers.get('Content-Disposition');
        let parts = contentDisposition.split(';');
        if (contentDisposition && parts.length > 1) {
            let partF = parts[1].split('filename');
            if (partF && partF.length > 1) {
                let partEqual = partF[1].split('=');
                if (partEqual && partEqual.length > 1)
                    filename = partEqual[1].trim();
            }
        }
    }
    //saveAs(blob, filename);
    /*if (window.navigator && window.navigator.msSaveOrOpenBlob)
    {
        saveAs(blob, filename);
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
    else
    {*/
        var link = document.createElement("a");
        if (link.download !== undefined)
        {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    //}
}

export function statiOptions(){
    return [
        { label: 'Bozza', value: 'BOZZA' },
        { label: 'Inoltrata', value: 'INOLTRATA' },
        { label: 'Inviata al protocollo', value: 'INVIATA_AL_PROTOCOLLO' },
        { label: 'Errato protocollo', value: 'ERRATO_PROTOCOLLO' },
        { label: 'Protocollata', value: 'PROTOCOLLATA' },
        { label: 'Richiesta di annullamento', value: 'RICHIESTA_DI_CANCELLAZIONE' },
        { label: 'Annullata', value: 'CANCELLATA' },
      ];
}

