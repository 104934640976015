import { Component, EventEmitter, forwardRef, Injector, Input, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Moment } from 'moment';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-date-field',
    template: `
        <mat-form-field class="mat-input-wrapper" color="accent" appearance="fill">
            <mat-label class="big">{{label}}</mat-label>
            <input matInput [errorStateMatcher]="myErrorMatcher" [matDatepicker]="picker" [formControl]="control" (dateChange)="changeBind($event.value)"
                autocomplete="off" [disabled]="disabled" [min]="mindate" [max]="maxdate" [matDatepickerFilter]="dateFilter"
                (dateInput)="changeBind($event.value)">
            <mat-datepicker-toggle [disabled]="disabled" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="errors && enable">
                <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
            </mat-error>
        </mat-form-field>
    `,
    styles: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerField),
            multi: true
        }
    ]
})
export class DatePickerField extends BaseControlValueAccessor<Moment>
{
    @Input("mindate") mindate: Date;
    @Input("maxdate") maxdate: Date;

    @Input("matDatepickerFilter") dateFilter: (d: Date | null) => boolean;

    @Output("change") changeEvt$: EventEmitter<Moment>;

    public constructor(protected inj: Injector)
    {
        super(inj);
        this.changeEvt$ = new EventEmitter();
    }
    
    public changeBind(value: Moment): void
    {
        this.changeEvt$.emit(value);
    }
}