/**
 * Page service.
 * 
 * @author Fabio Gomiero
 * @version 1.0.0
 * @since 6th December 2016
 * @export 
 * @class PageService
 */
/// <references path="./../../model/Model" />

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserService } from './user.service';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class PageService {

    /**
     * Creates an instance of PageService.
     * 
     * @param {HttpClient} http
     * 
     * @memberOf PageService
     */
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService
    ) {
        // Do nothing
    }

    /**
     * Retrieve all pages
     * 
     * @returns {Promise<Array<Model.Page>>}
     * 
     * @memberOf PageService
     */
    public getPages(): Promise<Array<Model.Page>> {
        return this.http.get(`/myintranet/${this.userService.ipa}/pages`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Page>> response).entities);
    }

    /**
     * Delete a page
     * 
     * @returns {Promise<Array<Model.Page>>}
     * 
     * @memberOf PageService
     */
    /*public deletePage(id: string): Promise<any> {
        let parameters: HttpParams = new HttpParams();

        parameters.append('id', id);

        return this.http.request(`/myintranet/${this.userService.ipa}/pages/delete`, {
            method: 'DELETE',
            params: parameters
        }).toPromise();
    } */

    /**
     * Create a page
     * 
     * @param {Model.Page} page The page to create
     * @returns {Promise<Model.Page>}
     * 
     * @memberOf PageService
     */
    public createPage(page: Model.Page): Promise<Model.Page> {
        const params: Object = {
            template: page.template,
            path: page.path,
            widgets: page.widgets,
            attributes: page.attributes
        };

        if (page.alias && page.alias.length > 0) {
            params['alias'] = page.alias;
        }

        if (page.label && page.label.length > 0) {
            params['label'] = page.label;
        }

        return this.http.post(`/myintranet/${this.userService.ipa}/pages/create`, params)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Page>> response).entity);
    }

    /**
     * Update a page
     * 
     * @param {Model.Page} page The page to update
     * @returns {Promise<Model.Page>}
     * 
     * @memberOf PageService
     */
    public updatePage(page: Model.Page): Promise<Model.Page> {
        const params: Object = {
            id: page.id,
            template: page.template,
            path: page.path,
            widgets: page.widgets,
            attributes: page.attributes
        };

        if (page.alias && page.alias.length > 0) {
            params['alias'] = page.alias;
        }

        if (page.label && page.label.length > 0) {
            params['label'] = page.label;
        }

        return this.http.put(`/myintranet/${this.userService.ipa}/pages/update`, params)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Page>> response).entity);
    }

    /**
     * 
     * 
     * @param {Model.Page} page 
     * @returns 
     * @memberof PageService
     */
    public duplicate(id: string) {
        const params: Object = {
            id: id,
        };

        return this.http.post(`/myintranet/${this.userService.ipa}/pages/duplicate`, params)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Page>> response).entity);
    }

    public getPageByAttribute(param: string): Promise<Model.Page> {
        return this.http
            .get(`/myintranet/${this.userService.ipa}/pages/${param}`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Page>> response).entity);
    }
}
