import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface StateCallback {
  callback: Function;
  sourceId: string;
}

@Injectable()
export class GlobalState {

  /**
   * 
   * 
   * @private
   * @memberof GlobalState
   */
  private _data = new Subject<Object>();

  /**
   * 
   * 
   * @private
   * @memberof GlobalState
   */
  private _dataStream$ = this._data.asObservable();

  /**
   * 
   * 
   * @private
   * @type {Map<string, Array<Function>>}
   * @memberof GlobalState
   */
  private _subscriptions: Map<string, Array<StateCallback>> = new Map<string, Array<StateCallback>>();

  /**
   * Creates an instance of GlobalState.
   * @memberof GlobalState
   */
  constructor() {
    this._dataStream$.subscribe((data) => this.onEvent(data));
  }

  /**
   *
   *
   * @param {any} event
   * @param {any} value
   * @memberof GlobalState
   */
  public notifyDataChanged(event: string, value: any) {
    const current = this._data[event];
    if (current !== value) {
      this._data[event] = value;

      this._data.next({
        event,
        data: this._data[event]
      });
    }
  }

  /**
   * 
   * 
   * @param {string} event
   * @param {Function} callback
   * @memberof GlobalState
   */
  public subscribe(event: string, callback: Function, sourceId: string) {
    let subscribers = this._subscriptions.get(event) || [];
    subscribers.push({
      callback: callback,
      sourceId: sourceId
    });

    this._subscriptions.set(event, subscribers);
  }

  /**
   * 
   * 
   * @param {string} event 
   * @param {string} sourceId 
   * @memberof GlobalState
   */
  public unsubscribe(event: string, sourceId: string) {
    const subscribers = this._subscriptions.get(event) || [];

    const index = subscribers.findIndex(i => i.sourceId === sourceId);
    if (index > -1) {
      subscribers.splice(index, 1);
    }
  }

  /**
   * 
   * 
   * @private
   * @param {*} data 
   * @memberof GlobalState
   */
  private onEvent(data: any) {
    let subscribers = this._subscriptions.get(data['event']) || [];

    subscribers.forEach((stateCallback) => {
      stateCallback.callback.call(null, data['data']);
    });
  }
}
