<div class="confirm-pretrasmissione">
  <h2 class="colorSearch-an" mat-dialog-title>Riepilogo dati trasmissione</h2>
  <mat-dialog-content class="mat-typography">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="fieldName" class="fieldName">
        <mat-cell *matCellDef="let element">
          {{ element.fieldName | translate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="value">
        <mat-cell *matCellDef="let element">
          <div style="white-space: pre-line"> {{
            !element.value
              ? "-"
              : element.type == "date"
              ? (element.value | date: "dd/MM/yyyy")
              : element.type == "array"
              ? (element.value | spaceArray)
              : element.value
          }}</div>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div class="row"></div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" class="persBtn" mat-dialog-close>
      <p class="cont">Chiudi</p>
    </button>
    <button
      mat-raised-button
      color="accent"
      class="persBtn"
      (click)="trasmetti()"
    >
      <p class="cont">Trasmetti</p>
    </button>
  </mat-dialog-actions>
</div>
