import { RuoliEntiService } from 'src/app/services/ruoli-enti.service';
import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from './core/services/auth.service';
import { GlobalState } from './core/services/global-state.service';
import { LoaderService } from './core/spinner/loader.service';
import { EntiService } from './services/enti.service';
import { CambioRuoloEnteComponent } from './components/cambio-ruolo-ente-dialog/cambio-ruolo-ente-dialog';
import { ViewportScroller } from '@angular/common';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentInit {
  /**
   */
  public sideMenuCollapsed = false;
  public selected = new FormControl(null, null);
  public value: string;
  public enteForm: FormGroup;
  public openCloseMenu: Subject<boolean> = new Subject<boolean>();
  public iconContent: boolean = true;
  public utenteLoggato: string = 'Nessun utente loggato';
  public isUtenteLoggato: boolean = false;

  public nomeEnte: string = null;
  public isPublic = false;
  public menu: boolean = false;
  public scrollButton: boolean = false;

  // Creo una variabile booleana  per abilitare e disabilitare il cambio ruolo
  public mostraCambioRuolo: boolean = false;
  @ViewChild('header', {static: false}) private header: ElementRef<HTMLDivElement>;
  /**
   * Creates an instance of ApplicationComponent.
   */
  constructor(
    public loadService: LoaderService,
    public dialog: MatDialog,
    private state: GlobalState,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private enteSvc: EntiService,
    private translate: TranslateService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private ruoliEntiSvc: RuoliEntiService,
    private cdRef:ChangeDetectorRef,
    private scroll: ViewportScroller
  ) {
    translate.addLangs(['it', 'eng']);
    translate.setDefaultLang('it');
    translate.use('it');
  }

  ngAfterContentInit(): void {
     //this.cdRef.detach();
    setTimeout(() => {
      this.authenticationService.isPublic.subscribe(
        (boolEmitted) => (this.isPublic = boolEmitted)
      );
      this.menu = !this.isPublic;
      this.cdRef.detectChanges();
    });
  }

  ngOnInit() {
    this.enteForm = new FormGroup({
      ente: new FormControl(null, null),
    });
    this.authenticationService.currentAclObservable.subscribe((acl) => {
      if (acl != null) {
        this.isUtenteLoggato = true;
        this.utenteLoggato = acl.nome + ' ' + acl.cognome;
        this.nomeEnte = acl.nomeEnte;
        this.ruoliEntiSvc.getListRuoliEnte().subscribe((response) => {

          if (response.length > 1) {
            // Mostriamo "Cambio ruolo/utente"
            this.mostraCambioRuolo = true;
          } else {
            // Non Mostriamo "Cambio ruolo/utente"
            this.mostraCambioRuolo = false;
          }
        });
      } else {
        this.isUtenteLoggato = false;
        this.utenteLoggato = 'Nessun utente loggato';
        this.nomeEnte = null;
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(){
    if (this.header){
      const rect = this.header.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.scrollButton = !topShown && bottomShown;
    }
  }

  onRouteActivated() {
    this.cdRef.detach();
    setTimeout(() => {
      this.authenticationService.isPublic.subscribe(
        (boolEmitted) => (this.isPublic = boolEmitted)
      );
      this.menu = !this.isPublic;
      this.cdRef.reattach();
      this.cdRef.detectChanges();
    });
  }

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  showIconContent() {
    this.iconContent = !this.iconContent;
  }

  setLabelOnChangeValue(event) {

    if (event == 2) {
      this.logout();
    } else if (this.mostraCambioRuolo && event == 3) {
      this.openCambioRuoloEnteDialog();
    }
    this.selected = new FormControl(null, null);
  }

  openCambioRuoloEnteDialog() {
    //inserire qui la configurazione della material dialog MatDialogConfig per dati e css
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    const dialogRef = this.dialog.open(CambioRuoloEnteComponent, dialogConfig);
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  toggleMenu() {
    this.menu = !this.menu;
    if (this.menu) this.openCloseMenu.next(true);
    else this.openCloseMenu.next(false);
  }
}
