import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnteDelgatoService {

  private BASE_URL = environment.urls.base + '/EnteDelegato';

  constructor(private http: HttpClient) { }

   /**
   * Chiamata per recuperare la lista degli enti delegati validi
   * @param
   * @author Marta Zecchillo
   * @returns AutoCompleteDto[]/selectEnteDelegato.pjson
   */
  getEntiDelegati(){
    return this.http.get<AutoCompleteDto[]>
            (this.BASE_URL+`/selectEnteDelegato`);
  }

     /**
   * Chiamata per recuperare l'enete delgato in base all'acl dell'utente loggato
   * @param
   * @author Marta Zecchillo
   * @returns EnteDelegato
   */
  getEnteDelegato(codiceEnte: string){

    let params = new HttpParams();
    params = params.append("codiceEnte", codiceEnte);

    return this.http.get<ModelAutorizzazionePaesaggistica.EnteDelegatoDto>
            (this.BASE_URL+`/getEnteDelegato`,
            {
              params: params
            });
  }

}
