<div class="breadcrumbs">
  <span class="title">{{ "dettaglio.gestioneAutpae" | translate }}
    <mat-icon style="vertical-align: middle"> keyboard_arrow_right</mat-icon>
    <span *ngIf="!vistaMappa">{{ "dashboard.listaPratiche" | translate }}</span>
    <span *ngIf="vistaMappa">{{ "dashboard.mappa" | translate }}</span>
  </span>
</div>
<hr />
<div class="titolo flex-display">
  <span class="h1 upp" *ngIf="vistaMappa">{{
    "dashboard.mappa" | translate
    }}</span>
  <span class="h1 upp" *ngIf="!vistaMappa">{{
    "dashboard.title" | translate
    }}</span>
  <button mat-stroked-button color="basic" *ngIf="!vistaMappa" class="pers2" (click)="onNavigate('nuova-pratica')">
    {{ "dashboard.insert" | translate }}
  </button>
</div>
<mat-expansion-panel *ngIf="form" class="rett" hideToggle (opened)="arrowFlag = !arrowFlag"
  (closed)="arrowFlag = !arrowFlag">
  <mat-expansion-panel-header class="colorSearch">
    <mat-panel-title class="search">
      <mat-icon>search</mat-icon>
      <strong class="title">{{
        "generic.cerca" | translate | uppercase
        }}</strong>
      <mat-icon *ngIf="arrowFlag">keyboard_arrow_up</mat-icon>
      <mat-icon *ngIf="!arrowFlag">keyboard_arrow_down</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="coloredS" [formGroup]="form">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <app-text-field type="number" [min]="1" formControlName="id" [label]="'pratica.idFiltro' | translate">
        </app-text-field>
      </div>
      <div class="col-md-4 col-sm-12" *ngIf="!isEd">
        <app-select-auto-field formControlName="idEnteDelegato" [label]="'pratica.ente' | translate"
          [options]="entiDelegati" [disabled]="isEd" labelField="label" valueField="value"
          (valueChange)="evalOnChange('E')"></app-select-auto-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="idTipoProcedura" [label]="'pratica.tipoProc' | translate"
          [options]="tpOptions" labelField="label" valueField="value" (valueChange)="searchTI()">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="rilasciatoProvincia" [label]="'pratica.rilasciatoProv' | translate"
          [options]="rpOptions" labelField="label" valueField="value" (valueChange)="evalOnChange('RP')">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="idProvinciaCoinvolta" [label]="'pratica.provincia' | translate"
          [options]="pOptions" labelField="label" valueField="value" (valueChange)="evalOnChange('P')">
        </app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-auto-field formControlName="idComuneCoinvolto" [label]="'pratica.comune' | translate"
          [options]="cOptions" labelField="label" valueField="value" (valueChange)="evalOnChange('C')">
        </app-select-auto-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="statoPratica" [label]="'pratica.stato' | translate"
          [options]="statiOptions" labelField="label" valueField="value"></app-select-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-date-field formControlName="dataPresentazioneRichiesta" [label]="'pratica.dataPres' | translate">
        </app-date-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-date-field formControlName="dataRilascioDiniegoAutpae" [label]="'pratica.dataRilDin' | translate">
        </app-date-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-text-field formControlName="numeroAutpae" [label]="'pratica.numero' | translate"></app-text-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-text-field formControlName="richiedente" [label]="'pratica.richiedente' | translate"></app-text-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-text-field formControlName="oggetto" [label]="'pratica.oggetto' | translate"></app-text-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <app-select-field formControlName="idTipoIntervento" [label]="'pratica.tipoIntervento' | translate"
          [options]="tiOptions" labelField="label" valueField="value">
        </app-select-field>
      </div>
    </div>
  </div>
  <mat-action-row class="colored">
    <button mat-stroked-button class="pers2" color="warn" (click)="reset()">
      {{ "generic.reset" | translate }}
    </button>
    <button mat-raised-button class="pers2" color="accent" (click)="ricercaListaEventi()">
      {{ "generic.cerca" | translate }}
    </button>
  </mat-action-row>
</mat-expansion-panel>

<div *ngIf="isAlertAnnullamento && !vistaMappa">
<label style="width: 100%!important;">
  <input type="checkbox" class="alertCheckbox" autocomplete="off" />
  <div class="alert error">
    <span class="alertClose">X</span>
    <span class="alertText">Attenzione, ci sono pratiche per le quali è stato richiesto un annullamento 
    <br class="clear"/></span>
  </div>
</label>
</div>

<div style="margin-top: 1em"></div>
<ng-container *ngIf="!vistaMappa; else mappaTemplate">
  <app-simple-table [data]="list ? list.list : []" [header]="header" [action]="actions" [enableAction]="true" id="id"
    [totalRecords]="list ? list.count : 0" (onAction)="handleEvent($event)"
    (pageSortChange)="handleSortPagingChange($event)"
    [pageNumberInit]="praticaSearch.page">
  </app-simple-table>
</ng-container>
<ng-template #mappaTemplate>
  <h7>Il numero di pratiche consultabili è limitato ad un massimo di {{env.geo.maxResultsConsultazioneMappa}}</h7>
  <button mat-stroked-button color="basic" style="float: right; margin-bottom: 0.8em; margin-right: 0.8em; margin-top: 1.8em;" class="pers2"
    (click)="downloadShapeFile()" *ngIf="showDownload">
    {{ "dashboard.shapefile" | translate }}
  </button>
  <button mat-stroked-button color="basic" style="float: right; margin-bottom: 0.8em; margin-right: 0.8em; margin-top: 1.8em" class="pers2"
    (click)="downloadGeoJson()" *ngIf="showDownload">
    {{ "dashboard.geoJson" | translate }}
  </button>
  <app-mappa-pratiche [entitiesInput]="entitiesInput"></app-mappa-pratiche>
</ng-template>
