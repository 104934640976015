<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" (click)="onAnswerClicked(0)" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
    <!-- Information -->
    <div *ngIf="type === 0">
        <button (click)="onAnswerClicked(4)" class="btn btn-primary">Chiudi</button>
    </div>
</div>
<div class="modal-footer">
    <!-- Confirm -->
    <div *ngIf="type === 1">
        <button type="button" (click)="onAnswerClicked(1)" class="btn btn-primary">Sì</button>
        <button type="button" (click)="onAnswerClicked(2)" class="btn btn-default">No</button>
    </div>
</div>