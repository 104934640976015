import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseEntity, Line, Marker, Polygon } from 'regioneveneto-maps/models/models';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { Coordinate, GeometriesDeletedEvent, GeometriesEditedEvent, GeometryAddedEvent } from 'src/app/shared/components/ng-regioneveneto-map/models/model';
import { NgRegioneVenetoMapComponent } from 'src/app/shared/components/ng-regioneveneto-map/ng-regioneveneto-map.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tab-mappa',
  templateUrl: './tab-mappa.component.html',
  styleUrls: ['./tab-mappa.component.css']
})
export class TabMappaComponent implements OnInit {

  public windowSize: any = {
    width: "100%",
    height: "500px"
  };

  @Output() modifica: EventEmitter<any> = new EventEmitter();

  praticaDto: ModelAutorizzazionePaesaggistica.PraticaDto;
  @Input() entitiesInput:any[];


  constructor(
    private alertsCenterService:AlertsCenterService
  ) {}
  ngAfterViewInit(): void {
  }


  /******************************CONSTANTS  */
  /*public static BBOX_VENETO=[{lng : 13.10159959, lat : 46.68059681}, {lat : 44.79198856, lng : 10.62312538}];
  public static COORDS_PADOVA={ lat: 45.4161, lng: 11.8828 };
  public static LAYER_EDITING="Zona_Autor";
  public static ID_GRP_ENTITIES="groupId1";
  public static DRAW_STYLE={ color: "#da6565" };*/
  /********************************** */

  public options:any={
    initialization: {
    boundingBox : environment.geo.bboxVeneto,
    positioning: "point",
    zoom: 10,
    //point:{​​​​​​ lat: 45.4161, lng: 11.8828 }​​​​​​,
    /*boundingBox: [
    { lat: 60, lng: 20 },
    { lat: 30, lng: 10 },
    ],*/
    maxBounds: [
    [47, 14], // SW
    [44, 10], // NE
    ],
    maxZoom: 18,
    minZoom: 2,
    },

    searchProvider:{
      options:{
        style: 'bar',
        showMarker: true,
      },
      nativeSearchProvider:{
        name:'openStreetMap',
      },
    },
    
    layersToolbar: {
    position: "topright",
    viewMode: "expanded",
    },
    entitiesConstraints: {
      maxElementsForLayerInDrawMode: 10,
      maxVisibleElementsForLayer: 5,
    },
    drawOptions: {
      //drawModeEntries: ["polyline", "polygon", "rectangle", "circle", "marker"],
      drawModeEntries: ["polyline", "polygon", "marker"],
      drawStyle: {
        color: "red",
        fillColor: "yellow",
        weight: 5,
      },
    },
    };

    public baseLayers: any = [
      ...environment.layerAggiuntivi,
      {
        name: "map",
        type: "tile",
        url: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
        layerId: "mapbox/light-v9",
        isSelected: false,
      },
      {
        name: "OSM",
        type: "tile",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        isSelected: true,
        attribution:'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        },

      {
        name: "streets",
        type: "tile",
        url: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
        layerId: "mapbox/streets-v11",
        isSelected: false,
      },
    ];


  public centro: {
    type: "point" | "boundingBox";
    coordinates: Coordinate | [Coordinate, Coordinate];
  } = { type: "point", coordinates: environment.geo.coordsPadova };

  public overlayLayers = [
    {
      name: environment.geo.layerEditing,
      type: "entities",
      entities: [],
      isSelected: true,
      isEditable: true,
    },
  ];
  @Input() public isDrawModeEnabled: boolean = true;


  /**
   * qui vanno a finire le geometrie editate
   * ogni volta riassegno array
   */
  public entitiesGroups: any[]=[
    {
      layerName:environment.geo.layerEditing,
      id:environment.geo.idGrpEntities,
      entities:[]
    }
  ];

  @ViewChild("ngRegioneVenetoMap") public ngRegioneVenetoMap: NgRegioneVenetoMapComponent;

  private getEntities(){
    let entities=[];
    this.entitiesGroups.forEach(el=>{
      if(el.layerName==environment.geo.layerEditing &&
        el.id==environment.geo.idGrpEntities){
        entities=el.entities;
      }
    });
    return entities;
  }

  ngOnInit() {
    setTimeout(()=>{
      /*let entities=[];
      this.entitiesGroups=this.entitiesInput;
      this.entitiesGroups.forEach(el=>{
        if(el.layerName==LocalizzazionePraticaComponent.LAYER_EDITING &&
          el.id==LocalizzazionePraticaComponent.ID_GRP_ENTITIES){
          entities=el.entities;
        }
      });
      */
      let entities=this.entitiesInput.map(el=>{
        el.groupId=environment.geo.idGrpEntities;
        return el;
      });
      this.entitiesGroups=[
        {
          layerName:environment.geo.layerEditing,
          id:environment.geo.idGrpEntities,
          entities:entities
        }];
      if(entities && entities.length>0){
        //prendo tutti i punti derivanti sia da linee che da marker che da poligoni
        let coords:Coordinate[]=[];
        entities.forEach(ent=>{
          if(ent?.position){
            coords.push(ent?.position);
          }if(ent?.positions){
            coords.push(...ent?.positions);
          }
        });
        let coordCentro=this.ngRegioneVenetoMap.getPolygonBoundingBox(coords);
        this.centro={type:"boundingBox",coordinates:coordCentro};
        console.log("nuovo centro: {}",this.centro);
      }
    });
  }

private numberOfGeom():number{
  let ret:number=0;
  this.entitiesGroups.forEach(el=>{
    if(el.layerName==environment.geo.layerEditing &&
      el.id==environment.geo.idGrpEntities){
      ret=el.entities.length;
    }
  });
  return ret;
}

  private addToEntitiesGroup(geom:any){
    let oldEntities=[];
    this.entitiesGroups.forEach(el=>{
      if(el.layerName==environment.geo.layerEditing &&
        el.id==environment.geo.idGrpEntities){
        oldEntities=el.entities;
      }
    });
    this.entitiesGroups=[{
      id: environment.geo.idGrpEntities,
      layerName:environment.geo.layerEditing,
      entities: [geom,...oldEntities],
    }];
  }

  public onDrawEvent(event: any) {
    console.log("onDrawEvent {}",{
      type: event.type,
      data: JSON.stringify(event.data),
    });
    if(event.type=="geometryAdded"){
      if(this.getEntities().length>=environment.geo.maxGeometriePratica){
        this.alertsCenterService.showAlert({
          message: "Raggiunto il limite massimo ("+environment.geo.maxGeometriePratica+") di geometrie ammesse",
          type: 'success',
          autoClosable: false
        });
        return;
      }
      let geom=this.getGeometryFromAddEvent(event);
      if(geom){
        this.addToEntitiesGroup(geom);
      }
    }else if(event.type=="geometriesEdited"){
      this.patchEditedItems(event);
    }
    else if(event.type=="geometriesDeleted"){
      this.deleteItems(event);
    }
    this.modifica.emit({entities:this.getEntities(),overlayLayer:this.overlayLayers});
  }

  private deleteItems(event:GeometriesDeletedEvent){
    event.data.items.forEach(item=>{
      //cerco in entitiesGroup e aggiorno
      this.entitiesGroups.forEach(entityGroup=>{
        if(entityGroup.id==item.groupId){
          //dal gruppo devo eliminare le geometrie cancellate
          let newEntities=entityGroup.entities.filter(geom=>geom.id!=item.id);
          entityGroup.entities=newEntities;
        }
      });
    });
  }

  private patchEditedItems(event:GeometriesEditedEvent){
    event.data.items.forEach(item=>{
      //cerco in entitiesGroup e aggiorno
      this.entitiesGroups.forEach(entityGroup=>{
        if(entityGroup.id==item.groupId){
          entityGroup.entities.forEach(geom => {
            if(geom.id==item.id){
              console.log("patched geom id{}",geom.id);
              if(geom?.positions){
                if(Array.isArray((item as any)?.positions)){
                  let pos=(item as any).positions[0];
                  geom.positions=pos;
                }else{
                  geom.positions=(item as any).positions;
                }
              }
              if(geom?.position){//punto
                geom.position=(item as any).position;
              }
            }
          });
        }
      });
    });
  }

  private getGeometryFromAddEvent(event:any):BaseEntity{
    /*es.
      {
    "type": "geometryAdded",
    "data": "{\"geometryType\":\"polygon\",\"vertices\":[{\"lat\":45.411345842087755,\"lng\":11.89476013183594},{\"lat\":45.39398997323948,\"lng\":11.86454772949219},{\"lat\":45.37566410507485,\"lng\":11.886520385742188},{\"lat\":45.38723902876603,\"lng\":11.92497253417969}]}"
      }

      */
    let geomType=event?.data?.geometryType;
    let baseEntity:BaseEntity=null;
    switch (geomType) {
      case "polygon":
        let pligonToAdd:Polygon={
          type:"polygon",
          positions:event?.data?.vertices,
          drawStyle:environment.geo.drawStyle ,
          id:(this.numberOfGeom()+1)+"",
          groupId:environment.geo.idGrpEntities,
        }
        baseEntity=pligonToAdd;
        break;
      case "marker":
        let markerToAdd:Marker={
          type:"marker",
          position:event?.data?.vertices,
          drawStyle:environment.geo.drawStyle,
          id:(this.numberOfGeom()+1)+"",
          groupId:environment.geo.idGrpEntities,
        }
        baseEntity=markerToAdd;
        break;
      case "polyline":
        let lineToAdd:Line={
          type:"polyline",
          positions:event?.data?.vertices,
          drawStyle:environment.geo.drawStyle ,
          id:(this.numberOfGeom()+1)+"",
          groupId:environment.geo.idGrpEntities,
        }
        baseEntity=lineToAdd;
        break;
      default:
        alert("Geometria non supportata "+geomType);
        break;
    }
    return baseEntity;
  }


  public onEntityClickedEvent(event: any) {
    console.log("onEntityClickedEvent {}",{
      type: event.type,
      data: JSON.stringify(event.data),
    });
  }


  public downloadShapeFile(){
    if(!this.getEntities()){
      alert("Nessuna geometria da esportare!!");
    }
    //contatto il be e mi faccio generare lo shape dal geoJson
  }
}
