import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalSessionService
{
    public static ACL_KEY     = "acl_autpae";
    public static USER_KEY     = "user_autpae";
    public static UFFICIO_KEY = "ufficio_autpae";
    public static URL_MYINTRANET = "UrlRichiesta";

    constructor() { }

    public store(key: string, item: any): void
    {
        sessionStorage.setItem(key, item);
    }

    public remove(key: string): void
    {
        sessionStorage.removeItem(key);
    }

    public contains(key: string): boolean
    {
        return sessionStorage.getItem(key) != null;
    }

    public clear(): void
    {
        sessionStorage.clear();
    }

    public get(key: string): any
    {
        if(!this.contains(key))
            throw new Error("Chiave " + key + " non presente nello storage");
        sessionStorage.getItem(key);
    }

}