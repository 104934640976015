import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntiService {
  private _enti: BehaviorSubject<number> = new BehaviorSubject(null);
  enti$ = this._enti.asObservable();

  constructor(private http: HttpClient) { }

  public getList(page: number, size: number, sort: string, filters?: ModelEventiCalamitosi.EnteSearchableDto) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('page', `${page}`);
    searchParams = searchParams.append('size', `${size}`);
    searchParams = searchParams.append('sort', `${sort}`);
    for (let key in filters) {
      if (filters[key] !== null) {
        searchParams = searchParams.append(key, filters[key]);
      }
    }

    return this.http
      .get<ModelEventiCalamitosi.EnteDto[]>(`${environment.urls.base}/template/ente/list`,
        {
          params: searchParams
        }
      );
  }

  public getAbilitazioneEntePerEvento(idEvento: number) {
    return this.http
      .get<ModelEventiCalamitosi.EnteAbilitatoPerEventoDto>(`${environment.urls.base}/template/ente/ente-abilitato-compilazione-segnalazioni-per-evento/${idEvento}`);
  }

  public getAbilitazioneCorrezioneEntePerEvento(idEvento: number) {
    return this.http
      .get<ModelEventiCalamitosi.EnteAbilitatoPerEventoDto>(`${environment.urls.base}/template/ente/ente-abilitato-correzione-segnalazioni-per-evento/${idEvento}`);
  }

  public getEntiProfilatiList() {
    return this.http
      .get<ModelEventiCalamitosi.EnteDto[]>(`${environment.urls.base}/template/ente/list-enti-profilati`);
  }

  public changeStatoEnte(enteDto: ModelEventiCalamitosi.AnaDatiRaccoltiDTO) {
    return this.http
      .post<ModelEventiCalamitosi.EnteDto>(
        `${environment.urls.base}/template/ente/cambia-stato`,
        enteDto,
        {
          observe: 'response'
        }
      );
  }

  public modificaStatoCompilazione(compilazioneEntiDto: ModelEventiCalamitosi.ModStatoCompilazioneEntiDto) {
    return this.http.post<ModelEventiCalamitosi.EnteDto[]>(
      `${environment.urls.base}/template/ente/modifica-stato-compilazione`,
      compilazioneEntiDto,
      {
        observe:
          'response'
      });
  }

  showEnte(idEnte: number) {
    this._enti.next(idEnte);
  }

}
