import { TextAreaFieldComponent } from './../textarea-field/textarea-field';
import { forEach, isEqual, map, methodOf, String } from 'lodash';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { DialogService } from 'src/app/services/dialog.service';

import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { TipoProceduraService } from 'src/app/services/autorizzazionePaesaggistica/tipoProcedura.service';
import { TipoInterventoService } from 'src/app/services/autorizzazionePaesaggistica/tipoIntervento.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

import { PraticaService } from 'src/app/services/autorizzazionePaesaggistica/pratica.service';
import { EnteDelgatoService } from 'src/app/services/autorizzazionePaesaggistica/enteDelegato.service';
import { RilasciatoProvinciaService } from 'src/app/services/autorizzazionePaesaggistica/rilasciatoProvincia.service';
import { AnagraficaEnteService } from 'src/app/services/autorizzazionePaesaggistica/anagraficaEnte.service';
import { TipoVincoloService } from 'src/app/services/autorizzazionePaesaggistica/tipoVincolo.service';
import { AutoCompleteDto } from 'src/app/model/base-model';
import {
  RilasciatoProvinciaEnum,
  TipoDocumento,
} from 'src/app/model/AutorizzazionePaesaggistica.enum';
import { combineLatest, Observable } from 'rxjs';
import { downloadFile, statiOptions } from 'src/app/utilities/functions';
import { Pannelli } from 'src/app/model/Pannelli.enum';
import { CustomValidator } from 'src/app/shared/validators/custom-validator.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LocalizzazionePraticaComponent } from '../localizzazione-pratica/localizzazione-pratica.component';
import {
  fromGeoJSONToEntities,
  toGeoJson,
} from 'src/app/shared/components/ng-regioneveneto-map/models/model';
import { LocalSessionService } from 'src/app/core/services/local-session.service';
import { Local } from 'protractor/built/driverProviders';
import { TranslateService } from '@ngx-translate/core';
import {
  DatiRiepilogoPretrasmissione,
  RiepilogoPretrasmissioneDialogComponent,
} from '../riepilogo-pretrasmissione-dialog/riepilogo-pretrasmissione-dialog.component';
import { ArrayDataSource } from '@angular/cdk/collections';
import { I } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { DescrizioneTipoVincoloService } from 'src/app/services/autorizzazionePaesaggistica/descrizioneTipoVincolo.service';
import { tmpdir } from 'os';
import { EventEmitter } from '@angular/core';

interface FormElement {
  controlName: string;
  pannel: Pannelli;
}

interface FieldElement {
  field: string;
  array: boolean;
}

@Component({
  selector: 'app-dettaglio-pratica',
  templateUrl: './dettaglio-pratica.component.html',
  styleUrls: ['./dettaglio-pratica.component.css'],
})


export class DettaglioPraticaComponent implements OnInit {
  public form: FormGroup;
  formConfig: FormElement[] = [];
  public today = new Date();

  /*Variabili per la validazione */
  public fileAutorizzazione: boolean = false;
  public fileParereSop: boolean = false;
  public fileParereCommLoc: boolean = false;
  public validationFileAutorizzazione: ValidationErrors = null;
  public validationFilePareSop: ValidationErrors = null;
  public validationFilePareCommLoc: ValidationErrors = null;

  enable: boolean = false;

  /*Flag per verificare se siamo in dettaglio/readonly*/
  public isDettaglio: boolean;
  /*Flag per verificare se siamo in modifica*/
  public isModifica: boolean;
  public navigationSubscription;
  acl: string = sessionStorage.getItem('acl');
  praticaBean: ModelAutorizzazionePaesaggistica.PraticaBean;
  praticaDto: ModelAutorizzazionePaesaggistica.PraticaDto;
  entiDelegati: AutoCompleteDto[] = [];
  enteSelected: ModelAutorizzazionePaesaggistica.EnteDelegatoDto;
  tipoProcedura: ModelAutorizzazionePaesaggistica.TipoProcedura[] = [];
  tipoProceduraSelected: ModelAutorizzazionePaesaggistica.TipoProcedura;
  tipoIntervento: ModelAutorizzazionePaesaggistica.TipoInterventoDto[] = [];
  tipoInterventoSelected: ModelAutorizzazionePaesaggistica.TipoInterventoDto[] = [];
  tipoVincolo: ModelAutorizzazionePaesaggistica.TipoVincolo[] = [];
  //tipoVincoloSelected: ModelAutorizzazionePaesaggistica.TipoVincolo;
  descTipoVincolo: ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo[] =
    [];
  descTipoVincoloSelected: ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo;
  tipoRilasciatoProv: ModelAutorizzazionePaesaggistica.RilasciatoProvinciaDto[] =
    [];
  tipoRilasciatoSelected: ModelAutorizzazionePaesaggistica.RilasciatoProvinciaDto;
  provinceCoinvolte: AutoCompleteDto[] = [];
  comuniCoinvolti: ModelAutorizzazionePaesaggistica.DatiComuneDto[] = [];
  comuniCoinvoltiSelected: ModelAutorizzazionePaesaggistica.DatiComuneDto[] =
    [];
  comuniEnteDelegato: ModelAutorizzazionePaesaggistica.DatiComuneDto[] = [];
  comuniProvincia: ModelAutorizzazionePaesaggistica.DatiComuneDto[] = [];
  comuniCoinvoltiDescription: string[] = [];
  vincolo: AutoCompleteDto[] = [];

  //idTipoVincolo_lista = [];
  idTipoVincolo_map = new Map<string, ModelAutorizzazionePaesaggistica.ListaVincoliOggetto>(); // 
  //vincoloMap = new Map<string, string[]>(); // 


  parereSop: any[] = [
    { label: 'SI', value: 'SI' },
    { label: 'NO', value: 'NO' },
    { label: 'Incluso in autorizzazione', value: 'IA'}
  ];
  parereComLoc: any[] = [
    { label: 'SI', value: 'SI' },
    { label: 'NO', value: 'NO' },
    { label: 'Incluso in autorizzazione', value: 'IA'}
  ];

  public mimeAccepted = '.pdf,.p7m';
  public mimeAutorizzazioneAccepted = '.p7m';
  public tipoDocumentoEnum = TipoDocumento;
  allDesTipiVincolo: ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo[];
  allTipiIntervento: ModelAutorizzazionePaesaggistica.TipoInterventoDto[];
  // dati per la update
  mapFiles = new Map<string, File>(); // qui arrivano i file sleezionati localmente dall'input type file
  mapFilesCheck = new Map<string, number>(); // qui arrivano i file selezionati localmente dall'input type file
  allegatiRimossi: number[] = [];
  idPratica: string;

  /* DISABLE PROPERTY */
  disableRilasciatoProv: boolean = false;
  disabledFileParereSop: boolean = false;
  disabledFileParereCommLoc: boolean = false;

  tabInfo: boolean = false;
  tabIntervento: boolean = false;
  tabVincoli: boolean = false;
  tabAllegati: boolean = false;
  invia: boolean = false;
  tabMappa: boolean = false;
  allegatiAction: { TipoDocumento };
  public geoJson: any = [];
  public entitiesInput: any = [];

  public dataProtocollo = null;
  public numeroProtocollo = null;

  public geometrieModificate: boolean = false;

  datiRiepilogoPretrasmissione: DatiRiepilogoPretrasmissione;
  maxlength: number = null;

  /*Data Filter: per disabilitare date future */
  dateFilter = (d: Date | null): boolean => {
    const day = (new Date(d) || new Date()).getTime();
    const today = new Date().getTime();
    return day < today;
  };
  /**
   * tutti i possibili vincoli selezionati in base ai comuni selezionabili per l'ente delegato
   * in caso di provincia comprende oltre che ai comuni delegati per delega, anche quelli della provincia
   */
  allVincoliPratica: ModelAutorizzazionePaesaggistica.VincoloOptionBean[];
  labelStatoPratica: string;

  @ViewChild('textArea') textArea: TextAreaFieldComponent;

  maxSizeFile: any = environment.file.maxFileSize;

  constructor(
    private dialogSvc: DialogService,
    public dialog: MatDialog,
    private alertsCenterService: AlertsCenterService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private aclSvc: AuthenticationService,
    private praticaSvc: PraticaService,
    private enteDelegatoSvc: EnteDelgatoService,
    private tipoProceduraSvc: TipoProceduraService,
    private tipoInterventoSvc: TipoInterventoService,
    private tipoVincoloSvc: TipoVincoloService,
    private rilasciatoProvinciaSvc: RilasciatoProvinciaService,
    private anagraficaEnteSvc: AnagraficaEnteService,
    private customValidator: CustomValidator,
    private ts: TranslateService
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.modeSelector();
      }
    });
  }

  ngOnInit(): void {
    this.idPratica = this.route.snapshot.paramMap.get('idPratica');
    this.mapFilesCheck.clear();
    this.modeSelector();
    this.setFormConfig();
    this.buildForm();
    this.initCall();
    this.setVariableError();
  }

  tabChanged(event: any) {
    if (event.tab.textLabel == 'MAPPA') {
      this.tabMappa = true;
    } else {
      this.tabMappa = false;
    }
  }

  private modeSelector() {
    this.isDettaglio = this.router.url.startsWith('/dettaglio-pratica');
    this.isModifica = this.router.url.startsWith('/modifica-pratica');
  }

  initCall() {
    this.praticaSvc.getPratica(this.idPratica).subscribe((response) => {
      console.log(response);
      this.praticaDto = response.praticaDto;
      this.praticaBean = response;
      this.dataProtocollo = response.autorizzazione?.dataProtocollo;
      this.numeroProtocollo = response.autorizzazione?.numeroProtocollo;
      this.enteSelected = response.entePratica;
      if (response.geoJson) {
        this.geoJson = JSON.parse(response.geoJson);
        this.entitiesInput = fromGeoJSONToEntities(this.geoJson);
      }
      this.caricaDatiSelect();
      this.mappingStato();
      // 1) Questi 3 sono veri solo se c'è il file
      this.fileAutorizzazione = this.praticaBean.autorizzazione != null;
      this.fileParereSop = this.praticaBean.parereSop != null;
      this.fileParereCommLoc = this.praticaBean.parereCommLoc != null;

      if (this.fileAutorizzazione)
        this.mapFilesCheck.set(
          TipoDocumento.AUTORIZZAZIONE,
          this.praticaBean.autorizzazione.id
        );
      if (this.fileParereSop)
        this.mapFilesCheck.set(
          TipoDocumento.PARERE_SOP,
          this.praticaBean.parereSop.id
        );
      if (this.fileParereCommLoc)
        this.mapFilesCheck.set(
          TipoDocumento.PARERE_COMMLOC,
          this.praticaBean.parereCommLoc.id
        );

      // Controllo se disabilitare in ingresso i campi di input file
      this.disableAllegati(
        this.praticaDto.presenzaParereSop,
        TipoDocumento.PARERE_SOP
      );
      this.disableAllegati(
        this.praticaDto.presenzaParereCommLoc,
        TipoDocumento.PARERE_COMMLOC
      );
    });
  }

  private caricaDatiSelect() {

    let tipiRilasciatoProvincia$ =
      this.rilasciatoProvinciaSvc.getTipiRilasciatoProvincia(
        this.enteSelected.tipoEnteDelegato
      );
    let tipiProcedura$ = this.tipoProceduraSvc.getTipiProcedura();
    let anaProvince$ = this.anagraficaEnteSvc.getAnagraficaEntiProvince();
    let comuniEnte$ = this.anagraficaEnteSvc.getAnagraficaEntiComuni(
      this.praticaDto.idEnteDelegato,
      null,
      this.praticaDto.id
    );

    let tipiVincolo$ = this.tipoVincoloSvc.getTipiVincolo();
    let allDesTipiVincolo$ = this.tipoVincoloSvc.getAllDescrizioneTipiVincolo();

    this.entiDelegati = [
      { label: this.enteSelected.descrizione, value: this.enteSelected.id },
    ];
    // già arrivato a tappo 6 elementi massimo
    combineLatest([
      tipiRilasciatoProvincia$,
      tipiProcedura$,
      anaProvince$,
      comuniEnte$,
      tipiVincolo$,
      allDesTipiVincolo$,
    ]).subscribe(
      ([
        tipiRilasciatoProvincia,
        tipiProcedura,
        anaProvince,
        comuniEnte,
        tipiVincolo,
        allDesTipiVincolo,
      ]) => {
        // Controllo se l'Ente Delegato e' diverso da Tipo Provincia (D)
        if (this.enteSelected.tipoEnteDelegato !== 'D') {
          // Ciclo i valori di tipo Rilasciato Provincia presi dal Be
          tipiRilasciatoProvincia.find((e) => {
            // Se il valore preso dal Be risulta essere "Vuoto"
            if (e.id === RilasciatoProvinciaEnum.VUOTO) {
              // Metto il valore vuoto in rilasciatoProvincia
              this.praticaDto.rilasciatoProvincia = e.id;
              // allora pusho solo il valore uguale a vuoto in tipo rilasciato provincia
              this.tipoRilasciatoProv.push(e);
            }
          });
          if (this.isModifica) {
            this.disableEnableField('rilasciatoProvincia');
          }
        } else {
          // Se, invece l'Ente Delegato è uguale a Tipo Provincia (D)
          tipiRilasciatoProvincia.forEach((element) => {
            // e l'elemento preso dal Be risulta essere diverso da "Vuoto"
            if (element.id !== RilasciatoProvinciaEnum.VUOTO) {
              // Allora pusho i valori tranne il vuoto
              this.tipoRilasciatoProv.push(element);
            }
          });
        }
        this.tipoRilasciatoProv = tipiRilasciatoProvincia;
        this.tipoProcedura = tipiProcedura;
        this.provinceCoinvolte = anaProvince;
        // this.comuniCoinvolti = comuniEnte;
        this.comuniEnteDelegato = comuniEnte;
        this.setPronviciaCoinvolta(comuniEnte);
        this.tipoVincolo = tipiVincolo;
        this.allDesTipiVincolo = allDesTipiVincolo;
        // this.comuniCoinvoltiDescripion = comuniEnte.map((el) => el.label);
        //seconda combine latest
        let allTipiInt$ = this.tipoInterventoSvc.getAllTipiIntervento();
        let allVincoliPratica$ = this.tipoVincoloSvc.getVincoliPossibiliPratica(
          this.idPratica
        );
        combineLatest([allTipiInt$, allVincoliPratica$]).subscribe(
          ([allTipiInt, allVincoliPratica]) => {
            this.allTipiIntervento = allTipiInt;
            this.allVincoliPratica = allVincoliPratica;
            this.setComuniCoinvolti(this.patchFormValoriIniziali.bind(this));
          }
        );
        /*
        this.tipoInterventoSvc.getAllTipiIntervento().subscribe((res) => {
          this.allTipiIntervento = res;
          this.setComuniCoinvolti(this.patchFormValoriIniziali.bind(this));
        });
        */
        if (this.isModifica && this.praticaDto.statoPratica == 'BOZZA') {
          this.segnalaEventualeConcorrenza();
        }
      }
    );
  }

  segnalaEventualeConcorrenza() {
    const userStringify = sessionStorage.getItem(LocalSessionService.USER_KEY);
    let userCf = null;
    if (userStringify) {
      let userData = JSON.parse(userStringify);
      if (userData && userData?.codiceFiscale) {
        userCf = userData?.codiceFiscale;
      }
    }
    let nowTime = new Date();
    let dueOre = 3600 * 2 * 1000;
    let dateUpdated = new Date(this.praticaDto.dateUpdated);
    let isRecente = dateUpdated.getTime() + dueOre > nowTime.getTime();
    if (
      this.praticaDto.dateUpdated &&
      this.praticaDto.userUpdated &&
      userCf &&
      userCf != this.praticaDto.userUpdated &&
      isRecente
    ) {
      this.dialogSvc
        .openDialog(
          this.ts.instant('generic.warning'),
          this.ts.instant('pratica.updateRecenteConcorrente'),
          null,
          null,
          true,
          {
            labelConferma: 'Chiudi',
          }
        )
        .subscribe();
    }
  }

  setFormConfig() {


    //TEST
    /*
    this.dataForm = new FormGroup({
      descrizioneEstTipoVincolo: new FormArray(this.dataArr.map(data => new FormControl(data.key, [Validators.required])))
        // Add other form groups or form controls here
    });*/


    // Setto l'oggetto formConfig con i campi e i pannelli che servono
    this.formConfig = [
      {
        controlName: 'idEnteDelegato',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'oggetto',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'richiedente',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'dataPresentazioneRichiesta',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'dataRilascioDiniegoAutpae',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'dataProtocolloEnte',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'numeroProtocolloEnte',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'numeroAutpae',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'idTipoProcedura',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'rilasciatoProvincia',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'idProvinciaCoinvolta',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'comuniCoinvolti',
        pannel: Pannelli.INFORMAZIONI_GENERALI,
      },
      {
        controlName: 'idTipoIntervento',
        pannel: Pannelli.INTERVENTO,
      },
      {
        controlName: 'descrizioneTipoIntervento',
        pannel: Pannelli.INTERVENTO,
      },
      {
        controlName: 'idTipoVincolo',
        pannel: Pannelli.VINCOLI,
      },
      {
        controlName: 'idCodiceDescrizioneVincolo1',
        pannel: Pannelli.VINCOLI,
      },
      {
        controlName: 'idCodiceDescrizioneVincolo2',
        pannel: Pannelli.VINCOLI,
      },
      
      {
        controlName: 'descrizioneEstTipoVincolo1',
        pannel: Pannelli.VINCOLI,
      } ,
      {
        controlName: 'descrizioneEstTipoVincolo2',
        pannel: Pannelli.VINCOLI
      } ,
      {
        controlName: 'vincoli',
        pannel: Pannelli.VINCOLI,
      },

      /*
      {
        controlName: 'codiceDescrizioneSelected1',
        pannel: Pannelli.VINCOLI,
      },
      {
        controlName: 'codiceDescrizioneSelected2',
        pannel: Pannelli.VINCOLI,
      },*/

      {
        controlName: 'presenzaParereSop',
        pannel: Pannelli.ALLEGATI,
      },
      {
        controlName: 'presenzaParereCommLoc',
        pannel: Pannelli.ALLEGATI,
      },
    ];
  }

  buildForm() {
    // Istanzio la variabile new FormGroup
    this.form = new FormGroup({});

    /*this.fb: FormBuilder;
    descrizioneEstTipoVincolo: this.fb.array([
      this.fb.control('')
    ])*/
    // Itero l'array formConfig per recuperare il nome da assegnare al formControl
    this.formConfig.forEach((element) => {
      // Assegno al formoControlName il valore corrispondente all'elemento formConfig
      var formControlName: any = element.controlName;
      this.form.addControl(formControlName, new FormControl(null));
    });

    // Se siamo in dettagli-pratica non sarà possibile modifcare nessun campo
    if (this.isDettaglio) {
      // Disabilita tutto il form
      this.form.disable();
    } else {
      // Campi sempre disabilitati
      this.disableField('idEnteDelegato');
      this.disableField('descrizioneTipoIntervento');
      this.disableField('descrizioneEstTipoVincolo1');
      this.disableField('descrizioneEstTipoVincolo2');

    }
    //ascolta il settaggio sia programmatico che non su comuniCoinvolti
    this.form.get('comuniCoinvolti').valueChanges.subscribe((evento) => {
      this.onChangeComuniCoinvolti(evento);
    });
  }

  chooseTipoVincolo() {


    let idTipoVincolo = this.form.value.idTipoVincolo;  


    if(idTipoVincolo != null && idTipoVincolo != undefined){


      if(idTipoVincolo.length==0){


        this.idTipoVincolo_map = new Map<string, ModelAutorizzazionePaesaggistica.ListaVincoliOggetto>();
        this.form.patchValue({ idCodiceDescrizioneVincolo1: '' });
        this.form.patchValue({ idCodiceDescrizioneVincolo2: '' });
      } else {


        for(var i = 0; i < idTipoVincolo.length; i++) {

          if(!this.idTipoVincolo_map.has(idTipoVincolo[i])){

            this.descTipoVincolo = this.allDesTipiVincolo.filter(
              (el) => el.codiceTipoVincolo == idTipoVincolo[i]
            );

            let tmp_vi: ModelAutorizzazionePaesaggistica.ListaVincoliOggetto = {
              vincolo: idTipoVincolo[i],
              vincolo_descrizione: this.descTipoVincolo,
              vincolo_descrizione_selected: null,
              vincolo_descrizione_estesa: null
            };

            this.idTipoVincolo_map.set(idTipoVincolo[i], tmp_vi);

              if(this.idTipoVincolo_map.get(idTipoVincolo[i]).vincolo  == 'ART136_42_2004'){
              this.patchValueField('descrizioneEstTipoVincolo1', false);
              } 

              if(this.idTipoVincolo_map.get(idTipoVincolo[i]).vincolo  == 'ART142_42_2004'){
              this.patchValueField('descrizioneEstTipoVincolo2', false);
              } 

            } else {          



              if(!(idTipoVincolo.length == this.idTipoVincolo_map.size)){
                  if(this.idTipoVincolo_map.get(idTipoVincolo[i]).vincolo == 'ART136_42_2004'){
                  //this.patchValueField('descrizioneEstTipoVincolo1', false);
                  } else {
                    this.form.patchValue({ idCodiceDescrizioneVincolo1: '' });
                    this.idTipoVincolo_map.delete('ART136_42_2004');
                  }

                  if(this.idTipoVincolo_map.get(idTipoVincolo[i]).vincolo == 'ART142_42_2004'){
                  //this.patchValueField('descrizioneEstTipoVincolo2', false);
                  } else {
                    this.form.patchValue({ idCodiceDescrizioneVincolo2: '' });
                    this.idTipoVincolo_map.delete('ART142_42_2004');
                  }
              }
             
            }


          } 
          /*
          this.tipoVincolo.find((e) => {
            for(var i = 0; i < idTipoVincolo.length; i++) {
            //if (e.codice == idTipoVincolo.toString()) {
              if(e.codice == idTipoVincolo[i]){
              this.tipoVincoloSelected = e;
              //this.vincoloMap.set(idTipoVincolo[i], []);
              console.log('valore tipoVincoloSelected 1-->'+JSON.stringify(e))
              }
           }
          });*/
      }
    } else {

      this.idTipoVincolo_map = new Map<string, ModelAutorizzazionePaesaggistica.ListaVincoliOggetto>();
      this.form.patchValue({ idCodiceDescrizioneVincolo1: '' });
      this.form.patchValue({ idCodiceDescrizioneVincolo2: '' }); 

      
      //this.vincoloMap = new Map<string, []>(); 
    }
  
   
  }

  chooseDescTipoVincolo(event: any, id: any) {
    // this.form.value.idCodiceDescrizioneVincolo;

    let vincoli_append_string: string='';
    let vincoli_append_string_riepilogo: string='';

    let idCodiceDescrizioneVincolo = event.value;

    // abblenco e poi intercetto la scelta e inserisco
    if(this.idTipoVincolo_map.get(id).vincolo == 'ART136_42_2004'){
     this.form.patchValue({ descrizioneEstTipoVincolo1: '' });
    }

    if(this.idTipoVincolo_map.get(id).vincolo == 'ART142_42_2004'){
     this.form.patchValue({ descrizioneEstTipoVincolo2: '' });
    }

    if(this.idTipoVincolo_map.has(id)){
     this.idTipoVincolo_map.get(id).vincolo_descrizione_selected = idCodiceDescrizioneVincolo;
    } 

    this.descTipoVincolo = this.allDesTipiVincolo.filter(
      (el) => el.codiceTipoVincolo == this.idTipoVincolo_map.get(id).vincolo
    );

    let selezionate_ART136: string[]=[];
    let selezionate_ART142: string[]=[];

    if(idCodiceDescrizioneVincolo != null && idCodiceDescrizioneVincolo != undefined && idCodiceDescrizioneVincolo != '') {

        for(var i = 0; i < idCodiceDescrizioneVincolo.length; i++) {
        this.descTipoVincolo.forEach((el) => {

          
              if (el.codiceDescrizioneVincolo == idCodiceDescrizioneVincolo[i].toString()) {

                vincoli_append_string = vincoli_append_string + el.descrizione + '\n \r';
                vincoli_append_string_riepilogo = vincoli_append_string_riepilogo+ el.descrizione + '\n \n \r';
                if(this.idTipoVincolo_map.get(id).vincolo == 'ART136_42_2004'){

                  selezionate_ART136.push(el.codiceDescrizioneVincolo)
                  this.form.patchValue({
                    //descrizioneEstTipoVincolo: el.descrizione + '\n',
                    descrizioneEstTipoVincolo1: vincoli_append_string,
                  });

                  this.form.patchValue({ idCodiceDescrizioneVincolo1: selezionate_ART136});
                  
                } else {
                    if(this.idTipoVincolo_map.get(id).vincolo == 'ART142_42_2004'){

                      selezionate_ART142.push(el.codiceDescrizioneVincolo)
                      this.form.patchValue({
                        //descrizioneEstTipoVincolo: el.descrizione + '\n',
                        descrizioneEstTipoVincolo2: vincoli_append_string,
                      });
                      this.form.patchValue({ idCodiceDescrizioneVincolo2: selezionate_ART142});
                  }

                }
                this.idTipoVincolo_map.get(id).vincolo_descrizione_estesa = vincoli_append_string_riepilogo;

              //this.descTipoVincoloSelected = el;
              console.log('settato descrizione{}', el.descrizione);
            }
        });
      }
    }

    // Chiamata per il campo hidden ID_VINCOLO
    /*
    this.tipoVincoloSvc
      .getVincolo(idCodiceDescrizioneVincolo)
      .subscribe((result) => {
        this.vincolo = result;
      });
    */ // ora vengono fetchati tutti i possibili già in init e vengono mostrati solo quando setta i comuni coinvolti
  }

  chooseIntervento(event: any, callback?: any, isInit?: boolean) {

    var tipoProceduraSelected = event.value;
    this.tipoIntervento = this.allTipiIntervento.filter(
      (el) => el.idTipoProcedura == tipoProceduraSelected
    );
    if (!isInit) {
      this.form.patchValue({
        idTipoIntervento: null,
        descrizioneTipoIntervento: '',
      });

      // Forziamo il reset dell'autosize (Altrimenti al cambio tipo procedura non verrà resettato l autosize
      // della descrizione tipo intervento)
      this.textArea.autosize.reset();
    }
    if (callback) {
      callback();
    }
  }

  chooseDescTipoIntervento() {
    this.tipoInterventoSelected = [];
    let tmp_append_string: string='';
    let idTipoIntervento = this.form.value.idTipoIntervento;

    this.form.patchValue({ descrizioneTipoIntervento: '' });

    if (!idTipoIntervento) return;

    for(var i = 0; i < idTipoIntervento.length; i++) {

    this.tipoIntervento.forEach((el) => {
      if (el.id == idTipoIntervento[i].toString()) {
        tmp_append_string = tmp_append_string + el.descrizione + '\n \r';
        this.form.patchValue({
          //descrizioneTipoIntervento: el.descrizione + '\n',
          descrizioneTipoIntervento: tmp_append_string,
        });

        this.tipoInterventoSelected.push(el);
      }
    }); 
   }
  }

  /**
   *
   * @param action  trasmetti=vuole trasmettere , altrimenti null per salvataggio
   */
  savePratica(action: string) {
    let value = this.form.value;

    // Se il campo è disabled: value?.field non restituirà nulla.
    // Quindi, se value?.field è vuoto, recuperiamo il valore precedentemente impostato nell'oggetto
    // altrimenti si prenderà il valore scelto nella form
    // (Il campo non può essere disabilitato come può essere abilitato)
    var rilasciatoProvincia = this.form.get('rilasciatoProvincia').enabled
      ? value?.rilasciatoProvincia
      : this.praticaDto.rilasciatoProvincia;

    var provincia = this.form.get('idProvinciaCoinvolta').enabled
      ? value?.idProvinciaCoinvolta
      : this.praticaDto.idProvinciaCoinvolta;

    let comuni = this.form.get('comuniCoinvolti').enabled
      ? value?.comuniCoinvolti
      : this.praticaBean.comuniCoinvolti;

    // else {
    //   // Controlliamo se il campo comuneCoinvolto è abilitato
    //   if (this.form.get('comuneCoinvolto').enabled) {
    //     // Se il campo è abilitato, allora controllo che il valore sia diverso da null
    //     if (value?.comuneCoinvolto) {
    //       // Se il valore non risulta essere null, allora lo pusho
    //       comuni.push(value?.comuneCoinvolto);
    //     } else {
    //       // Se il valore risulta essere null lo assegno all'array(Cosi da non pushare un valore null)
    //       comuni = value?.comuneCoinvolto;
    //     }
    //   } else {
    //     // Se il campo è disabilitato mi prendo il valore che ho nel bean
    //     comuni = this.praticaBean.comuniCoinvolti;
    //   }
    //}


    this.setVariableError();

    let lista_tmp_vincoli: ModelAutorizzazionePaesaggistica.ListaVincoli[]=[];
    this.idTipoVincolo_map.forEach((value: ModelAutorizzazionePaesaggistica.ListaVincoliOggetto, key: string) => {
      let tmp_vincoli: ModelAutorizzazionePaesaggistica.ListaVincoli;
      let tmp_des_vincoli: string[]=[];
        if(value.vincolo_descrizione_selected != null && value.vincolo_descrizione_selected != undefined){
          for(let i = 0 ; i<value.vincolo_descrizione_selected.length; i++){
            tmp_des_vincoli[i]= value.vincolo_descrizione_selected[i];
          }
      }
        tmp_vincoli = {vincoli: key, vincoli_descrizione: tmp_des_vincoli};
        lista_tmp_vincoli.push(tmp_vincoli);
    })  

    
    // Setta l'oggetto pratica da salvare
    this.praticaDto = {
      id: this.praticaDto.id,
      idEnteDelegato: this.praticaDto.idEnteDelegato,
      oggetto: value.oggetto != '' ? value?.oggetto : null,
      richiedente: value.richiedente != '' ? value?.richiedente : null,
      dataPresentazioneRichiesta: value?.dataPresentazioneRichiesta,
      dataRilascioDiniegoAutpae: value?.dataRilascioDiniegoAutpae,
      numeroAutpae: value.numeroAutpae != '' ? value?.numeroAutpae : null,
      idTipoProcedura: value?.idTipoProcedura,
      rilasciatoProvincia: rilasciatoProvincia,
      idProvinciaCoinvolta: provincia,
      idTipoIntervento: value?.idTipoIntervento,      //idTipoVincolo: ''value?.idTipoVincolo!= '' ? value?.idTipoVincolo : null'',
      idTipoVincolo : null,
      //idCodiceDescrizioneVincolo: value?.idCodiceDescrizioneVincolo!= '' ? value?.idCodiceDescrizioneVincolo : null,
      idCodiceDescrizioneVincolo: null,
      vincoloMap: lista_tmp_vincoli,
      presenzaParereSop: value?.presenzaParereSop,
      presenzaParereCommLoc: value?.presenzaParereCommLoc,
      numeroProtocolloEnte:value?.numeroProtocolloEnte != '' ? value?.numeroProtocolloEnte : null,
      dataProtocolloEnte: value?.dataProtocolloEnte,
    };

    let newPraticaBean: ModelAutorizzazionePaesaggistica.PraticaBean = {
      praticaDto: this.praticaDto,
      entePratica: this.enteSelected,
      comuniCoinvolti: comuni,
      idAllegatiRimossi: this.allegatiRimossi,
      vincoli: value?.vincoli,
    };
    if (this.geoJson) {
      newPraticaBean.geoJson = JSON.stringify(this.geoJson);
    }

    // Fai la chiamata al BE per salvare
    this.praticaSvc
      .savePratica(
        newPraticaBean,
        this.mapFiles.get(TipoDocumento.AUTORIZZAZIONE),
        this.mapFiles.get(TipoDocumento.PARERE_SOP),
        this.mapFiles.get(TipoDocumento.PARERE_COMMLOC)
      )
      .subscribe(
        (res) => {
          console.log('res-->'+res);
          this.geometrieModificate = false;
          if (action === 'trasmetti') {
            this.reloadDati(this.validaFeBe.bind(this));
            this.invia = true;
          } else {
            this.reloadDati();
            this.alertsCenterService.showAlert({
              message: 'SALVATAGGIO AVVENUTO CORRETTAMENTE',
              type: 'success',
              autoClosable: false,
            });
          }
        },
        (error) => {
          console.log(error.error);
          // this.setAlert(
          //   'SALVATAGGIO NON ESEGUITO:' + error.error,
          //   'danger',
          //   false
          // );

          let messaggio = error.error;
          if (!messaggio) {
            messaggio = 'Errore durante il salvataggio della pratica!';
          }
          if (!(error.status === 0 || error.status >= 500)) {
            this.dialogSvc
              // Dialog che si aprirà solo nel caso in cui si sfori la lunghezza massima di un campo
              .openDialog(
                'SALVATAGGIO NON ESEGUITO:',
                messaggio,
                '800px',
                null,
                true,
                {
                  labelConferma: 'Chiudi',
                  cssClass: 'confirmNoMaxWidth',
                }
              )
              .subscribe();
          }
        }
      );
  }

  private validaFeBe() {
    // Effettua Validazioni FE PRIMA di chiamare la validazione BE**
    // DOPO aver cliccato trasmetti
    // this.form.get(formControlName).setValidators(Validators.required);
    // this.form.updateValueAndValidity();
    // ** Controlla il pannello a cui appartiene il campo errato e accendi il pannello relativo
    // ** Il campo errarto si deve accendere di rosso
    this.setValidatorForm();
    var validFe = this.validationFE();
    //  Faccio partire la chiamata al BE solo se la validation FE va a buon fine

    console.log('validFe--->'+validFe)
    if (validFe) {
      let _this = this;
      this.mostraDialogRiepilogo(_this.trasmetti.bind(this));
    }
  }

  private trasmetti() {
    this.dialogSvc
      .openDialog(
        'OPERAZIONE IRREVERSIBILE',
        'Desidera confermare la trasmissione della pratica?'
      )
      .subscribe((result) => {
        if (result) {
          this.praticaSvc.validatePratica(this.praticaDto.id).subscribe(
            (result) => {
              this.checkTrasmissione(result);
            },
            (error) => {
              this.setAlert('Trasmissione non avvenuta', 'danger', false);
            }
          );
        }
      });
  }

  private mostraDialogRiepilogo(callback: any) {
    this.setDatiRiepilogoPretrasmissione();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      datiPratica: this.datiRiepilogoPretrasmissione,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(
      RiepilogoPretrasmissioneDialogComponent,
      dialogConfig
    );
    let _this = this;
    dialogRef.afterClosed().subscribe((result) => {
      let res = result;
      if (callback && res.confermaTrasmissione) {
        callback();
      }
    });
  }

  setDatiRiepilogoPretrasmissione() {

    let geo = JSON.parse(this.praticaBean.geoJson);

    //Imposto l'oggetto TipoProcedura selezionato in base al valore (id) scelto
    this.tipoProcedura.find((el) => {
      if (el.id == this.praticaDto.idTipoProcedura)
        this.tipoProceduraSelected = el;
    });

    // Imposto l'oggetto rilasciatoProvincia selezionato in base al value (id) scelto
    let rilasciatoProvincia: string;

    this.tipoRilasciatoProv.find((el) => {
      if (el.id == this.praticaBean.praticaDto.rilasciatoProvincia)
        rilasciatoProvincia = el.descrizione;
    });

    //Imposto l'oggetto ComuniCoinvolti selezionati in base ai value (id) scelti
    let labelComuniCoinvolti: string[] = [];

    this.comuniCoinvolti.find((el) => {
      this.praticaBean.comuniCoinvolti.forEach((e) => {
        if (el.id == e)
          //this.comuniCoinvoltiSelected.push(el)
          labelComuniCoinvolti.push(el.descrizione);
      });
    });

    // Imposto l'oggetto provinciaCoinvolta selezionati in base al value (id) scelto
    let labelProvinciaCoinvolta: string;


    this.provinceCoinvolte.find((el) => {

      if ( el.value == this.praticaBean.praticaDto.idProvinciaCoinvolta.toString())
        labelProvinciaCoinvolta = el.label;
    });

   /* let vincoliSelezionati = this.form.get('vincoli').value;
    let vincoliDescription = [];
    if (
      this.vincolo &&
      vincoliSelezionati &&
      Array.isArray(vincoliSelezionati)
    ) {
      vincoliDescription = this.vincolo
        .filter((item) => (vincoliSelezionati as string[]).includes(item.value))
        .map((item) => item.label);
    }*/

    //dati intervento
    let intervento_miniDescrizione = [];
    let intervento_descrizioneEstesa = [];
    let append_des_estesa: string='';
    let append_des_mini: string='';

    for(let i=0; i<this.tipoInterventoSelected.length; i++){
      append_des_estesa = append_des_estesa + this.tipoInterventoSelected[i].descrizione + ' \n \n \r ';
      append_des_mini = append_des_mini + this.tipoInterventoSelected[i].minidescrizione + ' \n \n \r ';
    }
    intervento_descrizioneEstesa.push(append_des_estesa);
    intervento_miniDescrizione.push(append_des_mini);

    //dati vincolo
    let tipoVincolo = [];
    let minidescrizioneTipoVincolo = [];
    let descrizioneEstesaTipoVincolo = [];
    let append_tipoVincolo: string='';
    let append_des_mini_tipo_vincolo: string='';
    let append_des_estesa_tipo_vincolo:string='';

    this.idTipoVincolo_map.forEach((value: ModelAutorizzazionePaesaggistica.ListaVincoliOggetto, key: string) => {

    this.tipoVincolo.find((e) => {
        if(e.codice == value.vincolo){
          append_tipoVincolo = append_tipoVincolo + e.descrizione + ' \n \n \r ';
        }
    });

    for(let i=0; i<value.vincolo_descrizione_selected.length;i++){
      this.allDesTipiVincolo.find((e) => {
        if(e.codiceDescrizioneVincolo == value.vincolo_descrizione_selected[i]){
          append_des_mini_tipo_vincolo = append_des_mini_tipo_vincolo + e.codiceTipoVincolo + ' - ' + e.minidescrizione + ' \n \n \r ';
        }
      });
    }
    
      append_des_estesa_tipo_vincolo = append_des_estesa_tipo_vincolo + value.vincolo + ': \n \n \r ' + value.vincolo_descrizione_estesa;
    });

    tipoVincolo.push(append_tipoVincolo);
    minidescrizioneTipoVincolo.push(append_des_mini_tipo_vincolo);
    descrizioneEstesaTipoVincolo.push(append_des_estesa_tipo_vincolo);


    this.datiRiepilogoPretrasmissione = {
      praticaBean: this.praticaBean,
      tipoProcedura: this.tipoProceduraSelected,
      rilasciatoProvincia: rilasciatoProvincia,
      provinciaCoinvolta: labelProvinciaCoinvolta,
      comuniCoinvolti: labelComuniCoinvolti,
      tipoIntervento: intervento_miniDescrizione,
      tipoInterventoDescEstesa: intervento_descrizioneEstesa,
      //tipoVincolo: this.tipoVincoloSelected,
      //descTipoVincolo: this.descTipoVincoloSelected,
      tipoVincolo:tipoVincolo,
      minidescrizioneTipoVincolo:minidescrizioneTipoVincolo,
      descrizioneEstesaTipoVincolo: descrizioneEstesaTipoVincolo,
      presenzaMappa:
        geo != null && geo?.features && geo?.features.length > 0 ? 'SI' : 'NO'
      /*vincoli: vincoliDescription,*/
    };
  }

  /**
   * aggiorno lo stato degli allegati....
   */
  reloadDati(callback?: any) {
    this.praticaSvc.getPratica(this.idPratica).subscribe((response) => {
      this.praticaDto = response.praticaDto;
      this.praticaBean = response;
      this.enteSelected = response.entePratica;
      if (response.geoJson) {
        this.geoJson = JSON.parse(response.geoJson);
      }
      this.mappingStato();

      this.mapFiles.clear();

      this.fileAutorizzazione = this.praticaBean.autorizzazione != null;
      this.fileParereSop = this.praticaBean.parereSop != null;
      this.fileParereCommLoc = this.praticaBean.parereCommLoc != null;

      if (this.fileAutorizzazione)
        this.mapFilesCheck.set(
          TipoDocumento.AUTORIZZAZIONE,
          this.praticaBean.autorizzazione.id
        );
      if (this.fileParereSop)
        this.mapFilesCheck.set(
          TipoDocumento.PARERE_SOP,
          this.praticaBean.parereSop.id
        );
      if (this.fileParereCommLoc)
        this.mapFilesCheck.set(
          TipoDocumento.PARERE_COMMLOC,
          this.praticaBean.parereCommLoc.id
        );

      // Controllo se disabilitare in ingresso i campi di input file
      this.disableAllegati(
        this.praticaDto.presenzaParereSop,
        TipoDocumento.PARERE_SOP,
        false
      );
      this.disableAllegati(
        this.praticaDto.presenzaParereCommLoc,
        TipoDocumento.PARERE_COMMLOC,
        false
      );

      if (callback) {
        callback();
      }

      this.patchFormValoriIniziali(this.praticaDto, this.form, null);
    });
  }

  public askConfermaIndietro() {
    if (this.isModifica && this.form.touched) {
      this.dialogSvc
        .openDialog(
          'ATTENZIONE',
          'Le modifiche non risultano salvate, abbandonare?'
        )
        .subscribe((result) => {
          if (result) {
            this.onNavigate('lista-pratiche');
          }
        });
    } else {
      this.onNavigate('lista-pratiche');
    }
  }

  onNavigate(path: string, denominazione?: string) {
    switch (path) {
      default: {
        this.router.navigate([path]);
        break;
      }
    }
  }

  /**
   * impostazione valori nel form
   * @param pratica
   * @param form
   */
  private patchFormValoriIniziali(
    pratica: ModelAutorizzazionePaesaggistica.PraticaDto,
    form: FormGroup,
    callback: any
  ) {


    // let idProvinciaCoinvolta = pratica
    form.patchValue({
      ...pratica,
      idProvinciaCoinvolta: pratica.idProvinciaCoinvolta?.toString(),
    });

    // comuniCoinvolti viene convertito in array di string per matchare con le options che hanno value di tipo string
    let comuniCoinvolti = [];
    let vincoli = [];
    //if (this.comuniCoinvolti && this.comuniCoinvolti.length != 0)
    if (this.praticaBean.comuniCoinvolti) {
      // comuniCoinvolti = this.praticaBean.comuniCoinvolti.map((el) => el + '');
      comuniCoinvolti = this.praticaBean.comuniCoinvolti;
    }
    if (this.praticaBean.vincoli)
      vincoli = this.praticaBean.vincoli.map((el) => el + '');
    form.patchValue({ comuniCoinvolti });
    form.patchValue({ vincoli: vincoli });
    // risetto le selezioni in modo da far avvalorare le descrizioni estese
    if (pratica.idTipoProcedura) {
      this.chooseIntervento(
        { value: pratica.idTipoProcedura },
        this.chooseDescTipoIntervento.bind(this),
        true
      );
    }

    
    if (pratica.vincoloMap) {
      let tmp_vin: string[] = [];
      pratica.vincoloMap.forEach((vincolo) => {
        tmp_vin.push(vincolo.vincoli);
      });

      this.form.patchValue({ idTipoVincolo: tmp_vin});

     this.chooseTipoVincolo();

     let i = 0
     pratica.vincoloMap.forEach((vincolo) => {
       let tmp_desc: string[] = [];
       vincolo.vincoli_descrizione.forEach((desc) => {
       tmp_desc.push(desc);
       });

       this.chooseDescTipoVincolo(
         { value: tmp_desc },vincolo.vincoli);
       i++;
     });
    }

    //test_old
    /*
    if (pratica.idTipoVincolo) {
      this.chooseTipoVincolo();
    }
    if (pratica.idCodiceDescrizioneVincolo) {
      this.chooseDescTipoVincolo({ value: pratica.idCodiceDescrizioneVincolo }, '');
    }*/



    //Setto i valori di ID_VINCOLO
    // form.patchValue({
    //   vincoli: this.praticaBean?.vincoli,
    // });

    if(callback) {
      callback();
    }
    // this.setComuniCoinvolti();
    this.form.markAsUntouched();
  }

  /**
   * Set SELECT PROVINCE
   * @author Valentina Barba
   * @date 18/11/2021
   */
  setPronviciaCoinvolta(
    comuniEnte: ModelAutorizzazionePaesaggistica.DatiComuneDto[]
  ) {
    let nuovaProvincia: AutoCompleteDto[] = [];

    // Iteriamo i comuni i coinvolti
    comuniEnte.forEach((comune) => {
      // Itero le province coinvolte
      this.provinceCoinvolte.forEach((provincia) => {
        // Confrontiamo id_parent con l'id della provincia coinvolta
        // quelli che coincidono, dobbiamo salvarli
        if (comune.parentId === Number(provincia.value)) {
          // salvo in nuovaProvincia quelli che coincidono
          if (nuovaProvincia.length === 0) {
            // Se nuova provincia risulta vuoto, faccio il push perché non ci sono duplicati
            nuovaProvincia.push(provincia);
          } else {
            // Altrimenti

            // Inizializzo una variabile di controllo a false
            let isPresent = false;

            // Nuova provincia risulta già popolata, quindi c'è il rischio che vada a pushare un duplicato
            // Ciclo la nuova provincia per controllare che il nuovo valore non coicida con i precedenti
            nuovaProvincia.find((np) => {
              // Se uno dei valori già presenti coincide con il valore della nuovaProvincia
              if (np.value === provincia.value) {
                // Se il valore è già presente setto la variabile di controllo a true ed esco dal ciclo
                isPresent = true;
              }
            });
            // Se la variabile di controllo è uguale a false, allora posso pushare la provincia
            if (!isPresent) {
              nuovaProvincia.push(provincia);
            }
          }
        }
      });
    });

    // Inseriamo nell'oggetto solo le province che ci interessano
    this.provinceCoinvolte = nuovaProvincia;

    if (this.enteSelected.tipoEnteDelegato != 'B' && this.enteSelected.tipoEnteDelegato != 'C') {
      this.praticaDto.idProvinciaCoinvolta = Number(
        this.provinceCoinvolte[0].value
      );
      this.disableField('idProvinciaCoinvolta');
    }
  }

  setComuniCoinvolti(callback: any) {
    let value = this.form.value;
    this.comuniCoinvolti = [];

    switch (this.enteSelected.tipoEnteDelegato) {
      // D == Ente Delgato di Tipo Provincia
      case 'D': // Controllo il tipo "Rilasciato da parte della Provincia" scelto
        if (value?.rilasciatoProvincia === RilasciatoProvinciaEnum.ART45B) {
          this.comuniCoinvolti = this.comuniEnteDelegato;
          // NO Selezione multipla
          this.maxlength = 1;
          // TEST: Per Far scattare l'errore di Validazione BE
          // this.anagraficaEnteSvc
          //   .getAnagraficaEntiComuni(
          //     null,
          //     String(this.praticaDto.idProvinciaCoinvolta),
          //     this.praticaDto.id
          //   )
          //   .subscribe((result) => {
          //     this.comuniProvincia = result;
          //     this.comuniCoinvolti = this.comuniProvincia;
          //   });
        } else if (
          value?.rilasciatoProvincia === RilasciatoProvinciaEnum.ART45A
        ) {
          if (this.comuniProvincia.length === 0) {
            //Chiamata con idProvincia
            this.anagraficaEnteSvc
              .getAnagraficaEntiComuni(
                null,
                String(this.praticaDto.idProvinciaCoinvolta),
                this.praticaDto.id
              )
              .subscribe((result) => {
                this.comuniProvincia = result;
                this.comuniCoinvolti = this.comuniProvincia;
              });
          } else {
            this.comuniCoinvolti = this.comuniProvincia;
          }
          // Selezione multipla
          this.maxlength = null;
        }
        console.log(this.maxlength);
        break;

      // A1 == Ente Delgato di Tipo Comune delegato
      case 'A1':
        this.comuniCoinvolti = this.comuniEnteDelegato;
        if (
          this.praticaBean.comuniCoinvolti == null ||
          this.praticaBean.comuniCoinvolti?.length == 0
        ) {
          this.praticaBean.comuniCoinvolti.push(this.comuniCoinvolti[0].id);
        }
        // Selezione multipla
        this.maxlength = null;
        this.disableField('comuniCoinvolti');
        break;

      // A2 == Ente Delgato di tipo Forma associativa o Cooperazione
      case 'A2':
        this.comuniCoinvolti = this.comuniEnteDelegato;
        // Selezione multipla
        this.maxlength = null;
        break;

      // B == Ente Delgato di Tipo Ente Parco Regionale
      case 'B': //Chiamata con idProvincia
        var idProvincia = value?.idProvinciaCoinvolta
          ? value?.idProvinciaCoinvolta
          : this.praticaDto.idProvinciaCoinvolta;
        if (idProvincia) {
          //Filtra l'array col parent_id
          this.comuniEnteDelegato.forEach((comune) => {
            if (comune.parentId == idProvincia)
              this.comuniCoinvolti.push(comune);
          });
        }
        // // NO Selezione multipla
        // this.maxlength = 1;

        // Selezione multipla
        this.maxlength = null;
        break;

        case 'C':
        var idProvincia = value?.idProvinciaCoinvolta
          ? value?.idProvinciaCoinvolta
          : this.praticaDto.idProvinciaCoinvolta;


        if (idProvincia) {
          //Filtra l'array col parent_id
          this.comuniEnteDelegato.forEach((comune) => {
            if (comune.parentId == idProvincia)
              this.comuniCoinvolti.push(comune);
          });
        }
        // // NO Selezione multipla
        // this.maxlength = 1;

        // Selezione multipla
        this.maxlength = null;
        break;
    }

    if (callback) {
      callback(this.praticaDto, this.form, this.setComuniCoinvolti.bind(this));
    }
  }

  public downloadFile(event: any) {
    this.dialogSvc
      .openDialog('CONFERMA OPERAZIONE', "Desidera scaricare l'allegato? ")
      .subscribe((result) => {
        if (result) {
          this.praticaSvc
            .downloadAllegatoPratica(this.idPratica, event.id)
            .subscribe((result) => {
              if (result.ok) {
                downloadFile(result.body, event.fileName);
              }
            });
        }
      });
  }

  public deleteFile(event: any, documento: string) {
    if (event?.id) {
      this.allegatiRimossi.push(event.id);
    }

    this.mapFilesCheck.delete(documento);
    this.mapFiles.delete(documento);

    if (this.invia) {
      this.setValidationAllegati(documento);
    }
  }
  /**
   * eventualmente spengo la validazione...
   * @param event
   * @param documento
   */
  public selectFile(event: any, documento: string) {
    this.mapFiles.delete(documento);

    this.mapFiles.set(documento, event);

    this.mapFilesCheck.delete(documento);

    this.mapFilesCheck.set(documento, event.id);

    if (documento == TipoDocumento.PARERE_SOP) {
      this.validationFilePareSop = null;
    } else if (documento == TipoDocumento.PARERE_COMMLOC) {
      this.validationFilePareCommLoc = null;
    } else if (documento == TipoDocumento.AUTORIZZAZIONE) {
      this.validationFileAutorizzazione = null;
    }
  }

  public disableAllegati($event, documento: string, validate?: boolean) {
    var disabeleAllegato = false;

    if ($event === 'NO' || $event === 'IA') {
      disabeleAllegato = true;
    }

    if (documento === TipoDocumento.PARERE_SOP)
      this.disabledFileParereSop = disabeleAllegato;
    else if (documento === TipoDocumento.PARERE_COMMLOC)
      this.disabledFileParereCommLoc = disabeleAllegato;

    // Se la variabile "invia" risulta settata a "true" allora aggiorno le validazioni
    if (this.invia && validate) {
      this.setValidationAllegati(documento);
    }
  }

  private disableEnableField(field: string) {
    if (this.form.get(field).enabled) this.form.get(field).disable();
    else this.form.get(field).enable();
  }

  private disableField(field: string) {
    this.form.get(field).disable();
  }

  /**
   * Metodo per mettere a null il valore di un campo
   * @author Valentina Barba
   * @date 22/11/2021
   */
  patchValueField(field: string, array: boolean) {
    if (!array) this.form.patchValue({ [field]: null });
    else this.form.patchValue({ [field]: [] });
  }
  /*----- SEZIONE VALIDAZIONE FE ----- */

  private setValidatorForm() {
    // Assegnano a tutti campi a cui è richiesto i validators
    this.form.get('idEnteDelegato').setValidators(Validators.required);
    this.form.get('idEnteDelegato').updateValueAndValidity();

    this.form
      .get('oggetto')
      .setValidators([
        Validators.required,
        this.customValidator.noWhitespaceValidator(this.form.get('oggetto')),
      ]);
    this.form.get('oggetto').updateValueAndValidity();

    this.form
      .get('richiedente')
      .setValidators([
        Validators.required,
        this.customValidator.noWhitespaceValidator(
          this.form.get('richiedente')
        ),
      ]);
    this.form.get('richiedente').updateValueAndValidity();

    this.form.setValidators([
      this.customValidator.dateLessThan(
        'dataRilascioDiniegoAutpae',
        'dataProtocolloEnte'
      ),
    ]);

    this.form
      .get('dataRilascioDiniegoAutpae')
      .setValidators(Validators.required);
    this.form.get('dataRilascioDiniegoAutpae').updateValueAndValidity();

    this.form
      .get('numeroAutpae')
      .setValidators([
        Validators.required,
        this.customValidator.noWhitespaceValidator(
          this.form.get('numeroAutpae')
        ),
      ]);
    this.form.get('numeroAutpae').updateValueAndValidity();

    this.form.get('idTipoProcedura').setValidators(Validators.required);
    this.form.get('idTipoProcedura').updateValueAndValidity();

    this.form.get('rilasciatoProvincia').setValidators(Validators.required);
    this.form.get('rilasciatoProvincia').updateValueAndValidity();

    this.form.get('idProvinciaCoinvolta').setValidators(Validators.required);
    this.form.get('idProvinciaCoinvolta').updateValueAndValidity();

    this.form.get('comuniCoinvolti').setValidators(Validators.required);
    this.form.get('comuniCoinvolti').updateValueAndValidity();

    /*this.form
      .get('idTipoIntervento')
      .setValidators(
        this.customValidator.requiredDependsOn(
          this.form.get('idTipoProcedura'),
          ['APO', 'APS']
        )
      );
    this.form.get('idTipoIntervento').updateValueAndValidity();

    this.form.get('idTipoVincolo').setValidators(Validators.required);
    this.form.get('idTipoVincolo').updateValueAndValidity();

    this.form
      .get('idCodiceDescrizioneVincolo')
      .setValidators(Validators.required);
    this.form.get('idCodiceDescrizioneVincolo').updateValueAndValidity();
    */

    this.form.get('presenzaParereSop').setValidators(Validators.required);
    this.form.get('presenzaParereSop').updateValueAndValidity();

    this.form.get('presenzaParereCommLoc').setValidators(Validators.required);
    this.form.get('presenzaParereCommLoc').updateValueAndValidity();

    this.form.get('dataProtocolloEnte').setValidators(Validators.required);
    this.form.get('dataProtocolloEnte').updateValueAndValidity();

    this.form
      .get('numeroProtocolloEnte')
      .setValidators([
        Validators.required,
        this.customValidator.noWhitespaceValidator(
          this.form.get('numeroProtocolloEnte')
        ),
      ]);
    this.form.get('numeroProtocolloEnte').updateValueAndValidity();

    console.log(
      'required Data Protocoloo Ente ',
      this.form.get('dataProtocolloEnte').hasError('required')
    );
  }

  validationFE() {
    // Controllo se la form è valida: Se No setto i messaggi di errore e ritorno false, se Sì ritorno true
    if (!this.form.valid) {
      let group = this.form.controls;
      this.enable = true;
      console.log(this.form.errors);

      Object.keys(group).forEach((key) => {
        if (this.form.get(key).invalid) {
          var pannel: Pannelli = null;

          this.formConfig.find((f) => {
            if (f.controlName == key) {
              pannel = f.pannel;
            }
          });

          // Controllo il pannello di appartenenza del campo e accendo il pannello relativo
          switch (pannel) {
            case Pannelli.INFORMAZIONI_GENERALI:
              this.tabInfo = true;
              break;
            case Pannelli.INTERVENTO:
              this.tabIntervento = true;
              break;
            case Pannelli.VINCOLI:
              this.tabVincoli = true;
              break;
            case Pannelli.ALLEGATI:
              this.tabAllegati = true;
              break;
            default:
              break;
          }
        }
      });

      // Setta Dialog messaggi di errore Dialog
      let message: string =
        'Per trasmettere la pratica tutti i campi devono essere corretti! ';
      this.setAlert(message, 'danger', false);
    }

    this.setValidationAllegati(null);

    /*Se la form non è valida o se durante la validazioneAllegati sono stati impostati
      degli errori ritorna false altrimenti true */
    return !this.form.valid || this.tabAllegati ? false : true;
  }

  setValidationAllegati(documento: string) {
    // Ritorna true se è presente il file di autorizzazione altrimenti false

    this.fileAutorizzazione = this.mapFilesCheck.has(
      TipoDocumento.AUTORIZZAZIONE
    );
    this.fileParereSop = this.mapFilesCheck.has(TipoDocumento.PARERE_SOP);
    this.fileParereCommLoc = this.mapFilesCheck.has(
      TipoDocumento.PARERE_COMMLOC
    );

    // if (documento === TipoDocumento.AUTORIZZAZIONE) {
    //   this.fileAutorizzazione = this.mapFilesCheck.has(
    //     TipoDocumento.AUTORIZZAZIONE
    //   );
    // }
    // if (documento === TipoDocumento.PARERE_SOP) {
    //   this.fileParereSop = this.mapFilesCheck.has(TipoDocumento.PARERE_SOP);
    // }
    // if (documento === TipoDocumento.PARERE_COMMLOC) {
    //   this.fileParereCommLoc = this.mapFilesCheck.has(
    //     TipoDocumento.PARERE_COMMLOC
    //   );
    // }
    // }

    /*Validazione allegato autorizzazione */
    if (!this.fileAutorizzazione) {
      this.validationFileAutorizzazione = { required: true };
      this.tabAllegati = true;
      this.enable = true;
    }

    /*Validazione allegato Parere Sopraintendenza */
    // Se ParereSop è Sì il campo input per il fileParereSop non sarà disabilitato quindi dovrà essere inserito il file opportuno
    if (
      this.form.get('presenzaParereSop').value == 'SI' &&
      !this.fileParereSop
    ) {
      this.enable = true;
      this.tabAllegati = true;
      this.validationFilePareSop = { required: true };
    } else if (
      this.form.get('presenzaParereSop').value == 'NO' &&
      this.fileParereSop
    ) {
      this.enable = true;
      this.tabAllegati = true;
      this.validationFilePareSop = { notRequired: true };
    } else {
      this.validationFilePareSop = null;
    }

    /*Validazione allegato Parere Commissione Locale */
    // Se ParereCommLoc è Sì il campo input per il fileParereCommLoc non sarà disabilitato quindi dovrà essere inserito il file opportuno
    if (
      this.form.get('presenzaParereCommLoc').value == 'SI' &&
      !this.fileParereCommLoc
    ) {
      this.enable = true;
      this.tabAllegati = true;
      this.validationFilePareCommLoc = { required: true };
    } else if (
      this.form.get('presenzaParereCommLoc').value == 'NO' &&
      this.fileParereCommLoc
    ) {
      this.enable = true;
      this.tabAllegati = true;
      this.validationFilePareCommLoc = { notRequired: true };
    } else {
      this.validationFilePareCommLoc = null;
    }
  }

  setVariableError() {
    this.tabInfo = false;
    this.tabIntervento = false;
    this.tabVincoli = false;
    this.tabAllegati = false;
    this.invia = false;

    this.validationFileAutorizzazione = null;
    this.validationFilePareSop = null;
    this.validationFilePareCommLoc = null;
    this.enable = false;

    // Itero l'array formConfig per recuperare il nome del formControl
    this.formConfig.forEach((element) => {
      //  Svuoto la lista dei validatori di ogni formControl
      var formControlName: any = element.controlName;
      this.form.get(formControlName).clearValidators();
      this.form.get(formControlName).updateValueAndValidity();
    });
    this.form.clearValidators();

    this.form.updateValueAndValidity();
  }

  /*----- esito trasmissione con validazione BE ----- */
  private checkTrasmissione(response: any) {
    if (response == null) {
      this.setAlert('Errore durante la trasmissione !!!', 'danger', false);
    } else {
      if (response.isValid) {
        this.dialogSvc
          .openDialog(
            'ESITO TRASMISSIONE',
            'Trasmissione avvenuta con successo',
            '',
            null,
            true,
            {
              labelConferma: 'Chiudi',
              cssClass: 'confirmNoMaxWidth',
            }
          )
          .subscribe((res) => {
            this.router.navigate(['/dettaglio-pratica/' + this.idPratica]);
          });
      } else {
        // Setta Dialog messaggi di errore Da BE
        this.enable = true;
        this.tabInfo = false;
        this.tabIntervento = false;
        this.tabVincoli = false;
        this.tabAllegati = false;
        let message: string =
          '<p>Di seguito gli errori nei relativi pannelli:<p>';
        message += '<ul>';
        if (response.mapPanelFieldsInvalid.INFORMAZIONI_GENERALI?.length) {
          this.tabInfo = true;
          message = this.buildMessageList(
            'INFORMAZIONI GENERALI',
            response.mapPanelFieldsInvalid.INFORMAZIONI_GENERALI,
            message
          );
        }
        if (response.mapPanelFieldsInvalid.INTERVENTO?.length) {
          this.tabIntervento = true;
          message = this.buildMessageList(
            'INTERVENTO',
            response.mapPanelFieldsInvalid.INTERVENTO,
            message
          );
        }
        if (response.mapPanelFieldsInvalid.VINCOLI?.length) {
          this.tabVincoli = true;
          message = this.buildMessageList(
            'VINCOLI',
            response.mapPanelFieldsInvalid.VINCOLI,
            message
          );
        }
        if (response.mapPanelFieldsInvalid.ALLEGATI?.length) {
          this.tabAllegati = true;
          message = this.buildMessageList(
            'ALLEGATI',
            response.mapPanelFieldsInvalid.ALLEGATI,
            message
          );
        }
        message += '</ul>';
        this.dialogSvc
          .openDialog('Dati non corretti', message, '800px', null, true, {
            labelConferma: 'Chiudi',
            cssClass: 'confirmNoMaxWidth',
          })
          .subscribe();
      }
    }
  }

  private buildMessageList(
    nomePannello: string,
    arrayMessError: string[],
    firstPart: string
  ): string {
    let message = firstPart;
    if (arrayMessError) {
      message += '<li><b>' + nomePannello + '</b></li>';
      message += '<ul>';
      arrayMessError.forEach((errorMess) => {
        message += '<li>' + errorMess + '</li>';
      });
      message += '</ul>';
    }
    return message;
  }

  private setAlert(message: string, type: any, autoClosable: boolean) {
    this.alertsCenterService.showAlert({
      message: message,
      type: type,
      autoClosable: autoClosable,
    });
  }

  // openMappa() {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.data = {
  //     praticaDto: this.praticaDto,
  //     entities: fromGeoJSONToEntities(this.geoJson),
  //     isDrawModeEnabled: this.isModifica,
  //   };
  //   dialogConfig.disableClose = true;
  //   const dialogRef = this.dialog.open(
  //     LocalizzazionePraticaComponent,
  //     dialogConfig
  //   );
  //   let _this = this;
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(result);
  //     let res = result;
  //     let _geo = toGeoJson(result.entities, this.praticaDto.id);
  //     if (!isEqual(_geo, this.geoJson)) this.geometrieModificate = true;
  //     this.geoJson = toGeoJson(result.entities, this.praticaDto.id);
  //   });
  // }

  public downloadShape(): void {
    if (!this.geometrieModificate) {
      this.praticaSvc.downloadShapeById(this.praticaDto.id).subscribe(
        (result) => {
          if (result.ok) downloadFile(result.body, 'shapefile.zip');
        },
        (error) => {
          this.alertsCenterService.showAlert({
            message: `Errore download shapefile`,
            type: 'danger',
            autoClosable: false,
          });
        }
      );
    } else {
      let m =
        'Le geometrie risultano non allineate ed è necessario effettuare un salvataggio prima di scaricare lo shape file';
      this.dialogSvc
        .openDialog('Attenzione', m, '800px', null, true, {
          labelConferma: 'Chiudi',
          cssClass: 'confirmNoMaxWidth',
        })
        .subscribe();
    }
  }

  public downloadGeoJson(): void {
    if (!this.geometrieModificate) {
      this.praticaSvc.downloadGeoJsonById(this.praticaDto.id).subscribe(
        (result) => {
          if (result.ok) downloadFile(result.body, 'geojson.json');
        },
        (error) => {
          this.alertsCenterService.showAlert({
            message: `Errore download geojson`,
            type: 'danger',
            autoClosable: false,
          });
        }
      );
    } else {
      let m =
        'Le geometrie risultano non allineate ed è necessario effettuare un salvataggio prima di scaricare il geojson';
      this.dialogSvc
        .openDialog('Attenzione', m, '800px', null, true, {
          labelConferma: 'Chiudi',
          cssClass: 'confirmNoMaxWidth',
        })
        .subscribe();
    }
  }

  /**
   *
   * @param comuniCoinvolti sente il cambiamento neel formcontrol comuniCoinvolti
   */
  onChangeComuniCoinvolti(comuniCoinvolti: number[]) {
    this.popolaVincoli(comuniCoinvolti);
  }

  popolaVincoli(comuniCoinvolti: number[]) {
    console.log('comuni: {}', comuniCoinvolti);
    if (comuniCoinvolti && this.allVincoliPratica) {
      let vincoliSuComuni = this.allVincoliPratica
        .filter((el) => comuniCoinvolti.includes(el.idComune))
        .map((el) => {
          return { value: el.codice, label: el.descrizione };
        });
      let vincoliSet: AutoCompleteDto[] = [];
      //tolgo eventuali duplicati (vincoli su più comuni)
      vincoliSuComuni.forEach((el) => {
        if (!vincoliSet.includes(el)) {
          vincoliSet.push(el);
        }
      });
      this.vincolo = vincoliSet;
      //se i nuovi vincoli non includono i vecchi, vengono rimossi
    } else {
      //svuoto
      this.vincolo = [];
    }
    let vincoliSelezionati = this.form.get('vincoli').value;
    if (vincoliSelezionati && Array.isArray(vincoliSelezionati)) {
      let newSelezione = [];
      vincoliSelezionati.forEach((vincolo) => {
        if (this.vincolo.map((el) => el.value).includes(vincolo)) {
          newSelezione.push(vincolo);
        }
      });
      this.form.get('vincoli').patchValue(newSelezione);
    }
  }

  /*checkFile(file: any){
    if(this.maxSizeFile!=null&&file.size>this.maxSizeFile){
      this.dialogSvc
      // Dialog che si aprirà solo nel caso in cui si sfori la lunghezza massima di un campo
      .openDialog(
        'UPLOAD NON ESEGUITO:',
        'Non è possibile caricare un file maggiore di ' + (this.maxSizeFile/1000000) + 'MB',
        '800px',
        null,
        true,
        {
          labelConferma: 'Chiudi',
          cssClass: 'confirmNoMaxWidth',
        }
      )
      .subscribe();
      return false;
    }
    else{
      return true
    }
  }*/

  /**
   * al cambio del valore rilasciato provincia devo aggiornare la multiselect dei comunicoinvolti
   *
   * @param event
   */
  onChangeRilasciatoProvincia(event: any) {
    this.setComuniCoinvolti(null);
    this.patchValueField('comuniCoinvolti', true);
  }

  onValueChange(
    fn: (event?: any, ...events: any) => void,
    fieldElement?: { field: string; array: boolean }[],
    event?: any, id?:string
  ) {

    let bindedFn = fn.bind(this);
  
    if (fn && event) {
      bindedFn(event,id);
    } else if (fn) {
      bindedFn();
    }

    fieldElement?.forEach((e) => {
      this.patchValueField(e.field, e.array);
    });
  }

  modificaMappa(event: any) {
    console.log(event);
    let _geo = toGeoJson(event.entities, this.praticaDto.id);
    if (!isEqual(_geo, this.geoJson)) this.geometrieModificate = true;
    this.geoJson = toGeoJson(event.entities, this.praticaDto.id);
  }

  /**
   * @description Confronto lo stato pratica dto con il value dell'array statiOption e salvo la label corrispondente
   * @author Valentina Barba
   * @date 10/12/2021
   */

  mappingStato() {
    statiOptions().find((stato) => {
      if (stato.value === this.praticaDto.statoPratica) {
        this.labelStatoPratica = stato.label;
      }
    });
  }

  getDescrizioneAllegatoAutorizzazione() {
    const idTipoProcedura = this.form.get('idTipoProcedura').value;
    
    if (['DAP','DACP','AII'].includes(idTipoProcedura)) {
      return this.ts.instant("dettaglio.allegati.provvedimento");
    } else {
      return this.ts.instant("dettaglio.allegati.autpae");
    }
  }
}
