<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)" [ngClass]="{scrolled: isScrolled}">
    <a routerLink="{{'/myintranet/'+selectedIpa+'/admin/'}}" class="al-logo clearfix">
        <img src="/myintranet/client/application/assets/images/dir_pian_ter.png"/></a>
    <a href [ngClass]="{'must-be-visible':isMenuCollapsed}" (click)="toggleMenu()" class="collapse-menu-link icon-hamburger"></a><!-- logo-myintranet-neg.svg -->

    <div class="search">
        <form (ngSubmit)="searchContent()">
            <i class="ion-ios-search-strong" ng-click="startSearch()"></i>
            <input [(ngModel)]="searchInputModel" id="searchInput" [ngModelOptions]="{standalone: true}" type="text" placeholder="Cerca in Myintranet">
        </form>
    </div>

    <div class="user-profile clearfix">
        <div class="dropdown al-user-profile" [ngClass]="{'show': userDropdown}">
            <a class="profile-toggle-link dropdown-toggle" id="user-profile-dd" (click)="userDropdown = !userDropdown" aria-expanded="{userDropdown}">
            <span class="icon-user user-profile-dropdown header-icons" [title]="'Utente autenticato: '+userFullname"></span>
            </a>
            <ul class="dropdown-menu top-dropdown-menu profile-dropdown" aria-labelledby="user-profile-dd">
                <li class="dropdown-item">
                    <a>
                        <i class="fa fa-user"></i>
                        {{userFullname}}
                    </a>
                </li>
                <li class="dropdown-item"><a href="/myintranet/documentation/"><i class="fa fa-cog"></i>Documentazione</a></li>
                <li class="dropdown-item">
                    <a class="signout" href="javascript:void(0)" (click)="logout(logoutForm)"><i class='fa fa-power-off'></i>Logout</a>
                    <form [attr.action]="redirectLogout" method="POST" #logoutForm></form>
            </ul>
        </div>
    </div>
    <div #ipaDropdown class="ipa-dropdown" *ngIf="authorizedIpas">
        <span>Ente:</span>
        <!-- <p-dropdown [options]="authorizedIpas" [ngModel]="selectedIpa" (onChange)="onIpaChange($event.value)" [autoWidth]="false"></p-dropdown> -->
    </div>
</div>
