import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { SimpleTableHeader } from 'src/app/shared/components/simple-table/SimpleTableConfig';

export class DatiRiepilogoPretrasmissione {
  praticaBean?: ModelAutorizzazionePaesaggistica.PraticaBean;
  tipoProcedura?: ModelAutorizzazionePaesaggistica.TipoProcedura;
  rilasciatoProvincia?: string;
  provinciaCoinvolta?: string;
  comuniCoinvolti?: string[];
  //tipoIntervento?: ModelAutorizzazionePaesaggistica.TipoInterventoDto[]=[];
  tipoIntervento?: string[];
  tipoInterventoDescEstesa?: string[];
  //tipoVincolo?: ModelAutorizzazionePaesaggistica.TipoVincolo;
  //descTipoVincolo?: ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo;
  presenzaMappa?: string;
  //vincoli?: string[];
  tipoVincolo?:string[];
  minidescrizioneTipoVincolo?:string[];
  descrizioneEstesaTipoVincolo?:  string[];
}

@Component({
  selector: 'app-riepilogo-pretrasmissione-dialog',
  templateUrl: './riepilogo-pretrasmissione-dialog.component.html',
  styleUrls: ['./riepilogo-pretrasmissione-dialog.component.scss'],
})
export class RiepilogoPretrasmissioneDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RiepilogoPretrasmissioneDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      datiPratica: DatiRiepilogoPretrasmissione;
    },
    private alertsCenterService: AlertsCenterService
  ) {}

  displayedColumns: string[] = ['fieldName', 'value'];


  public fieldsDati: Array<any> = [
    {
      fieldName: 'riepilogo.ente',
      value: this.data.datiPratica.praticaBean.entePratica.descrizione,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.oggetto',
      value: this.data.datiPratica.praticaBean.praticaDto.oggetto,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.tipoProc',
      value: this.data.datiPratica.tipoProcedura.descrizione,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.richiedente',
      value: this.data.datiPratica.praticaBean.praticaDto.richiedente,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.dataPres',
      value:
        this.data.datiPratica.praticaBean.praticaDto.dataPresentazioneRichiesta,
      type: 'date',
    },
    {
      fieldName: 'riepilogo.numero',
      value: this.data.datiPratica.praticaBean.praticaDto.numeroAutpae,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.dataRilDin',
      value:
        this.data.datiPratica.praticaBean.praticaDto.dataRilascioDiniegoAutpae,
      type: 'date',
    },
    {
      fieldName: 'riepilogo.numProtocolloEnte',
      value: this.data.datiPratica.praticaBean.praticaDto.numeroProtocolloEnte,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.dataProtocolloEnte',
      value: this.data.datiPratica.praticaBean.praticaDto.dataProtocolloEnte,
      type: 'date',
    },
    {
      fieldName: 'riepilogo.rilasciatoProv',
      value: this.data.datiPratica.rilasciatoProvincia,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.provincia',
      value: this.data.datiPratica.provinciaCoinvolta,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.comune',
      value: this.data.datiPratica.comuniCoinvolti,
      type: 'array',
    },
    {
      fieldName: 'riepilogo.tipoIntervento',
      value: this.data.datiPratica.tipoIntervento,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.descTipoIntervento',
      value: this.data.datiPratica.tipoInterventoDescEstesa,
      type: 'text',
    },
    /*{
      fieldName: 'riepilogo.tipoVincolo',
      value: this.data.datiPratica.tipoVincolo?.descrizione,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.descTipoVincolo',
      value: this.data.datiPratica.descTipoVincolo?.minidescrizione,
      type: 'array',
    },
    {
      fieldName: 'riepilogo.descExTipoVincolo',
      value: this.data.datiPratica.descTipoVincolo?.descrizione,
      type: 'array',
    },*/
    {
      fieldName: 'riepilogo.tipoVincolo',
      value: this.data.datiPratica.tipoVincolo,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.descTipoVincolo',
      value: this.data.datiPratica.minidescrizioneTipoVincolo,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.descExTipoVincolo',
      value: this.data.datiPratica.descrizioneEstesaTipoVincolo,
      type: 'text',
    },
    /*{
      fieldName: 'riepilogo.idVincolo',
      value: this.data.datiPratica.vincoli,
      type: 'array',
    },*/
   /* {
      fieldName: 'riepilogo.autorizazionePaesaggistica',
      value: this.data.datiPratica.praticaBean.autorizzazione.fileName,
      type: 'text',
    },*/
    {
      fieldName: 'riepilogo.presenzaParereSoprintendenza',
      value: this.data.datiPratica.praticaBean.praticaDto.presenzaParereSop,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.parereSoprintendenza',
      value: this.data.datiPratica.praticaBean.parereSop?.fileName,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.presenzaParereCommissione',
      value: this.data.datiPratica.praticaBean.praticaDto.presenzaParereCommLoc,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.parereCommissione',
      value: this.data.datiPratica.praticaBean.parereCommLoc?.fileName,
      type: 'text',
    },
    {
      fieldName: 'riepilogo.presenzaMappa',
      value: this.data.datiPratica.presenzaMappa,
      type: 'text',
    },
  ];

  dataSource = this.fieldsDati;

  ngOnInit() {
  }

  public trasmetti() {
    this.dialogRef.close({ confermaTrasmissione: true });
  }
}
