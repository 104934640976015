import { ReportComponent } from './components/report/report.component';
import { ListaEntiPublicComponent } from './components/lista-enti-public/lista-enti-public.component';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
//import { MrfFormModule } from '@eng/morfeo';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertsCenterComponent } from './components/alerts-center/alerts-center/alerts-center.component';

import { CambioRuoloEnteComponent } from './components/cambio-ruolo-ente-dialog/cambio-ruolo-ente-dialog';

import { DettaglioPraticaComponent } from './components/dettaglio-pratica/dettaglio-pratica.component';
import { NuovaPraticaComponent } from './components/nuova-pratica/nuova-pratica.component';
import { FooterComponent } from './components/footer/footer.component';
import { GenericDialogContentComponent } from './components/generic-dialog-content/generic-dialog-content.component';
// Components
import { HeaderComponent } from './components/header/header.component';
import { ListaPratichePublicComponent } from './components/lista-pratiche-public/lista-pratiche-public.component';
import { ListaPraticheComponent } from './components/lista-pratiche/lista-pratiche.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LoaderInterceptor } from './core/spinner/loader.interceptor';
import { LoaderService } from './core/spinner/loader.service';
import { APP_DATE_FORMATS } from './shared/components/customDateFormat/pickDateAdapter';
import { SharedModule } from './shared/shared.module';
//Custom Widgets
import { getItaPaginatorIntl } from './translations/custom-paginator';
import { LocalizzazionePraticaComponent } from './components/localizzazione-pratica/localizzazione-pratica.component';
import { MappaPraticheComponent } from './components/mappa-pratiche/mappa-pratiche.component';
import { RiepilogoPretrasmissioneDialogComponent } from './components/riepilogo-pretrasmissione-dialog/riepilogo-pretrasmissione-dialog.component';
import { TabMappaComponent } from './components/tab-mappa/tab-mappa.component';
import { SideMenuPublicComponent } from './components/side-menu-public/side-menu-public.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SideMenuComponent,
    AlertsCenterComponent,
    GenericDialogContentComponent,
    LoginComponent,
    LogoutComponent,
    CambioRuoloEnteComponent,
    ListaPraticheComponent,
    DettaglioPraticaComponent,
    NuovaPraticaComponent,
    ListaPratichePublicComponent,
    ListaEntiPublicComponent,
    LocalizzazionePraticaComponent,
    MappaPraticheComponent,
    ReportComponent,
    RiepilogoPretrasmissioneDialogComponent,
    TabMappaComponent,
    SideMenuPublicComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    //MatTooltipModule,
    BrowserAnimationsModule,
    //MrfFormModule,
    LeafletModule,
    CookieModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MatPaginatorIntl, useValue: getItaPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
/* export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
} */
export function httpTranslateLoader(http: HttpClient)
{
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
