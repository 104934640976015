import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, HostListener, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TruncatePipe } from '../../pipes/truncate-pipe/truncate.pipe';
import { Toolbar, ToolbarEvent } from './../toolbar/toolbar';
import { ActionEventResult, PageSortEvent, SimpleActionLink, SimpleTableHeader } from './SimpleTableConfig';

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements AfterViewInit, OnDestroy, OnChanges
{
  @Input("data") data: Array<any> = [];
  @Input("id") id: any;
  @Input("header") header: Array<SimpleTableHeader> = [];
  @Input("action") action: Array<SimpleActionLink> = [];
  @Input("enableAction") enableAction: boolean = false;
  @Input("pageNumberInit") pageNumberInit: number=1; //pagina default 1-based
  @Input("pageSizeOptions") pageSizeOptions: Array<number> = [3, 5, 10];
  @Input("totalRecords") totalRecords: number = 0;
  @Input("defaultPageSize") set _defaultPageSize(dps: number) { this.defaultPageSize = dps; }
  
  @Output("onAction") actionEmitter: EventEmitter<ActionEventResult> = new EventEmitter();
  @Output("pageSortChange") psEmitter: EventEmitter<PageSortEvent> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public defaultPageSize: number = 10;
  public openToggle: any;
  public toolbar: {[key: string]: Toolbar} = {};

  private unsubscribe$: Subject<void> = new Subject();
  private truncatePipe: TruncatePipe;

  constructor() { 
    this.truncatePipe=new TruncatePipe();
  }

  public ngAfterViewInit(): void
  {
    this.sort.sortChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.paginator.pageIndex = 0);
    this.sort.sortChange.pipe(tap(() => this.notifySortPagingChange()), takeUntil(this.unsubscribe$)).subscribe();
  }

  public ngOnChanges(changes: any): void
  {
    for(let d of this.data)
      this.toolbar[d[this.id]] = this.generateToolBar(d);
  }

  public ngOnDestroy(): void
  {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //rimane in ascolto: se il click non è fatto sull'icona chiude il menù
  @HostListener('document:click', ['$event'])
  documentClick(event)
  {
    if (event.srcElement.innerText !== 'more_horiz') this.openToggle = 0;
  }

  public notifySortPagingChange(): void
  {
    let event: PageSortEvent = {
      sortBy: this.sort.active,
      sortType: this.sort.direction.toUpperCase() == 'DESC' ? 'DESC' : 'ASC',
      page: (this.paginator.pageIndex + 1),
      limit: this.paginator.pageSize
    }
    console.log(event);
    this.psEmitter.emit(event);
  }
  
  get displayedColumns()
  {
    let actions = this.enableAction && this.action && this.action.length > 0 ? ['actions'] : [];
    return [...this.header.map(m => m.fieldName), ...actions];
  }

  public generateToolBar(element: any): Toolbar
  {
    let toolbar: Toolbar;
    let actions = [];
    for(let a of this.action)
    {
      if(a.fnShow == null || a.fnShow(element) == true)
      {
        actions.push({
          btnTitle: a.title,
          btnIconClass: a.icon,
          btnDisabled: false,
          btnActionEvent: a.code,
          btnCssClass: a.btnClass ? a.btnClass : 'btn btn-secondary'
        });
      }
    }
    toolbar = { toolbarCss: 'toolbar-vertical', actions: actions };
    return toolbar;
  }

  public handleToolbarEvent(event: ToolbarEvent): void
  {
    this.actionEmitter.emit({type: event.event, value: event.data});
  }

  public toListString(arr: Array<string>)
  {
    /*let s = "<ul>";
    for(let e of arr)
      s+="<li>"+e+"</li>";
    s+="</ul>";*/
    let s="";
    if(arr && arr.length>1){
      for(let e of arr)
        s+=e+"<br>";
    }else{
      s=arr[0];
    }
    return s;
  }
  

  public getValue(row:any,header:SimpleTableHeader){
    let valueCell= row[header.fieldName];
    if(header.translate){
      valueCell=header.translate(valueCell);
    }
    if(header.truncate){
      valueCell=this.truncatePipe.transform(valueCell,header.truncate);
    }
    return valueCell;
  }

}
