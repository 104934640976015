/**
 * Multiselect component.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 4th January 2017
 * @export 
 * @class MultiselectComponent
 */
import { Input, Component, Optional, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'myp-multiselect',
    templateUrl: 'multiselect.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MultiselectComponent implements ControlValueAccessor {

    /**
     * The selectable values 
     * 
     * @type {Array<string>}
     * @memberOf MultiselectComponent
     */
    @Input()
    public values: Array<string>;

    /**
     * 
     * 
     * @type {boolean}
     * @memberof MultiselectComponent
     */
    @Input()
    public disabled: boolean = false;

    /**
     * The value for this control
     * 
     * @private
     * @type {Array<string>}
     * @memberOf MultiselectComponent
     */
    private value: Array<string>;

    /**
     * Propagate change to the outside
     * 
     * @private
     * 
     * @memberOf MultiselectComponent
     */
    private propagateChange = (_: any) => { };

    /**
     * Creates an instance of MultiselectComponent.
     * 
     * @param {NgControl} control
     * 
     * @memberOf MultiselectComponent
     */
    constructor(
        @Optional() control: NgControl
    ) {
        if (control)
            control.valueAccessor = this;
    }

    /**
     * Check if a value is selected or not
     * 
     * @param {string} value
     * @returns
     * 
     * @memberOf MultiselectComponent
     */
    public getSelected(value: string) {
        if (!this.value)
            return false;

        return this.value.indexOf(value) !== -1;
    }

    /**
     * Set the model value
     * 
     * @param {*} value
     * 
     * @memberOf MultiselectComponent
     */
    public writeValue(value: any) {
        this.value = value;
    }

    /**
     * Register the onChange function
     * 
     * @param {any} fn
     * 
     * @memberOf MultiselectComponent
     */
    public registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    /**
     * Register the onTouched function 
     * 
     * 
     * @memberOf MultiselectComponent
     */
    public registerOnTouched() {
        // Do nothing
    }

    /**
     * Raised when an option is clicked 
     * 
     * @private
     * @param {any} event
     * @param {any} value
     * 
     * @memberOf MultiselectComponent
     */
    public valueToggled(event: Event, value: string) {
        if (!this.value)
            this.value = new Array<string>();

        if (this.value.indexOf(value) === -1)
            this.value.push(value);
        else
            this.value.splice(this.value.indexOf(value), 1);

        this.propagateChange(this.value);
    }

}