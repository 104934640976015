import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Coordinate, GeometriesEditedEvent, Line } from 'src/app/shared/components/ng-regioneveneto-map/models/model';
import { NgRegioneVenetoMapComponent } from 'src/app/shared/components/ng-regioneveneto-map/ng-regioneveneto-map.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mappa-pratiche',
  templateUrl: './mappa-pratiche.component.html',
  styleUrls: ['./mappa-pratiche.component.scss']
})
export class MappaPraticheComponent implements OnInit,OnChanges {

  public windowSize: any = {
    width: "100%",
    height: "500px"
  };

  constructor() {}

  ngAfterViewInit(): void {
  }


  public options:any={
    initialization: {
    boundingBox : environment.geo.bboxVeneto,
    positioning: "point",
    zoom: 10,
    //point:{​​​​​​ lat: 45.4161, lng: 11.8828 }​​​​​​,
    /*boundingBox: [
    { lat: 60, lng: 20 },
    { lat: 30, lng: 10 },
    ],*/
    maxBounds: [
    [47, 14], // SW
    [44, 10], // NE
    ],
    maxZoom: 18,
    minZoom: 2,
    },

    searchProvider:{
      options:{
        style: 'bar',
        showMarker: true,
      },
      nativeSearchProvider:{
        name:'openStreetMap',
      },
    },
    
    layersToolbar: {
    position: "topright",
    viewMode: "expanded",
    },
    drawOptions: {
      drawModeEntries: ["polyline", "polygon", "marker"],
      drawStyle: {
        color: "red",
        fillColor: "yellow",
        weight: 5,
      },
    },
    };

  public baseLayers: any = [
    ...environment.layerAggiuntivi,
    {
      name: "map",
      type: "tile",
      url: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      layerId: "mapbox/light-v9",
      isSelected: false,
    },
    {
      name: "OSM",
      type: "tile",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      isSelected: true,
      attribution:'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
      },

    {
      name: "streets",
      type: "tile",
      url: "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      layerId: "mapbox/streets-v11",
      isSelected: false,
    },
  ];


  public centro: {
    type: "point" | "boundingBox";
    coordinates: Coordinate | [Coordinate, Coordinate];
  } = { type: "point", coordinates: environment.geo.coordsPadova };

  public overlayLayers = [
    {
      name: environment.geo.layerEditing,
      type: "entities",
      entities: [],
      isSelected: true,
      isEditable: true,
    },
  ];
  public isDrawModeEnabled: boolean = false;


  /**
   * qui vanno a finire le geometrie editate
   * ogni volta riassegno array
   */
  public entitiesGroups: any[]=[
    {
      layerName:environment.geo.layerEditing,
      id:environment.geo.idGrpEntities,
      entities:[]
    }
  ];

  /**
   * entities da aggiungere nel layer
   */
  @Input() entitiesInput:any[]=[];

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.entitiesInput){
      this.refreshLayer();
    }
  }

  @ViewChild("ngRegioneVenetoMap") public ngRegioneVenetoMap: NgRegioneVenetoMapComponent;

  private getEntities(){
    let entities=[];
    this.entitiesGroups.forEach(el=>{
      if(el.layerName==environment.geo.layerEditing &&
        el.id==environment.geo.idGrpEntities){
        entities=el.entities;
      }
    });
    return entities;
  }

  ngOnInit() {
    this.refreshLayer();
  }

  refreshLayer(){
    setTimeout(()=>{
      let entities=this.entitiesInput.map(el=>{
        el.groupId=environment.geo.idGrpEntities;
        return el;
      });
      this.entitiesGroups=[
        {
          layerName:environment.geo.layerEditing,
          id:environment.geo.idGrpEntities,
          entities:entities
        }];
      if(entities && entities.length>0){
        //prendo tutti i punti derivanti sia da linee che da marker che da poligoni
        let coords:Coordinate[]=[];
        entities.forEach(ent=>{
          if(ent?.position){
            coords.push(ent?.position);
          }if(ent?.positions){
            coords.push(...ent?.positions);
          }
        });
        let coordCentro=this.ngRegioneVenetoMap.getPolygonBoundingBox(coords);
        this.centro={type:"boundingBox",coordinates:coordCentro};
      }
    });
  }

  public onEntityClickedEvent(event: any) {
    console.log("onEntityClickedEvent {}",{
      type: event.type,
      data: JSON.stringify(event.data),
    });
  }


  public downloadShapeFile(){
    if(!this.getEntities()){
      alert("Nessuna geometria da esportare!!");
    }
    //contatto il be e mi faccio generare lo shape dal geoJson
  }
}
