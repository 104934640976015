import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
    selector: 'app-range-date-field',
    template: `
        <mat-form-field appearance="fill" color='accent' class="mat-input-wrapper">
            <mat-label class="big">{{label}}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [formControl]="fcStart" [min]="mindate" [max]="fcEnd?.value" placeholder="Data inizio">
                <input matEndDate [formControl]="fcEnd" [min]="fcStart?.value" [max]="maxdate" placeholder="Data fine">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    `,
    styles: [],
})
export class DateRangePickerField
{
    @Input("startDate") fcStart: FormControl;
    @Input("endDate") fcEnd: FormControl;

    @Input("mindate") mindate: Date;
    @Input("maxdate") maxdate: Date;

    @Input("label") label: string;

    public constructor(){}

}