import { toHttpParams } from 'src/app/utilities/functions';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { AutoCompleteDto, EnteContainerBean, SelectContainerBean, AutocompleteComuniBean } from './../../model/base-model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TipologicheService 
{
  private BASE_URL = "api/public/tipologiche/";

  private GET_TP = this.BASE_URL + "getTipologiceProcedimento.pjson";
  private GET_PROV = this.BASE_URL + "getProvince.pjson";
  private GET_COMUNI = this.BASE_URL + "getComuni.pjson";
  private GET_ED = this.BASE_URL + "getEntiDelegati.pjson";
  private GET_TI = this.BASE_URL + "getTipologieIntervento.pjson";
  private GET_RP = this.BASE_URL + "getRilasciatoProvincia.pjson";
  private GET_DATI = this.BASE_URL + "getDataForSearchForm";
  private POPULATE_ENTE = this.BASE_URL + "getDataForEnte.pjson";

  constructor(private http: HttpClient) { }

  public getTp(): Observable<Array<AutoCompleteDto>>
  {
    return this.http.get<Array<AutoCompleteDto>>(this.GET_TP);
  }

  public getProv(enteDelegato?: string): Observable<Array<AutoCompleteDto>>
  {
    let params = new HttpParams();
    if(enteDelegato)
      params = params.append("enteDelegato", enteDelegato);
    return this.http.get<Array<AutoCompleteDto>>(this.GET_PROV, {params});
  }

  public getCom(ente?: string, prov?: number): Observable<Array<AutocompleteComuniBean>>
  {
    let params: HttpParams = new HttpParams();
    if(ente)
      params = params.append('enteDelegato', ente);

    if(prov)
      params = params.append('idProvincia', prov.toString());
    return this.http.get<Array<AutocompleteComuniBean>>(this.GET_COMUNI, {params});
  }

  public getEntiDelegati(acl:string, ufficio: string): Observable<Array<AutoCompleteDto>>
  {
    let params: HttpParams = new HttpParams();
    if(acl != null) params = params.append('acl', acl);
    if(ufficio != null) params = params.append('codiceEnte', ufficio);
    return this.http.get<Array<AutoCompleteDto>>(this.GET_ED, {params});
  }

  public getTipoIntervento(tipoProcedimento: string): Observable<Array<AutoCompleteDto>>
  {
    let params: HttpParams = new HttpParams().set('tipoProcedimento', tipoProcedimento);
    return this.http.get<Array<AutoCompleteDto>>(this.GET_TI, {params});
  }

  public getRilasciatoProv(): Observable<Array<AutoCompleteDto>>
  {
    return this.http.get<Array<AutoCompleteDto>>(this.GET_RP);
  }

  public getDatiSelect(search: ModelAutorizzazionePaesaggistica.DataListSearch): Observable<SelectContainerBean>
  {
    let params: HttpParams = toHttpParams(search);
    return this.http.get<SelectContainerBean>(this.GET_DATI, {params});
  }

  public getDataForEnteSelect(codiceEnte?: string): Observable<EnteContainerBean>
  {
    let params: HttpParams = new HttpParams();
    if(codiceEnte)
      params = params.append('codiceEnte', codiceEnte);
    return this.http.get<EnteContainerBean>(this.POPULATE_ENTE, {params});
  }

}
