/**
 * Dashboard service.
 * 
 * @author Matteo Trevisan
 * @version 1.0.0
 * @since 15th September 2017
 * @export
 * @class DashboardService
 */
import { Injectable, Inject } from '@angular/core';
import { HttpClient,  HttpParams} from '@angular/common/http';
import { UserService } from './user.service';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class DashboardService {

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService
    ) {
        // Do nothing
    }

    public getQuickLinks(): Promise<Array<Model.ActionQuickLinks>> {

        return this.http
            .get(`/myintranet/${this.userService.ipa}/dashboard/quick-links`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.ActionQuickLinks>> response).entities);            
    }
}
