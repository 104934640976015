
import { Component, Input, ViewEncapsulation, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IBreadcrumbsConfig, IBreadcrumbsEntry } from './breadcrumbs.interfaces';
import { UserService } from './../../core/services/user.service';
import { isObject, has, isEmpty, size, join } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'myp-breadcrumbs',
    templateUrl: 'breadcrumbs.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {

    @Input()
    public config: IBreadcrumbsConfig;

    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService) { }

    public ngOnInit(): void { }

    public iconStyle(entry: IBreadcrumbsEntry, index: number): string[] {
        return (index > 0) ? this.divider() : this.empty();
    }

    public entryStyle(entry: IBreadcrumbsEntry, index: number): string[] {
        if (isObject(this.config) && isObject(entry)) {
            if (index === 0) {
                return this.home();
            }
            if (size(this.config.entries) === (index + 1)) {
                return this.current();
            }
            if (!isEmpty(entry.url)) {
                return this.link();
            }
            return this.label();
        }
        return this.empty();
    }

    public navigate(entry: IBreadcrumbsEntry): void {
        if (isObject(entry)) {
            if (!isEmpty(entry.url)) {
                this.router.navigateByUrl(
                    this.buildUrl(entry)
                );
            }
        }
    }

    private buildUrl(entry: IBreadcrumbsEntry): string {
        return this.adaptUrl(join([this.base(), entry.url], ''));
    }

    private base(): string {
        if (isObject(this.config) && !isEmpty(this.config.base)) {
            return this.config.base;
        }
        return '';
    }

    private adaptUrl(url: string): string {
        return url.replace(/__TENANT_CODE__/, this.userService.ipa);
    }

    private empty = (): string[] => [];

    private home = (): string[] => ['myp-breadcrumbs-home'];

    private link = (): string[] => ['myp-breadcrumbs-link'];

    private label = (): string[] => ['myp-breadcrumbs-label'];

    private current = (): string[] => ['myp-breadcrumbs-current'];

    private divider = (): string[] => ['icon', 'icon-arrow-right'];

}
