/**
 * @class AuthGuard
 * @implements {CanActivate}
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './../services/user.service';
import { AuthenticationService } from './auth.service';
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface RouteAcl {
  name: string;
  isPartial: boolean;
}

@Injectable()
export class AuthGuard implements CanActivate {

  /**
   * Creates an instance of AuthGuard.
   */
  constructor(
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    // Do nothing
  }

  /**
   *
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    /*const acl: RouteAcl = route.data.acl;
    if (acl) {
      if (!this.userService.isAuthorizedFor(acl.name)) {
        alert('Unauthorized!');
        return false;
      }
    }*/

    return this.authenticationService.getAcl().pipe(map(ret => {
      const currentUserAcl = ret.acl;
      if (currentUserAcl) {
        const acl = route.data.acl;
        if (acl) {
          if (acl.includes(currentUserAcl)) {
            return true;
          } else {
            alert("Non autorizzato");
            this.router.navigate(['logout']);
            return false;
          }
        } else {
          return true; //se non era specificato e' sempre accessibile
        }
      }
  
      window.location.replace(`${environment.urls.samlLogin}/`);
      return false;
    }))
  }

}