import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {  Component, forwardRef, Injector, Input, NgZone, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-textarea-field',
    template: `
    <!-- [ngClass]="test? 'test' : '' " -->
    <mat-form-field appearance="fill" color='accent' class="mat-input-wrapper"
                    [hideRequiredMarker]="hideRequiredMarker">
        <mat-label class="big">{{label}}</mat-label>
        <textarea matInput [errorStateMatcher]="myErrorMatcher" [formControl]="control" [ngClass]="{'uppercase': uppercase}"
                  [maxlength]="maxlength ? maxlength : 5000"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="20" #textArea></textarea>
        <mat-error *ngIf="errors && enable">
            <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
        </mat-error>
    </mat-form-field>
  `,
    styleUrls: ['./textarea-field.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextAreaFieldComponent),
            multi: true
        }
    ]
})
export class TextAreaFieldComponent extends BaseControlValueAccessor<string>
{
    @Input() uppercase: boolean = false;
    @Input() maxlength: number = null;
    @Input('test') test: boolean = false;
    @Input('hideRequiredMarker') hideRequiredMarker: boolean = false;
    @ViewChild('autosize') autosize: CdkTextareaAutosize;

    public myControl = new FormControl();

    constructor(protected inj: Injector, private _ngZone: NgZone)
    {
        super(inj);
    }

}
