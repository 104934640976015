import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoVincoloService {
  

  private BASE_URL = environment.urls.base + "/TipoVincolo";
  private DESC_URL = environment.urls.base + "/DescrizioneTipoVincolo";
  private VINCOLO_URL = environment.urls.base + "/Vincolo";

  constructor(private http: HttpClient) { }

  /**
  * Chiamata per recuperare la lista dei Tipi Vincolo
  * @param
  * @author Marta Zecchillo
  * @returns TipoVincolo[]
  */
  getTipiVincolo() {
    return this.http.get<ModelAutorizzazionePaesaggistica.TipoVincolo[]>
      (this.BASE_URL + `/select`);
  }

  /**
  * Chiamata per recuperare la lista delle descrizioni del tipo vincolo selezionato
  * @param
  * @author Marta Zecchillo
  * @returns DescrizioneTipoVincolo[]
  */
  getDescrizioneTipiVincolo(codiceTipoVincolo: string) {

    let params = new HttpParams;
    params = params.append("codiceTipoV", codiceTipoVincolo);

    return this.http.get<ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo[]>
      (this.DESC_URL + `/selectDTipoVincolo`, { params: params });
  }

  /**
   * fetch di tutte le descrizioni tipo vincolo
   * @author acolaianni
   * @returns
   */
  getAllDescrizioneTipiVincolo() {
    return this.http.get<ModelAutorizzazionePaesaggistica.DescrizioneTipoVincolo[]>
      (this.DESC_URL + `/selectDTipoVincolo`);
  }

  /* CHIAMATE ALLA TABELLA VINCOLO */
  /**
   * Chiamata per recuperare la lista degli idVincolo
   * @param
   * @author Marta Zecchillo
   * @returns AutoCompleteDto[]
   * @deprecated
   */
   getVincolo(codiceDescTipoVincolo: string) {
    let params = new HttpParams();
    params = params.append('codiceDescTipoVincolo', codiceDescTipoVincolo);

    return this.http.get<AutoCompleteDto[]>(
      this.VINCOLO_URL + `/selectIdVincolo`,
      { params: params }
    );
  }

  /**
   * 
   * Restituisce tutti i possibili vincoli a partire dall'ente delegato impostato sulla pratica (e la sua data di creazione)
   * restituisce i vincoli legati a tutti i possibili comuni coinvolti 
   * @param id idPratica
   * @author Adriano Colaianni
   */
  getVincoliPossibiliPratica(idPratica: string) {
    let params = new HttpParams();
    params = params.append('idPratica', idPratica);

    return this.http.get<ModelAutorizzazionePaesaggistica.VincoloOptionBean[]>(
      this.VINCOLO_URL + `/vincoliPossibiliByPratica`,
      { params: params }
    );
  }

}
