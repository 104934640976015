
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { errors, Error } from './../../translations/error-translation';
import { messages, Message } from './../../translations/message-translation';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslationService {

    /**
     * 
     * 
     * @type {Subject<string>}
     * @memberof TranslationService
     */
    public _language: Subject<string>

    /**
     * 
     * 
     * @private
     * @type {string}
     * @memberof TranslationService
     */
    private languageValue: string;


    /**
     * Creates an instance of TranslationService.
     * @param {HttpClient} http 
     * @memberof TranslationService
     */
    constructor( @Inject(HttpClient) private http: HttpClient) {

        if(navigator['languages']) {
            this.languageValue = navigator['languages'][0].slice(0, 2);
        }
        else {
            this.languageValue = navigator.language.slice(0, 2);
        }

        this._language = new BehaviorSubject<string>(this.languageValue);
    }

    /**
     * 
     * 
     * @readonly
     * @type {Observable<string>}
     * @memberof TranslationService
     */
    public get language(): Observable<string> {
        return this._language.asObservable();
    }

    /**
     * 
     * 
     * @param {string} language 
     * @memberof TranslationService
     */
    public setLanguage(language: string): void {
        this.languageValue = language;
        this._language.next(language);
    }

    /**
     * 
     * 
     * @memberof ErrorTranslationService
     */
    public getErrorTranslation(message: string): string {
        let error: Error = errors.find(err => err['message'] === message);
        return error ? error[this.languageValue] : message;
    }

    /**
     * 
     * 
     * @author Davide Trevisan
     * @param {string} message 
     * @param {string} source 
     * @returns 
     * @memberof TranslationService
     */
    public getMessageTranslation(message: string, source: string) {
        let item: Message = messages.find(msg => msg.message === message && msg.source === source)
        if (!item)
            item = messages.find(msg => msg.message === message && msg.source === 'generic')
        return item ? item[this.languageValue] : message
    }
}