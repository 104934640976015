import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { UserService } from 'src/app/core/services/user.service';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { PraticaService } from 'src/app/services/autorizzazionePaesaggistica/pratica.service';
import { DialogService } from 'src/app/services/dialog.service';
import { fromGeoJSONToEntities } from 'src/app/shared/components/ng-regioneveneto-map/models/model';
import { downloadFile, statiOptions } from 'src/app/utilities/functions';
import { environment } from 'src/environments/environment';
import { List, NumericDictionary } from 'lodash';
import { LocalSessionService } from './../../core/services/local-session.service';
import {
  AutocompleteComuniBean,
  AutoCompleteDto,
  EnteAssociazioneBean,
  EnteDelegatoSelectBean,
  PaginatedList,
} from './../../model/base-model';
import { TipologicheService } from './../../services/autorizzazionePaesaggistica/tipologiche.service';
import {
  ActionEventResult,
  SimpleActionLink,
  SimpleTableHeader,
} from './../../shared/components/simple-table/SimpleTableConfig';

@Component({
  selector: 'app-lista-pratiche',
  templateUrl: './lista-pratiche.component.html',
  styleUrls: ['./lista-pratiche.component.css'],
})
export class ListaPraticheComponent implements OnInit {
  public arrowFlag: boolean = false;
  public dettaglio: boolean = false;
  public eventDetail: any;
  public isAlertAnnullamento: boolean;
  public list_tmp: List<ModelAutorizzazionePaesaggistica.SimplePraticaBean>;
  public header: Array<SimpleTableHeader> = [];
  public actions: Array<SimpleActionLink> = [];
  public list: PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>;
  public praticaSearch: ModelAutorizzazionePaesaggistica.PraticaSearch =
    this.initFilter();
  //Variabili per modal
  public title: string;
  public content: string;
  public confirmed: string;
  public inputData: string;

  public entiDelegati: Array<AutoCompleteDto> = [];
  public tpOptions: Array<AutoCompleteDto> = [];
  public rpOptions: Array<AutoCompleteDto> = [];
  public statiOptions: Array<AutoCompleteDto> = [];
  public tiOptions: Array<AutoCompleteDto> = [];
  public pOptions: Array<AutoCompleteDto> = [];
  public cOptions: Array<AutocompleteComuniBean> = [];

  public ufficio: string = null;
  public acl: string = null;
  public isEd: boolean = false;
  public form: FormGroup;

  public isLoading: boolean = false;
  //se a true implica la visione mappa al posto del datatable
  public vistaMappa: boolean = false;
  public entitiesInput: any[] = [];
  public showDownload: boolean = false;

  private allTipiIntervento: Array<ModelAutorizzazionePaesaggistica.TipoInterventoDto> =
    [];
  private defaultRp: Array<AutoCompleteDto> = [];
  private defaultProvince: Array<AutoCompleteDto> = [];
  private defaultComuni: Array<AutocompleteComuniBean> = [];
  private defaultEnti: Array<EnteDelegatoSelectBean> = [];
  private associazioni: Array<EnteAssociazioneBean> = [];
  public env = environment;

  constructor(
    private fb: FormBuilder,
    private service: PraticaService,
    private tService: TipologicheService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private alertsCenterService: AlertsCenterService,
    private userService: UserService
  ) {
    this.vistaMappa = false;
    activatedRoute.data.subscribe((data) => {
      if (data.vistaMappa) {
        this.vistaMappa = true;
        this.praticaSearch.limit = environment.geo.maxResultsConsultazioneMappa;
        this.praticaSearch.page = 1;
      }
    });
  }

  public ngOnInit(): void {
    this.acl = sessionStorage.getItem(LocalSessionService.ACL_KEY);
    this.isEd = this.acl == 'ed';
    this.ufficio = sessionStorage.getItem(LocalSessionService.UFFICIO_KEY);

    this.header = this.buildHeader();
    this.actions = this.buildActions();

    this.praticaSearch.idEnteDelegato = this.ufficio;
    this.buildForm();
    this.initPage();
  }

  private simpleSearchData$(
    praticaSearch: ModelAutorizzazionePaesaggistica.PraticaSearch
  ): Observable<
    PaginatedList<ModelAutorizzazionePaesaggistica.SimplePraticaBean>
  > {
    let praticheSearchData$ = this.service.simpleSearchPratiche(praticaSearch);
    if (this.vistaMappa) {
      praticheSearchData$ = this.service.simpleSearchPraticheGeo(praticaSearch);
    }
    return praticheSearchData$;
  }

  private initPage(): void {
    let toRetrive: ModelAutorizzazionePaesaggistica.DataListSearch = {
      enteDelegato: false,
      provincia: false,
      comuni: false,
      tipoIntervento: true,
      tipoProcedura: true,
      tipoVincolo: false,
      rilasciatoProvincia: true,
    };

    combineLatest([
      this.tService.getDatiSelect(toRetrive),
      this.tService.getDataForEnteSelect(this.ufficio),
      this.tService.getProv(this.ufficio),
      this.tService.getCom(this.ufficio, null),
      this.simpleSearchData$(this.praticaSearch),
    ]).subscribe(
      ([rcn, red, prov, com, res]) => {
        this.allTipiIntervento = rcn.tipiIntervento;
        this.defaultRp = rcn.rilasciatoProvincia;
        this.defaultProvince = prov;
        this.defaultComuni = com;
        this.defaultEnti = red.enti;
        this.associazioni = red.associazioni;

        this.list = res;
        this.tpOptions = rcn.tipiProcedura;
        this.pOptions = prov;
        this.rpOptions = rcn.rilasciatoProvincia;
        this.cOptions = com;
        this.entiDelegati = this.defaultEnti.map((m) => {
          return { label: m.descrizione, value: m.id };
        });
        this.tiOptions = rcn.tipiIntervento.map((m) => {
          return { label: m.descrizione, value: m.id };
        });

        this.statiOptions = statiOptions();
        /*[
        {label: 'Bozza', value: 'BOZZA'},
        {label: 'Inoltrata', value: 'INOLTRATA'},
        {label: 'Inviata al protocollo', value: 'INVIATA_AL_PROTOCOLLO'},
        {label: 'Errato protocollo', value: 'ERRATO_PROTOCOLLO'},
        {label: 'Protocollata', value: 'PROTOCOLLATA'},
        {label: 'Richiesta di annullamento', value: 'RICHIESTA_DI_CANCELLAZIONE'},
        {label: 'Annullata', value: 'CANCELLATA'},
      ];*/
        if (this.isEd) {
          this.form.patchValue({ idEnteDelegato: this.ufficio });
          if (this.defaultEnti[0].tipoEnte != 'D')
            this.rpOptions = [this.defaultRp.find((f) => f.value == 'VUOTO')];
        }
        if (this.vistaMappa) this.elaboraFeatures();
      },
      () => {
        this.alertsCenterService.showAlert({
          message: `Errore lettura dati`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );

    if(this.acl == 'dpt'){
      this.controlloPraticheStatoAnnullamento();
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      id: [null],
      statoPratica: [null],
      idEnteDelegato: [null],
      idComuneCoinvolto: [null],
      idTipoProcedura: [null],
      dataPresentazioneRichiesta: [null],
      rilasciatoProvincia: [null],
      dataRilascioDiniegoAutpae: [null],
      numeroAutpae: [null],
      richiedente: [null],
      oggetto: [null],
      idTipoIntervento: [null],
      presenzaParereSop: [null],
      presenzaParereCommLoc: [null],
      idProvinciaCoinvolta: [null],
      dataTrasmissione: [null],
      dataRichiestaCancellazione: [null],
    });
    /* this.form.controls.idComuneCoinvolto.disable();
    this.form.controls.idTipoIntervento.disable(); */
  }

  private search(): void {
    //this.service.simpleSearchPratiche(this.praticaSearch)
    this.simpleSearchData$(this.praticaSearch).subscribe(
      (response) => {
        this.list = response;
        if (this.vistaMappa) {
          this.elaboraFeatures();
        }
      },
      (error) => {
        this.alertsCenterService.showAlert({
          message: `Errore lettura dati`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }

  public evalOnChange(_case: 'E' | 'C' | 'P' | 'RP'): void {
    switch (_case) {
      case 'E':
        this.filterRilasciatoProvincia();
        this.filterProvince();
        this.filterComuni();
        break;
      case 'RP':
        this.filterEnti();
        this.filterProvince();
        this.filterComuni();
        break;
      case 'P':
        this.filterEnti();
        this.filterComuni();
        break;
      case 'C':
        this.filterEnti();
        this.filterProvince();
        break;
    }
  }

  private filterEnti(): void {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let enti = this.defaultEnti.slice();
    if (rp != null && rp != 'VUOTO')
      enti = enti.filter((f) => f.tipoEnte == 'D');
    if (p != null)
      enti = enti.filter((f) =>
        this.associazioni.some(
          (s) => '' + s.idProvincia == p && s.idEnteDelegato == f.id
        )
      );
    if (com != null)
      enti = enti.filter((f) =>
        this.associazioni.some(
          (s) => '' + s.idComune == com && s.idEnteDelegato == f.id
        )
      );
    this.entiDelegati = enti.map((m) => {
      return { label: m.descrizione, value: m.id };
    });
    this.valutaResetIfNotPresent(this.entiDelegati, 'idEnteDelegato');
  }

  private filterProvince(): void {
    let ed = this.form.controls.idEnteDelegato.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let province = this.defaultProvince.slice();
    if (ed != null) {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(
        (e) => e.id == ed
      );
      if (ente.tipoEnte == 'D')
        province = [province.find((f) => f.value == '' + ente.idAe)];
      else
        province = province.filter((f) =>
          this.associazioni.some(
            (s) => s.idEnteDelegato == ed && '' + s.idProvincia == f.value
          )
        );
    }
    if (com != null) {
      let comune = this.cOptions.find((f) => f.value == com);
      province = province.filter((f) => comune.idProvincia == f.value);
    }
    this.pOptions = province;
    this.valutaResetIfNotPresent(this.pOptions, 'idProvinciaCoinvolta');
  }

  private filterComuni(): void {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let ed = this.form.controls.idEnteDelegato.value;
    let comuni = this.defaultComuni.slice();

    if (ed) {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(
        (e) => e.id == ed
      );
      switch (ente.tipoEnte) {
        case 'A1':
          //Caso ente comune
          comuni = this.defaultComuni.filter((f) =>
            this.associazioni.some(
              (s) => s.idEnteDelegato == ed && '' + s.idComune == f.value
            )
          );
          break;
        case 'D':
          //Caso ente provincia
          if (rp == 'ART45A')
            comuni = this.defaultComuni.filter(
              (f) => f.idProvincia == '' + ente.idAe
            );
          else
            comuni = comuni.filter((f) =>
              this.associazioni.some(
                (s) => s.idEnteDelegato == ed && '' + s.idComune == f.value
              )
            );
          break;
        default:
          comuni = comuni.filter((f) =>
            this.associazioni.some(
              (s) => s.idEnteDelegato == ed && '' + s.idComune == f.value
            )
          );
          if (p) comuni = comuni.filter((f) => f.idProvincia == p);
      }
    } else if (p) {
      comuni = this.defaultComuni.filter((f) => f.idProvincia == p);
    }
    this.cOptions = comuni;
    this.valutaResetIfNotPresent(this.cOptions, 'idComuneCoinvolto');
  }

  private filterRilasciatoProvincia(): void {
    let ed = this.form.controls.idEnteDelegato.value;
    let ril = this.defaultRp.slice();
    if (ed) {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(
        (e) => e.id == ed
      );
      if (ente.tipoEnte != 'D')
        ril = [this.defaultRp.find((f) => f.value == 'VUOTO')];
    }
    this.rpOptions = ril;
    this.valutaResetIfNotPresent(this.rpOptions, 'rilasciatoProvincia');
  }

  /*  public searchOnChange(): void
  {
    let rp = this.form.controls.rilasciatoProvincia.value;
    let ed = rp != 'ART45A' ? this.form.controls.idEnteDelegato.value : null;
    let p = this.form.controls.idProvinciaCoinvolta.value;

    let pObs$ = ed ? this.tService.getProv(ed) : of(this.defaultProvince);
    let cObs$ = p || ed ? this.tService.getCom(ed, p) : of(this.defaultComuni);

    combineLatest([pObs$, cObs$]).subscribe(([prov, com]) =>
    {
      this.pOptions = prov;
      this.cOptions = com;
      this.valutaResetIfNotPresent(prov, "idProvinciaCoinvolta");
      this.valutaResetIfNotPresent(com , "idComuneCoinvolto");
    },
    ([e1, e2]) =>
    {
      this.alertsCenterService.showAlert({
        message: `Errore lettura dati comuni e province`,
        type: 'danger',
        autoClosable: false
      });
    });
  } */

  private valutaResetIfNotPresent(
    v: Array<AutoCompleteDto>,
    formControlName: string
  ): void {
    let actualValue = this.form.controls[formControlName].value;
    if (!actualValue || !v.some((m) => m.value == actualValue))
      this.form.controls[formControlName].setValue(null);
  }

  public searchTI(): void {
    let tipoProcedura = this.form.controls.idTipoProcedura.value;
    this.form.controls.idTipoIntervento.setValue(null);
    this.tiOptions = this.allTipiIntervento
      .filter(
        (f) =>
          !tipoProcedura ||
          f.idTipoProcedura == this.form.controls.idTipoProcedura.value
      )
      .map((m) => {
        return { label: m.descrizione, value: m.id };
      });
  }

  public onNavigate(path: any) {
    this.router.navigate([path]);
  }

  public handleEvent(event: ActionEventResult): void {
    console.log('Evento: ', event);
    switch (event.type) {
      case 'details':
        this.router.navigate(['dettaglio-pratica', event.value.id]);
        break;
      case 'edit':
        this.router.navigate(['modifica-pratica', event.value.id]);
        break;
      case 'request_delete':
        this.confermaOperazione(
          'Richiesta annullamento',
          "Si vuol procedere con la richiesta di annullamento pratica presso la Direzione Pianificazione Territoriale?",
          this.richiestaCancellazione.bind(this),
          event.value.id
        );
        break;
      case 'delete':
        this.confermaOperazione(
          'Annullamento pratica',
          "Si è scelto di confermare la richiesta di cancellazione per l'autorizzazione paesaggistica selezionata. " +
           "Confermando l'operazione la pratica transiterà in stato Annullata e non sarà più possibile eseguire nessuna operazione. " +
           "Le autorizzazioni paesaggistiche in stato Eliminata non saranno considerate ai fini della pubblicazione e della reportistica.",
          this.cancellaPratica.bind(this),
          event.value.id
        );
        break;
      case 'delete_draft':
        this.confermaOperazione(
          'Cancellazione pratica',
          'Conferma per cancellare la pratica selezionata',
          this.eliminaBozzaPratica.bind(this),
          event.value.id
        );
        break;
      case 'restore':
        this.confermaOperazione(
          'Ripristino pratica',
          "Si è scelto di annullare la richiesta di cancellazione per l'autorizzazione paesaggistica selezionata."
           + " Confermando l'operazione sarà ripristinato lo stato precedente. Confermare l'operazione?",
          this.ripristinaPratica.bind(this),
          event.value.id
        );
        break;
      default:
        console.log('Evento non supportato.');
    }
  }

  public handleSortPagingChange(event: any): void {
    this.praticaSearch = { ...this.praticaSearch, ...event };
    this.search();
  }

  public reset(): void {
    this.form.reset();
    this.praticaSearch.page=1;
    this.pOptions = this.defaultProvince;
    this.cOptions = this.defaultComuni;
    this.rpOptions = this.defaultRp;
    if (this.isEd) this.form.patchValue({ idEnteDelegato: this.ufficio });
    else
      this.entiDelegati = this.defaultEnti.map((m) => {
        return { label: m.descrizione, value: m.id };
      });
    this.ricercaListaEventi();
  }

  public ricercaListaEventi(): void {
    this.praticaSearch.page=1;
    this.praticaSearch = {
      ...this.form.getRawValue(),
      limit: this.praticaSearch.limit,
      page: this.praticaSearch.page,
      sortBy: this.praticaSearch.sortBy,
      sortType: this.praticaSearch.sortType,
    };
    this.search();
  }

  private initFilter(): ModelAutorizzazionePaesaggistica.PraticaSearch {
    return {
      id: null,
      statoPratica: null,
      idEnteDelegato: null,
      idTipoProcedura: null,
      dataPresentazioneRichiesta: null,
      rilasciatoProvincia: null,
      dataRilascioDiniegoAutpae: null,
      numeroAutpae: null,
      richiedente: null,
      oggetto: null,
      idTipoIntervento: null,
      presenzaParereSop: null,
      presenzaParereCommLoc: null,
      idProvinciaCoinvolta: null,
      dataTrasmissione: null,
      dataRichiestaCancellazione: null,
      page: 1,
      limit: 10,
      sortBy: 'id',
      sortType: 'ASC',
    };
  }

  private buildHeader(): Array<SimpleTableHeader> {
    let list: Array<SimpleTableHeader> = [
      {
        label: 'pratica.idFiltro',
        fieldName: 'id',
        type: 'text',
        headerStyleClass: 'uppercase textLeft',
        respLabel: 'id Pratica',
      },
      {
        label: 'pratica.oggetto',
        fieldName: 'oggetto',
        type: 'text',
        truncate: 50,
        respLabel: 'Oggetto',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.numero',
        fieldName: 'numeroAutpae',
        type: 'text',
        respLabel: 'Numero Autorizzazione Paesaggistica',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.dataAutpae',
        fieldName: 'dataRilascioDiniegoAutpae',
        type: 'date',
        respLabel: 'Data Autorizzazione',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.stato',
        fieldName: 'statoPratica',
        type: 'text',
        translate: this.translateStato,
        respLabel: 'Stato Pratica',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.richiedente',
        fieldName: 'richiedente',
        type: 'text',
        respLabel: 'Richiedente',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.dataInserimento',
        fieldName: 'dataInserimento',
        type: 'date',
        respLabel: 'Data Inserimento',
        headerStyleClass: 'textLeft',
      },
      {
        label: 'pratica.dataInvio',
        fieldName: 'dataTrasmissione',
        type: 'date',
        respLabel: 'Data Inoltro',
        headerStyleClass: 'textLeft',
      },
    ];
    if (!this.isEd) {
      list.splice(1, 0, {
        label: 'pratica.ente',
        fieldName: 'nomeEnteDelegatoCompetenza',
        type: 'text',
        respLabel: 'Ente Delegato',
        headerStyleClass: 'textLeft',
      });
    }
    return list;
  }

  private buildActions(): Array<SimpleActionLink> {
    return [
      {
        title: 'Dettaglio',
        icon: 'article',
        code: 'details',
        fnShow: (p) => p.statoPratica != 'BOZZA',
      },
      {
        title: 'Dettaglio',
        icon: 'edit',
        code: 'edit',
        fnShow: (p) => p.statoPratica == 'BOZZA',
      },
      {
        title: 'Elimina pratica',
        icon: 'delete',
        code: 'delete_draft',
        fnShow: (p) => p.statoPratica == 'BOZZA',
      },
      {
        title: 'Richiedi annullamento',
        icon: 'delete_sweep',
        code: 'request_delete',
        fnShow: (p) => ['INOLTRATA','PROTOCOLLATA'].indexOf(p.statoPratica) != -1,
      },
      {
        title: 'Annulla pratica',
        icon: 'block',
        code: 'delete',
        fnShow: (p) => p.statoPratica == 'RICHIESTA_DI_CANCELLAZIONE' && !this.isEd,
      },
      {
        title: 'Ripristina pratica',
        icon: 'undo',
        code: 'restore',
        fnShow: (p) => p.statoPratica == 'RICHIESTA_DI_CANCELLAZIONE',
      },
    ];
  }

  private confermaOperazione(
    title: string,
    message: string,
    fn: (id: number) => void,
    id: number
  ): void {
    this.dialogService.openDialog(title, message).subscribe((result) => {
      let confirmed = `${result}`;
      if (confirmed == 'true') {
        fn(id);
      }
    });
  }

  private richiestaCancellazione(id: number): void {
    this.service.richiestaCancellazione(id).subscribe(
      (response) => {
        this.alertsCenterService.showAlert({
          message: `Richiesta di annullamento effettuata`,
          type: 'success',
          autoClosable: false,
        });
        this.search();
      },
      () => {
        this.alertsCenterService.showAlert({
          message: `Errore richiesta annullamento pratica`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }

  private cancellaPratica(id: number): void {
    this.service.cancellaPratica(id).subscribe(
      (response) => {
        this.alertsCenterService.showAlert({
          message: `Annullamento effettuato`,
          type: 'success',
          autoClosable: false,
        });
        this.search();
      },
      () => {
        this.alertsCenterService.showAlert({
          message: `Errore annullamento pratica`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }

  private eliminaBozzaPratica(id: number): void {
    this.service.eliminaBozzaPratica(id).subscribe(
      (response) => {
        this.alertsCenterService.showAlert({
          message: `Cancellazione effettuata`,
          type: 'success',
          autoClosable: false,
        });
        this.search();
      },
      () => {
        this.alertsCenterService.showAlert({
          message: `Errore cancellazione pratica`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }

  private ripristinaPratica(id: number): void {
    this.service.ripristinaPratica(id).subscribe(
      (response) => {
        this.alertsCenterService.showAlert({
          message: `Pratica ripristinata`,
          type: 'success',
          autoClosable: false,
        });
        this.search();
      },
      (errors) => {
        this.alertsCenterService.showAlert({
          message: `Errore ripristino pratica`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }

  /**
   * dalla this.list popolo la entitiesInput
   */
  elaboraFeatures() {
    if (this.list.count == 0) {
      this.entitiesInput = [];
      this.showDownload = false;
      return;
    }
    if (this.list.count >= environment.geo.maxResultsConsultazioneMappa) {
      this.dialogService
        .openDialog(
          'ATTENZIONE',
          'Raggiunto il limite massimo di pratiche consultabili in mappa (' +
            environment.geo.maxResultsConsultazioneMappa +
            ') filtrare ulteriormente !'
        )
        .subscribe((result) => {});
    }
    let newEntities = [];
    let geomId: number = 1;
    this.list.list.forEach((rowData) => {
      if (rowData?.geoJson) {
        let fc = JSON.parse(rowData.geoJson);
        const entities = fromGeoJSONToEntities(fc);
        entities.forEach((entity) => {
          entity.geomId = geomId;
          geomId = geomId + 1;
          entity.popup = this.getPopUpInfo(rowData);
        });
        newEntities.push(...entities);
      }
    });
    this.entitiesInput = newEntities;
    if(this.entitiesInput.length > 0){
      this.showDownload = true;
    } else {
      this.showDownload = false;
    }
  }

  getPopUpInfo(
    rowData: ModelAutorizzazionePaesaggistica.SimplePraticaBean
  ): any {
    let praticaDetailUrl = window.location.origin + window.location.pathname;
    if (rowData.statoPratica == 'BOZZA') {
      praticaDetailUrl =
        praticaDetailUrl + '/../modifica-pratica/' + rowData.id;
    } else {
      praticaDetailUrl =
        praticaDetailUrl + '/../dettaglio-pratica/' + rowData.id;
    }
    return (
      '<b>Pratica:</b>' +
      "<a href='" +
      praticaDetailUrl +
      "' target='_blank'>" +
      rowData.id +
      '</a>' +
      '<br>' +
      '<b>Ente deleg.:</b>' +
      rowData.nomeEnteDelegatoCompetenza +
      '<br>' +
      '<b>Tipo:</b>' +
      rowData.nomeTipoProcedura +
      '<br>' +
      '<b>Richiedente:</b>' +
      rowData.richiedente +
      '<br>' +
      '<b>Stato:</b>' +
      rowData.statoPratica +
      '<br>'
    );
  }

  public downloadShapeFile(): void {
    if (this.vistaMappa) {
      this.service.downloadShape(this.praticaSearch).subscribe(
        (response) => {
          if (response.ok) downloadFile(response.body, 'shapefile.zip');
        },
        (error) => {
          this.alertsCenterService.showAlert({
            message: `Errore download shapefile`,
            type: 'danger',
            autoClosable: false,
          });
        }
      );
    }
  }

  public downloadGeoJson(): void {
    console.log(this.list)
    if (this.vistaMappa) {
      this.service.downloadGeoJson(this.praticaSearch).subscribe(
        (response) => {
          if (response.ok) downloadFile(response.body, 'geojson.json');
        },
        (error) => {
          this.alertsCenterService.showAlert({
            message: `Errore download geoJson`,
            type: 'danger',
            autoClosable: false,
          });
        }
      );
    }
  }

  private translateStato(stato: string): string {
    let descrizioneStato = stato;
    statiOptions()
      .filter((el) => el.value == stato)
      .forEach((el) => (descrizioneStato = el.label));
    return descrizioneStato;
  }


  private controlloPraticheStatoAnnullamento() {
    this.service.simpleSearchPraticheRichiestaAnnullamento(this.praticaSearch).subscribe(
      (response) => {
        this.list_tmp = response;
        if(this.list_tmp.length >0){
          this.isAlertAnnullamento= true;
        }
      },
      (error) => {
        this.alertsCenterService.showAlert({
          message: `Errore lettura dati`,
          type: 'danger',
          autoClosable: false,
        });
      }
    );
  }


}
