import { AutocompleteComuniBean, EnteDelegatoSelectBean, EnteAssociazioneBean } from './../../model/base-model';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { TipologicheService } from './../../services/autorizzazionePaesaggistica/tipologiche.service';
import { AutoCompleteDto } from 'src/app/model/base-model';
import { downloadFile, statiOptions } from 'src/app/utilities/functions';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { PraticaService } from 'src/app/services/autorizzazionePaesaggistica/pratica.service';
import { Component, OnInit } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { LocalSessionService } from 'src/app/core/services/local-session.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit
{
  public form: FormGroup;
  public control: FormControl;

  public submitted: boolean = false;

  public options: Array<AutoCompleteDto> = this.getOptions();
  public entiDelegati: Array<AutoCompleteDto> = [];
  public tpOptions: Array<AutoCompleteDto> = [];
  public statiOptions: Array<AutoCompleteDto> = this.getStatoPratica();
  public pOptions: Array<AutoCompleteDto> = [];
  public cOptions: Array<AutocompleteComuniBean> = [];
  public tvOptions: Array<AutoCompleteDto> = [];
  public sinoOptions: Array<any> = this.getSiNoOptions();

  private defaultProvince: Array<AutoCompleteDto> = [];
  private defaultComuni: Array<AutocompleteComuniBean> = [];
  private defaultEnti: Array<EnteDelegatoSelectBean> = [];
  private associazioni: Array<EnteAssociazioneBean> = [];

  public ufficio: string = null;
  public acl: string = null;
  public isEd: boolean = false;

  constructor(private service: PraticaService,
              private alertsCenterService: AlertsCenterService,
              private fb: FormBuilder,
              private tService: TipologicheService) { }

  public ngOnInit(): void
  {
    this.acl = sessionStorage.getItem(LocalSessionService.ACL_KEY);
    this.isEd = this.acl == 'ed';
    this.ufficio = sessionStorage.getItem(LocalSessionService.UFFICIO_KEY);
    this.buildForm();
    this.initPage();
  }

  public downloadReport(): void
  {
    this.submitted = true;
    if(this.control.valid)
    {
      this.submitted = false;
      this.service.downloadReport(this.form.getRawValue(), this.control.value).subscribe(response =>
      {
        if(response.ok)
          downloadFile(response.body, 'report.' + this.control.value);
      },
      error =>
      {
        this.alertsCenterService.showAlert({
          message: `Errore download report`,
          type: 'danger',
          autoClosable: false
        });
      });
    }
  }

  private valutaResetIfNotPresent(v: Array<AutoCompleteDto>, formControlName: string): void
  {
    let actualValue = this.form.controls[formControlName].value;
    if (!actualValue || !v.some(m => m.value == actualValue))
      this.form.controls[formControlName].setValue(null);
  }

  private initPage(): void
  {
    let toRetrive: ModelAutorizzazionePaesaggistica.DataListSearch = {
      enteDelegato: false,
      provincia: true,
      comuni: true,
      tipoIntervento: false,
      tipoProcedura: true,
      tipoVincolo: true,
      rilasciatoProvincia: false
    }
    combineLatest([this.tService.getDatiSelect(toRetrive), this.tService.getDataForEnteSelect()]).subscribe(([selectContainer, enteContainer]) =>
    {
      this.defaultProvince = selectContainer.province;
      this.defaultComuni = selectContainer.comuni;
      this.defaultEnti = enteContainer.enti;
      this.associazioni = enteContainer.associazioni;

      this.tpOptions = selectContainer.tipiProcedura;
      this.tvOptions = selectContainer.tipiVincolo;
      this.pOptions = selectContainer.province;
      this.cOptions = selectContainer.comuni;
      this.entiDelegati = this.defaultEnti.map(m => { return { label: m.descrizione, value: m.id } });
    },
    ([e1, e2]) =>
    {
      this.alertsCenterService.showAlert({
        message: `Errore lettura dati`,
        type: 'danger',
        autoClosable: false
      });
    });
  }

  private buildForm(): void
  {
    this.form = this.fb.group({
      id: [null],
      idEnteDelegato: [null],
      idTipoProcedura: [null],
      dataPresentazioneRichiestaDa: [null],
      dataPresentazioneRichiestaA: [null],
      idComuneCoinvolto: [null],
      idProvinciaCoinvolta: [null],
      dataRilascioDiniegoAutpaeDa: [null],
      dataRilascioDiniegoAutpaeA: [null],
      codiceVincolo: [null],
      parereSoprintendenza: [null],
      parereCommissione: [null],
      statoPratica: [null],
    });
    //...
    this.control = new FormControl(null, Validators.required);
  }

  public reset(): void
  {
    this.form.reset();
    this.pOptions = this.defaultProvince;
    this.cOptions = this.defaultComuni;
    this.entiDelegati = this.defaultEnti.map(m => { return { label: m.descrizione, value: m.id } });
  }

  private getOptions(): Array<AutoCompleteDto>
  {
    return [
      //{ label: 'pdf', value: 'pdf' },
      { label: 'csv', value: 'csv' },
      { label: 'xls', value: 'xls' }
    ];
  }

  private getSiNoOptions(): Array<any>
  {
    return [
      { label: 'SI', value: 'SI'},
      { label: 'NO', value: 'NO'},
    ]
  }

  private getStatoPratica(): Array<AutoCompleteDto>
  {
    return statiOptions();
    /*return [
      { label: 'Bozza', value: 'BOZZA' },
      { label: 'Inoltrata', value: 'INOLTRATA' },
      { label: 'Inviata al protocollo', value: 'INVIATA_AL_PROTOCOLLO' },
      { label: 'Errato protocollo', value: 'ERRATO_PROTOCOLLO' },
      { label: 'Protocollata', value: 'PROTOCOLLATA' },
      { label: 'Richiesta di annullamento', value: 'RICHIESTA_DI_CANCELLAZIONE' },
      { label: 'Annullata', value: 'CANCELLATA' },
    ];*/
  }

  public evalOnChange(_case: 'E'|'C'|'P'): void
  {
    switch (_case)
    {
      case 'E':
        this.filterProvince();
        this.filterComuni();
        break;
      case 'P':
        this.filterEnti();
        this.filterComuni();
        break;
      case 'C':
        this.filterEnti();
        this.filterProvince();
        break;
    }
  }

  private filterEnti(): void
  {
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let enti = this.defaultEnti.slice();
    if (p != null)
      enti = enti.filter(f => this.associazioni.some(s => "" + s.idProvincia == p && s.idEnteDelegato == f.id));
    if (com != null)
      enti = enti.filter(f => this.associazioni.some(s => "" + s.idComune == com && s.idEnteDelegato == f.id))
    this.entiDelegati = enti.map(m => { return { label: m.descrizione, value: m.id } });
    this.valutaResetIfNotPresent(this.entiDelegati, 'idEnteDelegato');
  }

  private filterProvince(): void
  {
    let ed = this.form.controls.idEnteDelegato.value;
    let com = this.form.controls.idComuneCoinvolto.value;
    let province = this.defaultProvince.slice();
    if (ed != null)
    {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(e => e.id == ed);
      if (ente.tipoEnte == 'D')
        province = [province.find(f => f.value == "" + ente.idAe)];
      else
        province = province.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idProvincia == f.value));
    }
    if (com != null)
    {
      let comune = this.cOptions.find(f => f.value == com);
      province = province.filter(f => comune.idProvincia == f.value);

    }
    this.pOptions = province;
    this.valutaResetIfNotPresent(this.pOptions, 'idProvinciaCoinvolta');
  }

  private filterComuni(): void
  {
    let p = this.form.controls.idProvinciaCoinvolta.value;
    let ed = this.form.controls.idEnteDelegato.value;
    let comuni = this.defaultComuni.slice();

    if (ed)
    {
      const ente: EnteDelegatoSelectBean = this.defaultEnti.find(e => e.id == ed);
      switch (ente.tipoEnte)
      {
        case 'A1':
          //Caso ente comune
          comuni = this.defaultComuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          break;
        case 'D':
          //Caso ente provincia
          comuni = comuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          break;
        default:
          comuni = comuni.filter(f => this.associazioni.some(s => s.idEnteDelegato == ed && "" + s.idComune == f.value));
          if (p) comuni = comuni.filter(f => f.idProvincia == p);
      }
    }
    else if (p)
    {
      comuni = this.defaultComuni.filter(f => f.idProvincia == p);
    }
    this.cOptions = comuni;
    this.valutaResetIfNotPresent(this.cOptions, "idComuneCoinvolto");
  }
}
