/**
 * Allow iteration of objects in ngFor directives.
 * 
 * @author Paolo Bertuzzo
 * @version 1.0.0
 * @since 13th December 2016
 * @export 
 * @class IterableObjectPipe
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'iterable'})
export class IterableObjectPipe implements PipeTransform {
    
    /**
     * Pipe transform
     * 
     * @param {*} value
     * @param {any[]} [args]
     * @returns {any[]}
     * 
     * @memberOf IterableObjectPipe
     */
    public transform(value: any, args?: any[]): any[] {
        return Object.keys(value).map(key => {
            return {
                key: key,
                value: value[key]
            }
        });
    }
    
}