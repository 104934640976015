import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-chip-list-multiselect-field',
    template: `
        <mat-form-field class="mat-input-wrapper" color="accent" appearance="fill">
            <mat-label class="big">{{label}}</mat-label>
            <mat-chip-list #chipList [formControl]="control">
                <mat-chip *ngFor="let v of value" [selectable]="!disabled" [removable]="!disabled" (removed)="remove(v)">
                    {{fn(v)}}<mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                </mat-chip>
                <input #myInput matInput [formControl]="myControl"
                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (blur)="blur($event)" ><!-- (matChipInputTokenEnd)="add($event)" -->
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="fn" (optionSelected)="selected($event)">
                <mat-option *ngFor="let v of filteredOptions | async" [value]="valueField ? v[valueField] : v">
                    {{labelField ? v[labelField] : v}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="errors && enable">
                <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
            </mat-error>
        </mat-form-field>
    `,
    styles: [`
        ::ng-deep .mat-chip.mat-standard-chip.mat-chip-disabled {
          opacity: .87;
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListMultiselectField),
            multi: true
        }
    ]
})
export class ChipListMultiselectField extends BaseControlValueAccessor<Array<any>>
{
    @Input("options") set _options(options: Array<any>)
    {
        this.options = options;
        this.myControl.setValue(this.value);
    }

    @Input("labelField") labelField: string = "label";
    @Input("valueField") valueField: string = "value";

    @Input("duplicates") set _duplicates(d: boolean)
    {
        this.duplicates = d;
        //elimino i duplicati
        if(d && this.value)
            this.value = Array.from(this.value.reduce((m, t) => m.set(t.name, t), new Map()).values());
    }
    @Input("maxlength") set _maxlength(m: number)
    {
        this.maxlength = m;
        this.checkInput();
    }


    @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('myInput') myInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    @ViewChild('chipList', {static: true}) chipList: MatChipList;

    public options: Array<any> = [];

    public myControl = new FormControl();
    public filteredOptions: Observable<string[]>;
    public separatorKeysCodes: number[] = [ENTER, COMMA];

    public fn = this.displayFn.bind(this);
    public blur = this.onBlur.bind(this);

    public duplicates: boolean = true;
    public maxlength: number = null;

    public ngOnInit(): void
    {
        super.ngOnInit();
        this.chipList.errorStateMatcher = this.myErrorMatcher;
        this.checkInput();
        if(this.control)
        {
            if (this.control.invalid)
                this.chipList.errorState = true;
            this.control.valueChanges.subscribe(() =>
            {
                if (this.control.invalid)
                    this.chipList.errorState = true;
            });
        }
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(label => label ? this.filterOptions(label) : this.options.slice())
        );
    }

    public writeValue(value: any)
    {
        super.writeValue(value);
        this.myControl.setValue(value ? value : []);
        this.checkInput();
    }

    public setDisabledState?(isDisabled: boolean): void
    {
        super.setDisabledState(isDisabled);
        isDisabled ? this.myControl.disable() : this.myControl.enable();
    }

    public remove(item: string): void
    {
        const index = this.value.indexOf(item);
        if (index >= 0)
        {
            this.value.splice(index, 1);
            this._callTouchAndChange();
        }
        this.valueChange.emit(this.value);
        this.checkInput();
    }

    public selected(event: MatAutocompleteSelectedEvent): void
    {
        if (this.checkDuplicates(event.option.value) && this.checkMaxlength())
        {
            this.value.push(event.option.value);
            this._callTouchAndChange();
            this.valueChange.emit(this.value);
            this.myInput.nativeElement.value = '';
            this.myControl.setValue(null);
            this.checkInput();
        }
    }

    private displayFn(obj: any): string
    {
        let v = ''
        if (obj)
        {
            let p = this.options.find(f => this.valueField ? isEqual(f[this.valueField], obj) : isEqual(f, obj));
            v = p && this.labelField ? p[this.labelField] : p;
        }
        return v;
    }

    private filterOptions(value: any): Array<any>
    {
        const filterValue = value ? ("" + value).toLowerCase() : "";
        let filtered = this.options.filter(f => this.labelField ? f[this.labelField].toLowerCase().includes(filterValue) : f.toLowerCase().includes(filterValue));
        return filtered;
    }

    private checkDuplicates(item: any): boolean
    {
        return this.duplicates || !this.value.some(v => isEqual(v, item));
    }

    private checkMaxlength(): boolean
    {
        console.log("this.value.length + 1: ", this.value.length + 1)
        console.log("maxlength: ", this.maxlength)
        console.log("is maxlength: ", this.maxlength == null || (this.value.length + 1) < this.maxlength)
        return this.maxlength == null || (this.value.length + 1) <= this.maxlength;
    }

    private onBlur(event: any): void
    {
        if (!event.relatedTarget || event.relatedTarget.tagName !== 'MAT-OPTION')
        {
            this.myInput.nativeElement.value = '';
            this.myControl.setValue(null);
        }
    }

    private checkInput(): void
    {
        if(this.value)
        {
            if (this.value.length < this.maxlength)
            {
                this.value.splice(0, this.value.length);
                this.valueChange.emit(this.value);
            }
        }
    }
}
