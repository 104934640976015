<h1 class="colorSearch" mat-dialog-title>SELEZIONA IL RUOLO - ENTE DESIDERATO</h1>

<div mat-dialog-content>
    <mat-table [dataSource]="dataSource" matSort matSortActive="nomeEnte" matSortDirection="desc" matSortDisableClear>
        <ng-container matColumnDef="acl">
            <mat-header-cell *matHeaderCellDef> Ruolo </mat-header-cell>
            <mat-cell [attr.data-label]="'ACL'" *matCellDef="let element">
                <span *ngIf="element.acl == 'ed'">Ente delegato</span>
                <span *ngIf="element.acl == 'dpt'">Dir. Pian. Terr.</span>
            </mat-cell>
        </ng-container>

        <!--<ng-container matColumnDef="ente">
            <mat-header-cell *matHeaderCellDef> Ente </mat-header-cell>
            <mat-cell [matTooltip]="element.descrizione" [matTooltipPosition]="'left'"
                [matTooltipClass]="'pers-tooltip'" [attr.data-label]="'ENTE'" *matCellDef="let element">
                {{element.ente}} </mat-cell>
        </ng-container>-->

        <ng-container matColumnDef="nomeEnte">
            <mat-header-cell *matHeaderCellDef> Nome Ente </mat-header-cell>
            <mat-cell [attr.data-label]="'NOME ENTE:'" *matCellDef="let element">
                {{element.nomeEnte}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tipologiaEnte">
            <mat-header-cell *matHeaderCellDef> Tipologia Ente </mat-header-cell>
            <mat-cell [attr.data-label]="'TIPOLOGIA ENTE:'" *matCellDef="let element">
                {{element.tipologiaEnte}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="aggiungi">
            <mat-header-cell *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell [attr.data-label]="'aggiungi'" *matCellDef="let element">
                <mat-checkbox class="example-margin" (change)="cambiaRuoloEnte(element)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell [ngClass]="'customWidthClass'" *matHeaderCellDef></mat-header-cell>
            <mat-cell [ngClass]="'customWidthClass'" *matCellDef="let element">
                <myp-toolbar 
                    [toolbar]="toolbar" role=toolbar [data]="element"
                    (click)="cambiaRuoloEnte(element.acl, element.ente)">
                </myp-toolbar>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    </mat-table>

    <div *ngIf="this.dataSource.counter$.source['value'] === 0">
        <mat-card class="emptyTable">
            <mat-card-content>
                <h6>Non ci sono risultati</h6>
            </mat-card-content>
        </mat-card>
    </div>

    <mat-paginator style="flex-wrap: wrap;" id='paginator' [pageSize]="risultatiPerPagina"
        [pageSizeOptions]="[3, 5, 10]"></mat-paginator>

</div>
<mat-dialog-actions align="end">
    <button mat-stroked-button color='accent' class='persBtn' mat-dialog-close>
        <p class='cont'>ANNULLA</p>
    </button>
</mat-dialog-actions>