/**
 * Template service.
 * 
 * @author Paolo Bertuzzo, Nicola Prenna
 * @version 1.0.0
 * @since 11th December 2016
 * @export 
 * @class TemplateService
 */
/// <references path="./../../model/Model" />

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class TemplateService {

    /**
     * Creates an instance of TemplateService.
     * 
     * @param {HttpClient} http
     * 
     * @memberOf TemplateService
     */
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService
    ) {
        // Do nothing
    }

    /**
     * Get the templates
     * 
     * @returns {Promise<Array<any>>}
     * 
     * @memberOf TemplateService
     */
    public getTemplates(): Promise<Array<Model.Template>> {
        return this.http
            .get(`/myintranet/${this.userService.ipa}/templates`)
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Template>> response).entities);
    }

    /**
     * Create a new template
     * 
     * @param {Model.Template} template The template
     * @returns {Promise<Model.Template>}
     * 
     * @memberOf TemplateService
     */
    public createTemplate(template: Model.Template): Promise<Model.Template> {
        return this.http.post(`/myintranet/${this.userService.ipa}/templates/create`, {
            name: template.name,
            content: template.content,
            contentPreview: template.contentPreview,
            theme: template.theme,
            widgets: template.widgets
        })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Template>> response).entity);
    }

    /**
     * Update an existing template
     * 
     * @param {Model.Template} template The template
     * @returns {Promise<Model.Template>}
     * 
     * @memberOf TemplateService
     */
    public updateTemplate(template: Model.Template): Promise<Model.Template> {
        return this.http.put(`/myintranet/${this.userService.ipa}/templates/update`, {
            id: template.id,
            name: template.name,
            content: template.content,
            contentPreview: template.contentPreview,
            theme: template.theme,
            widgets: template.widgets
        })
            .toPromise()
            .then(response => (<Model.ServiceResponse<Model.Template>> response).entity);
    }

    /**
     * Delete a template
     * 
     * @returns {Promise<Array<Model.Template>>}
     * 
     * @memberof TemplateService
     */
    /*public deleteTemplate(id: string): Promise<any> {
        return this.http.request(`/myintranet/${this.userService.ipa}/templates/delete/${id}`, {
            method: 'DELETE',
        }).toPromise();
    } */

    /**
     * 
     * 
     * @author Giulio Montagner
     * @param {string} id 
     * @returns {Promise<any>} 
     * @memberof TemplateService
     */
    public duplicateTemplate(id: string): Promise<any> {
        return this.http.post(`/myintranet/${this.userService.ipa}/templates/duplicate/${id}`, null).toPromise();
    }

}