<div class="container">
    <div *ngIf="messageBox">{{ messageBox }}</div>
    <div *ngIf="(items === null || items === undefined || (items !== null && items !== undefined && items?.length === 0)) && (messageBox == null || messageBox == undefined) ">Non ci sono elementi da visualizzare</div>
    <div *ngFor="let groupedItem of items | grouped:4" class="row listview mt-2">
        <div *ngFor="let item of groupedItem" class="col-md-3 listview-element" (mouseenter)="item.hover=true" (keypress)="$event.keyCode === 13 ? item.hover=!item.hover : false"  (mouseleave)="item.hover=false" (click)="selectItem(item)">
            <div *ngIf="!item.thumbnailUrl" class="{{!item.thumbnailUrl ? getThumbnail(item) : ''}}">
            </div>
            <img *ngIf="item.thumbnailUrl" [src]="item.thumbnailUrl" class="content-thumbnail">
            <div role="button" tabindex=0 ><p class="item-label">{{ item.label }}</p></div>
            <myp-toolbar [toolbar]="toolbar" [data]="item" role=toolbar aria-expanded="false" (onToolbarEvent)="handleToolbarEvent($event)"></myp-toolbar>
        </div>
    </div>
</div>