import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelAutorizzazionePaesaggistica } from 'src/app/model/Model-autorizzazionePaesaggistica';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RilasciatoProvinciaService {

  private BASE_URL = environment.urls.base + "/TipoRilasciatoProvincia";

  constructor(private http: HttpClient) { }

  /**
  * Chiamata per recuperare la lista dei Tipi Rilasciato-Da-Provincia
  * @param
  * @author Marta Zecchillo
  * @returns RilasciatoProvinciaDto[]
  */
  //  getTipiRilasciatoProvincia(){
  //   return this.http.get<ModelAutorizzazionePaesaggistica.RilasciatoProvinciaDto[]>
  //                       (this.BASE_URL+`/select`);
  // }

    /**
  * Chiamata per recuperare la lista dei Tipi Rilasciato-Da-Provincia per gli enti di tipo provincia
  * @param
  * @author Marta Zecchillo
  * @returns RilasciatoProvinciaDto[]
  */
  getTipiRilasciatoProvincia(codiceTipoEnteDelegato: string){

  let params = new HttpParams();
  params = params.append("codiceTipoED", codiceTipoEnteDelegato)

  return this.http.get<ModelAutorizzazionePaesaggistica.RilasciatoProvinciaDto[]>
                      (this.BASE_URL+`/select`, { params: params });
}

}
