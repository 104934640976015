import { DrawLocale } from "./locale.model";

export interface WindowSize {
  width: number;
  height: number;
}

export interface MapOptions {
  initialization?: {
    boundingBox?: [Coordinate, Coordinate];
    point?: Coordinate;
    positioning?: "point" | "boundingBox";
    zoom?: number;
  };
  layersToolbar?: {
    position?: "topright" | "topleft" | "bottomright" | "bottomleft";
    viewMode?: "expanded" | "collapsed";
  };
  drawOptions?: {
    drawModeEntries?: Array<
      "marker" | "line" | "polygon" | "circle" | "rectangle"
    >;
    drawStyle?: any;
    initialDrawMode?: boolean;
  };
}

export interface BaseLayer {
  name: string;
  url: string;
  layerId: string;
  isSelected: boolean;
  type: "wms" | "tile";
}

/**
 * 
 */
export interface WmsBaseLayer {
  transparent?: boolean;
  format?: string;
}

/**
 *
 */
export interface OverlayLayer {
  name: string;
  type: "entities" | "wms" | "tms" | "wfs";
  isSelected: boolean;
  transparent?: boolean;
  format?: string;
}

/**
 *
 */
export interface EntitiesOverlayLayer extends OverlayLayer {
  entities: BaseEntity[];
  type: "entities";
  isEditable: boolean;
}

/**
 *
 */
export interface WmsOverlayLayer extends OverlayLayer {
  url: string;
  layerId: string;
  type: "wms";
}

/**
 *
 */
export interface TmsOverlayLayer extends OverlayLayer {
  type: "tms";
  url: string;
}

/**
 *
 */
export interface WfsOverlayLayer extends OverlayLayer {
  type: "wfs";
  url: string;
  typeNamespace: string;
  typeName: string;
}

/**
 *
 */
export interface BaseEntity {
  id?: string;
  groupId?: string;
  drawStyle?: Style;
  type: "point" | "marker" | "polyline" | "polygon" | "circle" | "rectangle";
  infoWindowLoadingFunction?: (id: string) => Promise<string>;
  popup?: string;
}

/**
 *
 */
export interface Point extends BaseEntity {
  position: Coordinate;
  type: "point";
}
/**
 *
 */
export interface Marker extends BaseEntity {
  icon: Icon;
  position: Coordinate;
  type: "marker";
}

/**
 *
 */
export interface Line extends BaseEntity {
  positions: Coordinate[];
  type: "polyline";
}

/**
 *
 */
export interface Polygon extends BaseEntity {
  positions: Coordinate[];
  type: "polygon";
}

/**
 *
 */
export interface Circle extends BaseEntity {
  position: Coordinate;
  radius: number;
  type: "circle";
}

/**
 *
 */
export interface Rectangle extends BaseEntity {
  positions: [[number, number], [number, number]];
  type: "rectangle";
}

/**
 *
 */
export interface Icon {
  iconUrl: string;
  iconSize: [number, number];
  popupAnchor: [number, number];
}

/**
 *
 */
export interface DrawEvent {
  type: "drawStarted"
    | "geometryAdded"
    | "geometriesEdited"
    | "geometriesDeleted";
}

/**
 *
 */
export interface DrawStartedEvent {
  type: "drawStarted";
  data: {
    geometryType: "circle" | "marker" | "rectangle" | "polygon" | "line";
  };
}

/**
 *
 */
export interface GeometryAddedEvent {
  type: "geometryAdded";
  data: {
    geometryType: "circle" | "marker" | "rectangle" | "polygon" | "line";
    vertices: [[number, number]];
    radius?: number;
  };
}

/**
 *
 */
export interface GeometriesEditedEvent {
  type: "geometriesEdited";
  data: {
    items: BaseEntity[];
  };
}

/**
 *
 */
export interface GeometriesDeletedEvent {
  type: "geometriesDeleted";
  data: {
    items: {
      groupId?: string;
      id: string;
    }[];
  };
}

/**
 * Style definition
 */
export interface Style {
  stroke?: boolean | undefined;
  color?: string | undefined;
  weight?: number | undefined;
  opacity?: number | undefined;
  lineCap?: "butt" | "round" | "square" | "inherit" | undefined;
  lineJoin?: "miter" | "round" | "bevel" | "inherit" | undefined;
  dashArray?: string | number[] | undefined;
  dashOffset?: string | undefined;
  fill?: boolean | undefined;
  fillColor?: string | undefined;
  fillOpacity?: number | undefined;
  fillRule?: "nonzero" | "evenodd" | "inherit" | undefined;
  renderer?: any | undefined;
  className?: string | undefined;
}

/**
 * Coordinate tuple
 */
export interface Coordinate {
  lat: number;
  lng: number;
  alt?: number | undefined;
}

/**
 *
 */
export interface EntityClickedEvent {
  type: "entityClicked";
  data: {
    item: BaseEntity;
  };
}

/**
 *
 */
export interface MaxEntitiesReachedEvent {
  type: "maxEntitiesReached";
}

/**
 *
 */
export interface EntitiesGroup {
  id: string;
  layerName: string;
  entities: BaseEntity[];
}

export interface Locale {
  drawLocale: DrawLocale;
}


export function fromGeoJSONToEntities(featureCollection:any){
  let retEntities=[];
  let features:any[]=featureCollection.features;
  if(features?.length>0){
    features.forEach(feature=>{
      if(feature?.geometry?.type=="Polygon"){
        let ent={
          type:"polygon",
          id:feature?.properties.geomId,
          drawStyle:feature?.properties.drawStyle,
          positions:fromGeoCoordinatesPolygonToPositions(feature.geometry.coordinates)
          //positions:fromGeoCoordinatesToPositions(feature.geometry.coordinates)
        };
        retEntities.push(ent);
      }else if(feature?.geometry?.type=="LineString"){
        let ent={
          type:"polyline",
          id:feature?.properties.geomId,
          drawStyle:feature?.properties.drawStyle,
          positions:fromGeoCoordinatesToPositions(feature.geometry.coordinates)
        };
        retEntities.push(ent);
      }else if(feature?.geometry?.type=="Point"){
        let ent={
          type:"marker",
          id:feature?.properties.geomId,
          drawStyle:feature?.properties.drawStyle,
          position:fromGeoCoordinatesToPosition(feature.geometry.coordinates)
        };
        retEntities.push(ent);
      }
    });
  }
  return retEntities;
}


/**
 * esporta in formato geoJson solo geometrie interne a plugin mappa di tipo: marker polygon polyline
 * @param entities 
 * @param idPratica 
 * @returns 
 */
export function toGeoJson(entities:any[],idPratica:string){
  let geoJson={
    type: "FeatureCollection",
    features: []
  };
  entities.forEach(ent=>{
    console.log("entity: ", ent)
    let properties={
      idPratica:idPratica,
      geomId:ent.id,
      groupId:ent.groupId,
      drawStyle:ent.drawStyle,
    };
    if(ent.type=="polygon"){
      let polGeoJson={
        type:"Feature",
        geometry: {
          type: "Polygon",
          coordinates: fromCoordinateToPolygonGeoCoordinates(ent.positions)
          //coordinates: fromCoordinateToGeoCoordinates(ent.positions)
        },
        properties:properties
      }
      geoJson.features.push(polGeoJson);
    }else if(ent.type=="polyline"){
      let polGeoJson={
        type:"Feature",
        geometry: {
          type: "LineString",
          coordinates: fromCoordinateToLineStringGeoCoordinates(ent.positions)
          //coordinates: fromCoordinateToGeoCoordinates(ent.positions)
        },
        properties:properties
      }
      geoJson.features.push(polGeoJson);
    }else if(ent.type=="marker"){
      let polGeoJson={
        type:"Feature",
        geometry: {
          type: "Point",
          coordinates: fromCoordinateToPointGeoCoordinates(ent.position)
          //coordinates: fromCoordinateToGeoCoordinates(ent.position)
        },
        properties:properties
      }
      geoJson.features.push(polGeoJson);
    }
  });
  return geoJson;
}


export function fromCoordinateToGeoCoordinates(coord:Coordinate|Coordinate[]){
  if(Array.isArray(coord)){
    let out=[];
    coord.forEach(point=>{
      out.push([point.lng, point.lat]);
    });
    return out;
  }else{
    return [coord.lng,coord.lat];
  }
}

export function fromCoordinateToPointGeoCoordinates(coord: Coordinate): Array<number>
{
  return [coord.lng, coord.lat];
}

export function fromCoordinateToLineStringGeoCoordinates(coord: Array<Coordinate>): Array<Array<number>>
{
  let out = [];
  coord.forEach(point =>
  {
    out.push([point.lng, point.lat]);
  });
  return out;
}

export function fromCoordinateToPolygonGeoCoordinates(coord: Array<Coordinate>): Array<Array<Array<number>>>
{
  let out = [];
  coord.forEach(point =>
  {
    out.push([point.lng, point.lat]);
  });
  out.push(out[0]);
  return [out];
}

export function fromGeoCoordinatesToPosition(coord:any){
  return {lng:coord[0],lat:coord[1]};
}

export function fromGeoCoordinatesToPositions(coord:any[]){
    let out=[];
    coord.forEach(point=>{
      let coord:Coordinate={lng:point[0],lat:point[1]};
      out.push(coord);
    });
    return out;
}

export function fromGeoCoordinatesPolygonToPositions(coord:Array<any[]>){
    let out=[];
    coord[0].forEach(point=>{
      let coord:Coordinate={lng:point[0],lat:point[1]};
      out.push(coord);
    });
    return out;
}
