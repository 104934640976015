import { MatInput } from '@angular/material/input';
import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseControlValueAccessor } from 'src/app/core/utils/BaseControlValueAccessor';

@Component({
    selector: 'app-select-auto-field',
    template: `
        <mat-form-field *ngIf="control" class="mat-input-wrapper" color="accent" appearance="fill">
            <mat-label class="big">{{label}}</mat-label>
            <input matInput type="text" [formControl]="myControl" [readonly]="_disabled" [matAutocomplete]="auto" (blur)="blur($event)"/>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="fn" (optionSelected)="changeBind($event.option.value)">
                <mat-option [value]="null" matTooltip="Nessun valore" matTooltipPosition="below">-- Nessun valore selezionato--</mat-option>
                <mat-option *ngFor="let option of filteredOptions | async" [matTooltip]="option[labelField]" 
                    matTooltipPosition="below" [value]="valueField ? option[valueField]: option">
                    {{option[labelField]}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="errors && enable">
                <app-input-error [errors]="errors" [enable]="enable"></app-input-error>
            </mat-error>
        </mat-form-field>
    `,
    styles: [`
           ::ng-deep 	.mat-input-element:disabled,
            .mat-form-field-type-mat-native-select.mat-form-field-disabled
            .mat-form-field-infix::after {
              color: rgba(0,0,0,.87);
            }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectAutocompleteField),
            multi: true
        }
    ]
})
export class SelectAutocompleteField extends BaseControlValueAccessor<any> implements AfterContentInit
{
    @Input("options") set _options(options: Array<any>)
    {
        this.options = options;
        this.myControl.setValue(this.value);
    }

    @Input("labelField") labelField: string = "label";
    @Input("valueField") valueField: string = null;
    @Input("disabled") set _disabled(isDisabled: boolean)
    {
        this.setDisabledState(isDisabled);
    }

    @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter();

    @ViewChild(MatInput) input: MatInput;

    public options: Array<any> = [];

    public myControl = new FormControl();
    public filteredOptions: Observable<string[]>;
    public fn = this.displayFn.bind(this);
    public blur = this.onBlur.bind(this);

    constructor(protected inj: Injector,
                private dr: ChangeDetectorRef)
    {
        super(inj);
    }

    public ngOnInit(): void
    {
        super.ngOnInit();
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(label => label ? this.filterOptions(label) : this.options.slice())
        );
    }

    public ngAfterContentInit(): void
    {
        this.dr.detectChanges();
        if (this.control)
        {
            this.input.errorStateMatcher = this.myErrorMatcher;
            this.setErrorsIfExists();
            this.myControl.valueChanges.subscribe(() => this.setErrorsIfExists());
        }
    }

    private setErrorsIfExists(): void
    {
        if (this.errors)
        {
            console.log("SET ERRORS ", this.errors);
            this.input.errorState = true;
            this.myControl.setErrors(this.errors);
            console.log("Errors in myControl: ", this.myControl.errors);
        }
    }

    public writeValue(value: any)
    {
        super.writeValue(value);
        this.myControl.setValue(value);
    }

    public changeBind(value: any): void
    {
        let change = !isEqual(this.value, value);
        this.myControl.setValue(value);
        this.value = value;
        this.control.setValue(value);
        if (change) this.valueChange.emit({ value: value });
    }

    private displayFn(obj: any): string
    {
        let v = ''
        if (obj)
        {
            let p = this.options.find(f => this.valueField ? isEqual(f[this.valueField], obj) : isEqual(f, obj));
            v = p && this.labelField ? p[this.labelField] : p;
        }
        return v;
    }

    private filterOptions(value: any): Array<any>
    {
        if (typeof (value) == "string")
        {
            const filterValue = value ? ("" + value).toLowerCase() : "";
            let filtered = this.options.filter(f => this.labelField ? f[this.labelField].toLowerCase().includes(filterValue) : f.toLowerCase().includes(filterValue));
            return filtered;
        }
    }

    public onBlur(event: any): void
    {
        if (!event.relatedTarget || event.relatedTarget.tagName !== 'MAT-OPTION')
        {
            if (!this.myControl.value || (typeof (this.myControl.value) == "string" && this.myControl.value.trim() == ""))
            {
                this.myControl.setValue(null);
                //this.control.setValue(null);
                this.changeBind(null);
            }
            else
            {
                this.myControl.setValue(this.value);
            }
        }
    }


    public setDisabledState(isDisabled: boolean): void
    {
        this.disabled = isDisabled;
        if(this.disabled)
            this.myControl.disable();
        else
            this.myControl.enable();
    }
}
