/**
 * Configuration service.
 * 
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 28th August 2017
 * @export 
 * @class ConfigurationService 
 */
import { Injectable, Inject } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { UserService } from './user.service';
// import { ContentService } from './content.service';

@Injectable()
export class ConfigurationService {

    /**
     * 
     * 
     * @private
     * @type {Promise<Model.ContentType[]>}
     * @memberof ConfigurationService
     */
    private contentTypePromise: Promise<Model.ContentType[]>;

    /**
     * Creates an instance of ConfigurationService.
     * @param {HttpClient} http
     * @param {UserService} userService
     * @param {ContentService} contentService
     * @memberof ConfigurationService
     */
    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserService) private userService: UserService,
       //  @Inject(ContentService) private contentService: ContentService
    ) {
        // this.contentTypePromise = this.contentService.getDefaultContentTypes();
    }

    /**
     * 
     * 
     * @readonly
     * @type {Promise<Model.ContentType[]>}
     * @memberof ConfigurationService
     */
    public get defaultContentTypes(): Promise<Model.ContentType[]> {
        return this.contentTypePromise;
    }
}
