
<div style="margin-top: 1.4em;"></div>
<div class="title mb-2">
  <span class="title">{{ "dashboard.listaEnti" | translate }}
  </span>
</div>
<div class="input-group" [formGroup]="form" style="margin-top: 2em; margin-bottom: 2em;">
  <input class="form-control" placeholder="{{'enti.denominazione'|translate}}" formControlName="descrizione" role="textbox" type="text"/>
  <span class="input-group-btn">
    <button class="btn btn-secondary" (click)="onSearch()">{{'generic.cerca'|translate}}<span class="fa fa-search"></span></button>
    <!-- <button mat-stroked-button class="pers2" color="warn" (click)="onSearch()">Cerca</button> -->
  </span>
</div>
<app-simple-table id="id" [data]="list ? list.list : []" [header]="header" [enableAction]="false" 
  [totalRecords]="list ? list.count : 0" (pageSortChange)="sortAndPaging($event)">
</app-simple-table>
