
  export enum AclTipo {
    DGPE = 'dgpe', //da eliminare...
    DPCPL = 'dpcpl',//da eliminare...
    EELL = 'eell',//da eliminare...

    DPT="dpt",
    ED="ed",
    MULTI = 'multi'
  }
  