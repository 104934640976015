<div class="breadcrumbs">
  <span class="title"
    >{{ "dettaglio.gestioneAutpae" | translate }}
    <mat-icon style="vertical-align: middle"> keyboard_arrow_right</mat-icon>
    {{ "dashboard.nuovaAutorizzazione" | translate }}
  </span>
</div>
<hr />

<div class="flex-row">
  <div class="flex-display">
    <button
      mat-stroked-button
      class="pers"
      (click)="askConfermaIndietro()"
    >
      <mat-icon style="vertical-align: middle">keyboard_arrow_left</mat-icon>
    </button>
    <span class="h1 upp"> Nuova Autorizzazione Paesaggistica</span>
  </div>

  <button mat-stroked-button color="basic" class="pers2" (click)="confirm()">
    <span> Nuova autorizzazione </span>
    <!-- class="text" -->
  </button>
</div>

<div *ngIf="form" class="flex-form" [formGroup]="form" #formCrea>
  <!--[filter]=true
  valueField="id"
  [valNgModel]="enteSelected"-->
  <!-- <app-select-field
    formControlName="enteDelegato"
    [label]="('pratica.ente'|translate) + '*'"
    labelField="label"
    valueField="value"
    [options]="entiDelegati"
    [filter]="true"
    [enable]="submitted"
    [disabled]="disabledEnte"
  ></app-select-field> -->
  <!--     [errors]="form.get('enteDelegato').errors"... non più necessario -->
  <!-- [valNgModel]="enteSelected" -->
  <app-select-auto-field
    formControlName="enteDelegato"
    [label]="('pratica.ente' | translate) + '*'"
    labelField="label"
    valueField="value"
    [options]="entiDelegati"
    [enable]="submitted"
    [disabled]="disabledEnte"
  >
  </app-select-auto-field>
  <app-textarea-field
    formControlName="oggetto"
    [maxlength]="200"
    [label]="('pratica.oggetto' | translate) + '*'"
    [enable]="submitted"
    [hideRequiredMarker]="false"
  >
  </app-textarea-field>
  <!-- <mat-form-field color="accent" appearance="fill">
    <mat-label class="big">Ente Delegato*</mat-label>
    <mat-select formControlName="enteDelegato" [(ngModel)]="enteSelected">
      <mat-option *ngFor="let enteDelegato of entiDelegati" [value]="enteDelegato">
        {{enteDelegato.descrizione}}
      </mat-option>
    </mat-select>
  </mat-form-field> -->

  <!-- <mat-form-field class="example-full-width" color="accent" appearance="fill">
    <mat-label class="big">Oggetto*</mat-label>
    <textarea matInput formControlName="oggetto" [maxlength]="2000"></textarea>
  </mat-form-field> -->
  <!--[control]="form.controls.oggetto.errors&&form.controls.oggetto.touched"-->
  <!-- <app-input-error
    [errors]="form.get('oggetto').errors"
    [enable]="submitted"
  ></app-input-error
  > --><!-- [controlName]="form.controls.oggetto" -->
</div>
