import {  Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { GenericDialogContentComponent } from '../components/generic-dialog-content/generic-dialog-content.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  confirmed: boolean;
  inputData: string;

  constructor(public dialog: MatDialog) { }

  //FUNZIONE PER APRIRE UNA MODAL DI CONFERMA GENERICA
  openDialog(title: string, message: string, width?:string, height?:string,disableAnnulla?:boolean,
    altreOpzioni?:{labelConferma:string,cssClass?:string}): Observable<boolean> {
    const dialogRef = this.dialog.open(GenericDialogContentComponent, {
      data: { 
        title: title, 
        message: message ,
        disableAnnulla:disableAnnulla ? true:false,
        altreOpzioni:altreOpzioni
      },
      width: width ? width:'auto',
      height: height? height:'auto'
    });
    return new Observable<boolean>((observer) => {
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.confirmed = true;
        } else {
          this.confirmed = false;
        }
        observer.next(this.confirmed);
        observer.complete();
      });
    });
  };

 
     
}
