<h1 class="colorSearch" mat-dialog-title>Localizzazione intervento</h1>
<div mat-dialog-header align="center">
  <p>{{ "dettaglio.idPratica" | translate }}: {{ praticaDto.id }}</p>
</div>
<div mat-dialog-content>
  <ng-regioneveneto-map
    #ngRegioneVenetoMap
    (drawEvent)="onDrawEvent($event)"
    (entityClickedEvent)="onEntityClickedEvent($event)"
    [windowSize]="windowSize"
    [options]="options"
    [baseLayers]="baseLayers"
    [overlayLayers]="overlayLayers"
    [drawModeEnabled]="isDrawModeEnabled"
    [entitiesGroups]="entitiesGroups"
    [center]="centro"
  ></ng-regioneveneto-map>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button color="accent" class="persBtn" (click)="chiudi()">
    <p class="cont">Fine</p>
  </button>
  <!-- <button  mat-raised-button color='accent' class='persBtn' (click)="downloadShapeFile()">
    <p class='cont'>Download Shape file</p>
  </button> -->
</div>
