<ng-container *ngIf="!public; else publicFooter">
  <div class="main-footer">
    <div class="flex-display logoCont">
      <img src="assets/images/logo-regioneveneto.svg">
    </div>
    <div class="flex-display contVen">
      <span class="rven"><b>Regione Veneto</b></span>
      <ul class="external-links">
        <li><a class="footer-style" [href]="urlMyintranetOrigin+'/myintranet/C_DEMO/admin/info/accessibility'"
            target="_blank">Accessibilità</a></li>
        <li><a class="footer-style" [href]="urlMyintranetOrigin +'/myintranet/C_DEMO/admin/info/privacy'"
            target="_blank">Privacy</a></li>
        <li><a class="footer-style"
            href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/note_legali"
            target="_blank">Note Legali</a></li>
        <li><a class="footer-style"
            href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/info_cookies"
            target="_blank">Informativa Cookie</a></li>
        <li><a class="footer-style" href="http://www.regione.veneto.it" target="_blank">www.regione.veneto.it</a></li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-template #publicFooter>
  <div class="public-footer">
    <div class="flex-display">
      <div class="container-fluid">
        <div class="row flex-nowrap mt-2">
          <div class="col-md-2">
            <div class="col-md-12 border-bottom border-white">
              <strong>CONTATTI</strong>
            </div>
            <div class="col-md-12 public-footer-content mt-1">
              Regione Veneto - Giunta Regionale
              <br>Palazzo Baibi - Dorsoduro, 3901 30123-Venezia
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-md-12 border-bottom border-white">
              <strong>RECAPITI</strong>
            </div>
            <div class="col-md-12 public-footer-content mt-1">
              Centralino 041 27 92 111
              <br>PEC della Regione Veneto
            </div>
          </div>
          <div class="col-md-2">
            <div class="col-md-12 border-bottom border-white">
              <strong>COLLEGAMENTI</strong>
            </div>
            <div class="col-md-12 public-footer-content mt-1">
              <a style="color:white;" href="https://osservatoripaesaggio.regione.veneto.it/">Osservatori per il Paesaggio</a>
            </div>
          </div>
          <div class="col-md-4 d-none d-md-block">
          </div>
          <div class="col-md-2">
            <div class="col-md-12 border-bottom border-white">
              <strong>SEGUICI SU</strong>
            </div>
            <div class="col-md-12 my-2 text-center">
              <div class="row">
                <div class="col-md-4">
                  <a href="https://www.facebook.com/RegionedelVeneto" target="_blank">
                    <mat-icon>facebook</mat-icon>
                  </a>
                </div>
                <div class="col-md-4">
                  <a href="https://twitter.com/RegioneVeneto" target="_blank">
                    <mat-icon svgIcon="twitter" style="cursor: pointer;"></mat-icon>
                  </a>
                </div>
                <div class="col-md-4">
                  <a href="https://www.youtube.com/channel/UCkZgHeZp11h21quvZMF9B4A" target="_blank">
                    <mat-icon svgIcon="youtube" style="cursor: pointer;"></mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 public-footer-links flex-nowrap">
          <!-- <div class="flex-display"> -->
            <span class="rven"><b>Regione Veneto</b></span>
            <ul class="external-links">
              <li><a class="public-footer-style" 
                href="https://myintranet-rdv.regione.veneto.it/myintranet/RDV/admin/info/accessibility"
                  target="_blank">Accessibilità</a></li>
              <li><a class="public-footer-style" 
                href="https://myintranet-rdv.regione.veneto.it/myintranet/RDV/admin/info/privacy"
                  target="_blank">Privacy</a></li>
              <li><a class="public-footer-style"
                  href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/note_legali"
                  target="_blank">Note Legali</a></li>
              <li><a class="public-footer-style"
                  href="http://myportal-infopbl.regione.veneto.it/myportal/INFOPBL/informative/info_cookies"
                  target="_blank">Informativa Cookie</a></li>
              <li><a class="public-footer-style" href="http://www.regione.veneto.it" target="_blank">www.regione.veneto.it</a></li>
            </ul>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>
