/**
 * 
 * @author Giulio Montagner
 * @version 1.0.0
 * @since 22nd September 2017
 * @export
 * @class RightSidebarDirective
 */
import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { GlobalState } from './../../core/services/global-state.service';

@Directive({ selector: '[mypRightSidebar]' })
export class RightSidebarDirective implements OnInit, OnDestroy {
    
    /**
     * Creates an instance of RightSidebarDirective.
     * @param {ElementRef} element 
     * @memberof RightSidebarDirective
     */
    constructor(
        private element: ElementRef,
        private globalState: GlobalState
    ){
        const el: Element = this.element.nativeElement;
        if(!el.classList.contains('al-sidebar-right'))
            el.classList.add('al-sidebar-right');
    }

    /**
     * 
     * 
     * @memberof RightSidebarDirective
     */
    public ngOnInit(){
        this.globalState.notifyDataChanged('right-sidebar', true);
    }

    /**
     * 
     * 
     * @memberof RightSidebarDirective
     */
    public ngOnDestroy(){
        this.globalState.notifyDataChanged('right-sidebar', false);
    }
}