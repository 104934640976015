
export enum TipoEnteDelegato {
  R = "Regione",
  A2 = "Forme associative e di cooperazione fra enti locali",
  B = "Ente parco regionale",
  C = "Ente parco nazionale",
  A1 = "Comune delegato",
  D = "Provincia delegata o Città Metropolitana"
}

export enum TipoEnteEnum{
  CO ="Comune",
  P = "Provincia",
  R = "Regione"
}

export enum RilasciatoProvinciaEnum {
	VUOTO = "VUOTO",
	ART45A = "ART45A",
	ART45B = "ART45B"
}


export enum TipoDocumento{
  AUTORIZZAZIONE="AUTORIZZAZIONE",
  PARERE_SOP="PARERE_SOP",
  PARERE_COMMLOC="PARERE_COMMLOC"
}
