import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private arrayRichieste: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    const ii = this.arrayRichieste.indexOf(req);
    if (ii >= 0) {
      this.arrayRichieste.splice(ii, 1);
    }
    this.loaderService.isLoading.next(this.arrayRichieste.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.arrayRichieste.push(req);
    this.loaderService.isLoading.next(true);

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        err => { this.removeRequest(req); observer.error(err); },
        () => { this.removeRequest(req); observer.complete(); });
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
  
}
