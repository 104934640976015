import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AclTipo } from 'src/app/model/AclTipo.enum';
import { CambioRuoloEnteComponent } from '../cambio-ruolo-ente-dialog/cambio-ruolo-ente-dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  erroreLogin: boolean;
  caricamento: boolean;
  returnURL: string;

  constructor(private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              ) {
  }

  //quando login viene chiamato ci si considera loggati, in realtà il vero controllo dipende da un httpOnly cookie contenente il JWT
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.returnURL = params.returnUrl;
      this.authenticationService.getAcl().subscribe(ret => {
        this.authenticationService.login(ret);
        if (ret.acl == AclTipo.EELL) {
          this.router.navigate(['/gestione-segnalazioni']);
        } else if (ret.acl == AclTipo.DPCPL) {
          this.router.navigate(['/lista-eventi']);
        } else if (ret.acl == AclTipo.DGPE) {
          this.router.navigate(['/elenco-richieste']);
        }
        else if (ret.acl == AclTipo.DPT) {
            this.router.navigate(['/lista-pratiche']);
        }
         else if (ret.acl == AclTipo.ED) {
          this.router.navigate(['/lista-pratiche']);
        }
        else if (ret.acl == AclTipo.MULTI) {
          this.caricamento = false;
          this.openCambioRuoloEnteDialog();
        } else {
          this.router.navigate(['/logout']);
        }
      });
    });
    this.erroreLogin = false;
    this.caricamento = true;
  }

  openCambioRuoloEnteDialog() {
    //inserire qui la configurazione della material dialog MatDialogConfig per dati e css
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    const dialogRef = this.dialog.open(CambioRuoloEnteComponent, dialogConfig);
  }  

  reset() {
    window.location.reload();
  }

}
