import { Component, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertsCenterService } from 'src/app/core/services/alerts-center.service';
import { RuoliEntiDataSource } from 'src/app/services/ruoli-enti.datasource';
import { RuoliEntiService } from 'src/app/services/ruoli-enti.service';
import { Toolbar, ToolbarEvent } from 'src/app/shared/components/toolbar/toolbar';

@Component({
  selector: 'app-cambio-ruolo-ente-dialog',
  templateUrl: './cambio-ruolo-ente-dialog.html',
  styleUrls: ['./cambio-ruolo-ente-dialog.css']
})
export class CambioRuoloEnteComponent implements OnInit, AfterViewInit {

  arrowFlag: boolean = false;
  dettaglio: boolean = false;
  eventDetail: any;
  risultatiPerPagina: number = 5;
  public form: FormGroup;
  aggiungiTutti: string = "";
  private filtrato: boolean = false;
  dataSource: RuoliEntiDataSource;
  confirmed: string;
  private filtroRicerca: string = "";
  ruoliEntiSearchableDto: ModelEventiCalamitosi.RuoliEntiSearchableDto = {};

  public toolbarMap: {[key: string]: Toolbar} = {};
  public toolbar: Toolbar = this.generateToolBar();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('orderBy') orderBy: MatSelect;

  @Output()
  public onToolbarEvent: EventEmitter<ToolbarEvent> = new EventEmitter();

  isLoading: boolean = false;
  openToggleAcl: string = null;
  openToggleEnte: string = null;

  constructor(
    private ruoliEntiService: RuoliEntiService,
    private router: Router,
    private alertsCenterService: AlertsCenterService,
    private dialogRef: MatDialogRef<CambioRuoloEnteComponent>,

    @Inject(MAT_DIALOG_DATA) data) {
    dialogRef.disableClose = true;
    this.buildForm();
  }

  ngOnInit(): void {
    this.dataSource = new RuoliEntiDataSource(this.ruoliEntiService);
    /* this.dataSource.errors$.subscribe(res => {
      if (res) this.alertsCenterService.showAlert({
        message: `Errore lettura dati`,
        type: 'danger',
        autoClosable: false
      });
    }) */
  }

  //rimane in ascolto: se il click non è fatto sull'icona chiude il menù
  @HostListener('document:click', ['$event'])
  documentClick(event) {
    if (event.srcElement.innerText !== 'more_horiz') {
      this.openToggleAcl = null;
      this.openToggleEnte= null;
    }
  }

  displayedColumns: string[] = ['acl', 'nomeEnte', 'tipologiaEnte', 'actions']; //'ente', 

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.loadRuoliEnti('', this.ruoliEntiSearchableDto, 'nomeEnte,DESC', 0, this.risultatiPerPagina);
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => {
            this.ricercaListaEventi();
          }))
        .subscribe();
      this.dataSource.counter$
        .pipe(
          tap((count) => {
            this.paginator.length = count;
          })
        )
        .subscribe();
    });

    this.dataSource.errors$.subscribe(res =>
    {
      if (res) this.alertsCenterService.showAlert({
        message: `Errore lettura dati`,
        type: 'danger',
        autoClosable: false
      });
    })
  }

  private buildForm() {
    this.form = new FormGroup({
      filtro: new FormControl(null, null),
    });
  }

  public ricercaListaEventi(filtrato?: boolean): void {
    this.dataSource.loadRuoliEnti('', this.ruoliEntiSearchableDto, this.sort.active + "," + this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
  }

  public cambiaRuoloEnte(acl: string, ente: string) {
    let aclDto: ModelEventiCalamitosi.AclDto = {acl: acl, ente: ente};

    this.ruoliEntiService.aggiornaRuoliService(aclDto).toPromise().then(
      res => {
        console.log('Siamo nel ramo success...');
        this.alertsCenterService.showAlert({
          message: `Cambio ruolo effettuato con successo`,
          type: 'success',
          autoClosable: false
        });
        this.dialogRef.close(true);
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['access']);
      },
      err => {
        console.log('Siamo nel ramo failure...');
        /*this.alertsCenterService.showAlert({
          message: err.error.esitoServizio.descrizioneEsito,
          type: 'danger',
          autoClosable: false
        });*/
        this.dialogRef.close(true);
        this.router.navigate(['logout']);
      });
  }

  public generateToolBar(): Toolbar {
    let toolbar: Toolbar;
    toolbar = { toolbarCss: 'toolbar-vertical', actions: [] }
    toolbar.actions.push(
      {
        btnTitle: 'Cambia ruolo/utente',
        btnIconClass: 'article',
        btnActionEvent: 'modify',
        btnCssClass: 'btn btn-secondary'
      }
    )
    return toolbar;
  }
}
